/* eslint-disable react/jsx-props-no-multi-spaces */

import { useMemo, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { BellIcon } from 'lucide-react';
import { Action, INotificationDto } from '../../shared/model/INotificationDto';
import {
  notificationKeys,
  useNotificationsQuery,
  useNotificationSubscription,
  useReadNotificationMutation,
} from './notificationQueries';
import NotificationsTray from './NotificationsTray';
import Text from '../../shared/components/text/Text';
import BorderActiveHorizontal from '../../shared/components/indicators/BorderActiveHorizontal';
import { getInfiniteQueryDataLength } from '../../shared/utils/queryClientUtils';
import { Badge } from '../../shared/components/badge/Badge';
import { useToast } from '../../shared/components/toasts/use-toast';
import { useDispatchApiError } from '../../shared/hooks/useDispatchApiError';
import { Popover, PopoverContent, PopoverTrigger } from '../../shared/components/popovers/Popover';
import { useResponsiveQueries } from '../../shared/hooks/useResponsiveQueries';

export default function Notifications() {
  const [isOpen, setIsOpen] = useState(false);

  const queryClient = useQueryClient();
  const notificationsQuery = useNotificationsQuery();

  const { isSmallScreen } = useResponsiveQueries();

  const dispatchError = useDispatchApiError();

  const { toast } = useToast();

  useNotificationSubscription();
  useNotificationSubscription({ isUnread: true });
  useNotificationSubscription({ notificationArea: 'Contact' });
  useNotificationSubscription({ notificationArea: 'Network' });
  useNotificationSubscription({ notificationArea: 'Board' });
  useNotificationSubscription({ notificationArea: 'Project' });
  useNotificationSubscription({ notificationArea: 'Organization' });

  const numberOfUnreadNotifications = useMemo(
    () => getInfiniteQueryDataLength(notificationsQuery.data, (n) => !n.isRead),
    [notificationsQuery.data],
  );

  const readNotification = useReadNotificationMutation();
  const handleClearNotifications = async () => {
    notificationsQuery.data?.pages
      .flat()
      .filter((n) => !n.isRead)
      .forEach((n) => {
        readNotification.mutate({ id: n.id });
      });
  };

  const handleCloseCallback = () => {
    handleClearNotifications();
    setIsOpen(false);
    queryClient.invalidateQueries({ queryKey: notificationKeys.all });
  };

  const handleExecuteAction = (
    promise: Promise<unknown>,
    notification: INotificationDto,
    action: Action,
  ) => {
    promise
      .then(() => {
        if (action.type === 'Accept') {
          toast({
            title: 'Success',
            variant: 'success',
            description: notification.acceptMessage,
          });
        }
        notificationsQuery.setIsResponded(notification.id);
      })
      .catch(dispatchError)
      .finally(() => {
        if (action.invalidateQueryKeys.length === 0) return;

        setTimeout(() => {
          action.invalidateQueryKeys.forEach((queryKey) =>
            queryClient.invalidateQueries({ queryKey }),
          );
        }, 1000);
      });
  };

  return (
    <>
      <Popover open={isOpen}>
        <PopoverTrigger onClick={() => setIsOpen(true)}>
          <button type="button">
            <span className="sr-only">View notifications</span>

            <BellIcon className="h-5 w-5 text-cyan-dark hover:text-cyan-dark" aria-hidden="true" />

            {numberOfUnreadNotifications > 0 && (
              <Badge className="absolute top-[-5px] right-[-10px] fade-in-element">
                <Text as="span" size="xSmall" weight="bold" color="white" leading="none">
                  {numberOfUnreadNotifications}
                </Text>
              </Badge>
            )}
            {isOpen && <BorderActiveHorizontal thickness="thin" bottomOffset={-12} />}
          </button>
        </PopoverTrigger>
        <PopoverContent
          side={isSmallScreen ? 'bottom' : 'left'}
          className="w-96 ml-[-2rem] sm:mt-0 md:mt-14"
        >
          <NotificationsTray
            closeCallback={handleCloseCallback}
            onExecuteAction={handleExecuteAction}
          />
        </PopoverContent>
      </Popover>
    </>
  );
}
