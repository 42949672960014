import { useEffect, useState } from 'react';

const SMALL_BREAKPOINT = 768;
const MEDIUM_BREAKPOINT = 1200;

export type ScreenSize = 'small' | 'medium' | 'large';

export function useResponsiveQueries() {
  // Track the current window width
  const [windowWidth, setWindowWidth] = useState(() => window.innerWidth);

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }

    // Add the resize event listener
    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Determine which breakpoints are currently active
  const isSmallScreen = windowWidth < SMALL_BREAKPOINT;
  const isMediumScreen = windowWidth >= SMALL_BREAKPOINT && windowWidth <= MEDIUM_BREAKPOINT;
  const isLargeScreen = windowWidth > MEDIUM_BREAKPOINT;

  // Calculate screenSize based on the breakpoints
  let screenSize: ScreenSize = 'large';
  if (isSmallScreen) screenSize = 'small';
  else if (isMediumScreen) screenSize = 'medium';

  return { screenSize, isSmallScreen, isMediumScreen, isLargeScreen };
}
