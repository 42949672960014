import { useResponsiveQueries } from '../../hooks/useResponsiveQueries';

interface IProps {
  children: React.ReactNode;
}

export default function WrapperDesktop({ children }: IProps) {
  const { isSmallScreen } = useResponsiveQueries();

  if (isSmallScreen) return <></>;

  return <>{children}</>;
}
