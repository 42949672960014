import { clsx, type ClassValue } from 'clsx';
import { twMerge } from 'tailwind-merge';

// Function used by shadcn-ui to merge tailwind classes and clsx classes with ease
export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

// Function to transform an input model to an output model using a transformer function
export function transform<TInput, TOutput>(
  input: TInput,
  transformer: (input: TInput) => TOutput,
): TOutput {
  return transformer(input);
}
