import { Link } from 'react-router-dom';
import { ChevronRight } from 'lucide-react';
import { Fragment } from 'react';
import {
  SidebarGroup,
  SidebarGroupLabel,
  SidebarMenu,
  SidebarMenuAction,
  SidebarMenuButton,
  SidebarMenuItem,
  SidebarMenuSub,
  SidebarMenuSubButton,
  SidebarMenuSubItem,
  useSidebar,
} from './Sidebar';

import { INavItem } from '../../../shared/model/INavItem';
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from '../../../shared/components/collapsible/Collapsible';
import { cn } from '../../../lib/utils';
import { Badge } from '../../../shared/components/badge/Badge';
import { useResponsiveQueries } from '../../../shared/hooks/useResponsiveQueries';

interface INavMainProps {
  navItems: INavItem[];
}

export function NavMain({ navItems }: INavMainProps) {
  const { setOpenMobile } = useSidebar();
  const { isSmallScreen, isMediumScreen } = useResponsiveQueries();

  return (
    <SidebarGroup>
      {!isMediumScreen && <SidebarGroupLabel>All</SidebarGroupLabel>}

      <SidebarMenu>
        {navItems.map((navItem) => (
          <Fragment key={navItem.name}>
            {!navItem.isHidden && !navItem.isMobileOnly && (
              <Collapsible asChild defaultOpen={navItem.current}>
                <SidebarMenuItem>
                  <SidebarMenuButton asChild tooltip={navItem.name}>
                    <Link
                      to={navItem.route}
                      onClick={() => {
                        if (isSmallScreen) setOpenMobile(false);
                      }}
                    >
                      <navItem.icon
                        fill={navItem.current ? '#06b6d4' : 'transparent'}
                        className={cn(navItem.current && 'font-bold text-cyan')}
                      />
                      {!isMediumScreen && (
                        <span className={cn(navItem.current && 'font-bold text-cyan-dark')}>
                          {navItem.name}
                        </span>
                      )}
                      {(navItem.numberOfUnreadNotifications ?? 0) > 0 && (
                        <Badge className="ml-auto">{navItem.numberOfUnreadNotifications}</Badge>
                      )}
                    </Link>
                  </SidebarMenuButton>

                  {navItem.children?.length ? (
                    <>
                      <CollapsibleTrigger asChild>
                        <SidebarMenuAction className="data-[state=open]:rotate-90">
                          <ChevronRight />
                          <span className="sr-only">Toggle</span>
                        </SidebarMenuAction>
                      </CollapsibleTrigger>
                      <CollapsibleContent>
                        <SidebarMenuSub>
                          {navItem.children?.map((subItem) => (
                            <SidebarMenuSubItem key={subItem.name}>
                              <SidebarMenuSubButton asChild>
                                <Link to={subItem.route}>
                                  <span>{subItem.name}</span>
                                </Link>
                              </SidebarMenuSubButton>
                            </SidebarMenuSubItem>
                          ))}
                        </SidebarMenuSub>
                      </CollapsibleContent>
                    </>
                  ) : null}
                </SidebarMenuItem>
              </Collapsible>
            )}
          </Fragment>
        ))}
      </SidebarMenu>
    </SidebarGroup>
  );
}
