import {
  FieldErrors,
  FieldName,
  FieldValues,
  get,
  Message,
  Path,
  UseFormRegister,
  ValidationRule,
} from 'react-hook-form';
import { FieldValuesFromFieldErrors } from '@hookform/error-message';
import ValidationErrorMessage from './ValidationErrorMessage';
import Label from '../../text/Label';
import { Input } from '../../form/Input';
import { cn } from '../../../../lib/utils';

interface IProps<T extends FieldValues> {
  type?: string;
  labelText?: string;
  name: Path<T>;
  disabled?: boolean;
  placeholder?: string;
  register: UseFormRegister<T>;
  errors?: FieldErrors<T>;
  validationRules?: Partial<{
    required: Message | ValidationRule<boolean>;
    min: ValidationRule<number | string>;
    max: ValidationRule<number | string>;
    maxLength: ValidationRule<number>;
    minLength: ValidationRule<number>;
    pattern: ValidationRule<RegExp>;
  }>;
  onKeyUp?: React.KeyboardEventHandler<HTMLInputElement>;
  onInputBlur?: React.FocusEventHandler<HTMLInputElement>;
  inputCss?: string;
  inputFocusCss?: string;
  infoText?: string;
}

export default function TextFieldInput<T extends object>({
  labelText = '',
  name,
  disabled = false,
  register,
  errors,
  placeholder = '',
  validationRules = {},
  onKeyUp,
  onInputBlur,
  inputCss,
  inputFocusCss = 'focus:ring-0 focus:border-zinc-400',
  infoText,
}: IProps<T>) {
  const { onBlur, ...registration } = register(name, validationRules);

  const hasErrors = get(errors, name);

  return (
    <div>
      <Label
        htmlFor={name}
        text={labelText}
        infoText={infoText}
        extra={<ValidationErrorMessage errors={errors} name={name} />}
      />

      <Input
        type="text"
        id={name}
        disabled={disabled}
        onBlur={(e) => {
          if (onInputBlur) {
            onInputBlur(e);
          }
          onBlur(e);
        }}
        {...registration}
        className={cn(
          'shadow-sm placeholder:text-gray-400 block w-full !border-gray-300 rounded-md disabled:bg-gray-200',
          inputFocusCss,
          inputCss,
          hasErrors && 'focus:ring-red-500 focus:border-red-500 border-red-500',
        )}
        placeholder={placeholder}
        autoComplete="off"
        onKeyUp={onKeyUp}
      />
    </div>
  );
}
