import {
  FieldArrayWithId,
  FieldErrors,
  FieldErrorsImpl,
  useFieldArray,
  useForm,
  UseFormGetFieldState,
  UseFormGetValues,
  UseFormRegister,
} from 'react-hook-form';
import { Dispatch, SetStateAction, useState } from 'react';
import { X } from 'lucide-react';
import { useQueryClient } from '@tanstack/react-query';
import { PopoverTrigger } from '@radix-ui/react-popover';
import { ButtonColors } from '../../shared/constants/ButtonColors';
import Button from '../../shared/components/buttons/Button';
import TextFieldInput from '../../shared/components/inputs/text/TextFieldInput';
import TextAreaInput from '../../shared/components/inputs/text/TextAreaInput';
import { ICreateContactDto } from './models/ICreateContactDto';
import { REGEX_EMAIL } from '../../shared/constants/RegexValidations';
import { useCreateContactMutation, useUpdateContactMutation } from './queries/contactQueries';
import SubmitButton from '../../shared/components/buttons/SubmitButton';
import { UnsavedDataAlertDialog } from '../../shared/components/alert-dialog/UnsavedDataAlertDialog';
import { useOptionalControlledState } from '../../shared/hooks/useOptionalControlledState';
import FlexContainer from '../../shared/components/structure/FlexContainer';
import { IContactDto } from './models/IContactDto';
import { useToast } from '../../shared/components/toasts/use-toast';
import { useTenantsQuery } from '../Companies/queries/tenantQueries';
import { ITenantDto } from '../Companies/models/ITenantDto';
import AddIcon from '../../shared/components/icons/AddIcon';
import { ProfileCard } from '../../shared/components/cards/ProfileCard';
import { UserProfileDto } from '../Profile/models/UserProfile';
import {
  useAddUserAsContactCardAction,
  useConnectWithUserCardAction,
} from './hooks/contactProfileCardActionHooks';
import { searchUsersQueryBase } from '../Profile/queries/userProfileQueries';
import IconButton from '../../shared/components/buttons/IconButton';
import { Popover, PopoverContent } from '../../shared/components/popovers/Popover';
import CloseButton from '../../shared/components/buttons/CloseButton';
import SelectResourceTenant from '../Selectors/SelectResourceTenant';
import { ResourceType } from '../../shared/model/ResourceType';

type CreateContactFormValues = Omit<ICreateContactDto, 'tenantId'>;

const contactBaseDefaultValues: CreateContactFormValues = {
  firstName: '',
  lastName: '',
  emailAddresses: [{ value: '', label: '' }],
  phoneNumbers: [{ value: '', label: '' }],
  location: '',
  company: '',
  title: '',
  additionalInformation: '',
};

interface IUseContactCreateFormProps {
  values?: Partial<CreateContactFormValues>;
}

export function useContactCreateForm({ values: valuesFromProps }: IUseContactCreateFormProps) {
  const values = { ...contactBaseDefaultValues, ...valuesFromProps };
  return useForm<CreateContactFormValues>({
    mode: 'onChange',
    values,
    defaultValues: values,
  });
}

function EmailFieldArray({
  fields,
  append,
  remove,
  register,
  errors,
  getFieldState,
  getValues,
  onEmailKeyUp,
  existingUser,
}: {
  fields: FieldArrayWithId<CreateContactFormValues, 'emailAddresses'>[];
  append: (value: { value: string; label: string }) => void;
  remove: (index: number) => void;
  register: UseFormRegister<CreateContactFormValues>;
  errors: FieldErrorsImpl<CreateContactFormValues>;
  getFieldState: UseFormGetFieldState<CreateContactFormValues>;
  getValues: UseFormGetValues<CreateContactFormValues>;
  onEmailKeyUp: (value: string) => void;
  existingUser?: UserProfileDto;
}) {
  return (
    <>
      {fields.map((field, index) => (
        <div key={field.id}>
          <TextFieldInput
            labelText={index === 0 ? 'Email Address' : ''}
            name={`emailAddresses.${index}.value`}
            onKeyUp={() => onEmailKeyUp(getValues(`emailAddresses.${index}.value`))}
            type="text"
            register={register}
            errors={errors as FieldErrors<CreateContactFormValues>}
            validationRules={{
              pattern: { value: REGEX_EMAIL, message: 'Must be a valid email' },
            }}
          />
          <FlexContainer direction="row" className="my-2">
            {index === fields.length - 1 && !existingUser && (
              <IconButton
                isDisabled={
                  !!getFieldState(`emailAddresses.${index}.value`).error?.message ||
                  getValues(`emailAddresses.${index}.value`) === ''
                }
                icon={<AddIcon className="w-5 h-5" />}
                color={ButtonColors.Gray}
                onClick={() => append({ value: '', label: '' })}
                label="Add email"
                orientation="horizontal"
              />
            )}
            {index > 0 && !existingUser && (
              <IconButton
                icon={<X className="w-5 h-5 text-red" />}
                color={ButtonColors.Gray}
                onClick={() => remove(index)}
                label="Remove"
                orientation="horizontal"
              />
            )}
          </FlexContainer>
        </div>
      ))}
    </>
  );
}

function PhoneFieldArray({
  fields,
  append,
  remove,
  register,
  getValues,
}: {
  fields: FieldArrayWithId<CreateContactFormValues, 'phoneNumbers'>[];
  append: (value: { value: string; label: string }) => void;
  remove: (index: number) => void;
  register: UseFormRegister<CreateContactFormValues>;
  getValues: UseFormGetValues<CreateContactFormValues>;
}) {
  return (
    <>
      {fields.map((field, index) => (
        <div key={field.id}>
          <TextFieldInput
            labelText={index === 0 ? 'Phone Number' : ''}
            name={`phoneNumbers.${index}.value`}
            type="text"
            register={register}
          />
          <FlexContainer direction="row" className="my-2" gap="large">
            {index === fields.length - 1 && (
              <IconButton
                isDisabled={getValues(`phoneNumbers.${index}.value`) === ''}
                icon={<AddIcon className="w-5 h-5" />}
                color={ButtonColors.Gray}
                onClick={() => append({ value: '', label: '' })}
                label="Add number"
                orientation="horizontal"
              />
            )}
            {index > 0 && (
              <IconButton
                icon={<X className="w-5 h-5 text-red" />}
                color={ButtonColors.Gray}
                onClick={() => remove(index)}
                label="Remove"
                orientation="horizontal"
              />
            )}
          </FlexContainer>
        </div>
      ))}
    </>
  );
}

interface IExistingProfileCardProps {
  profile: UserProfileDto;
  onSuccess: () => void;
}

function ExistingProfileCard({ profile, onSuccess }: IExistingProfileCardProps) {
  return (
    <ProfileCard
      profile={profile}
      as="div"
      actionsPosition="inline"
      actions={[
        useAddUserAsContactCardAction({ userProfile: profile, onSuccess }),
        useConnectWithUserCardAction({ userProfile: profile, onSuccess }),
      ]}
    />
  );
}

function ExistingUserPopover({
  existingUser,
  onClose,
  onSuccess,
}: {
  existingUser: UserProfileDto;
  onClose: () => void;
  onSuccess: () => void;
}) {
  return (
    <Popover open={!!existingUser}>
      <PopoverTrigger />
      <PopoverContent side="bottom" align="start" sideOffset={-25}>
        <FlexContainer direction="row" gap="small">
          <FlexContainer direction="column" gap="none">
            <CloseButton className="absolute right-1 top-1 z-10" onClick={onClose} />
            <ExistingProfileCard profile={existingUser} onSuccess={onSuccess} />
          </FlexContainer>
        </FlexContainer>
      </PopoverContent>
    </Popover>
  );
}

interface IProps {
  defaultTenantId: string;
  form: ReturnType<typeof useContactCreateForm>;
  contactId?: string;
  unsavedDataWarningOpen?: boolean;
  onUnsavedDataWarningOpenChange?: Dispatch<SetStateAction<boolean>>;
  onSuccess: (data: IContactDto) => void;
  onCancel?: () => void;
}

/**
 * Renders a form for creating or editing a contact.
 *
 * @param defaultTenantId - The default tenant ID
 * @param form - The form object returned by `useContactCreateForm`,
 * @param contactId - Optional contact ID for editing an existing contact.
 * If not provided, the form is in "create" mode.
 * @param unsavedDataWarningOpenFromProps - Controls whether the unsaved data warning is displayed.
 * @param onUnsavedDataWarningOpenChangeFromProps - Function to update the unsaved data warning.
 * @param onSuccess - Eexecuted when the form is submitted successfully.
 * @param onCancel - Optional callback executed when the user cancels the current action
 *
 * @returns A React component rendering the contact creation or edit form.
 */

export default function ContactCreateEditForm({
  defaultTenantId,
  form,
  contactId,
  unsavedDataWarningOpen: unsavedDataWarningOpenFromProps,
  onUnsavedDataWarningOpenChange: onUnsavedDataWarningOpenChangeFromProps,
  onSuccess,
  onCancel,
}: IProps) {
  const [unsavedDataWarningOpen, onUnsavedDataWarningOpenChange] = useOptionalControlledState(
    unsavedDataWarningOpenFromProps,
    onUnsavedDataWarningOpenChangeFromProps,
    false,
  );

  const isUpdate = !!contactId;

  const [existingUser, setExistingUser] = useState<UserProfileDto | undefined>(undefined);
  const { toast } = useToast();
  const updateContactMutation = useUpdateContactMutation();
  const queryClient = useQueryClient();
  const tenants = useTenantsQuery().data?.pages.flat();
  const [selectedTenant, setSelectedTenant] = useState<ITenantDto>(
    tenants?.find((t) => t.id === defaultTenantId) || ({} as ITenantDto),
  );

  const {
    register,
    handleSubmit,
    reset,
    control,
    getValues,
    getFieldState,
    watch,
    formState: { isDirty, isValid, errors },
  } = form;

  const {
    fields: emailFields,
    append: emailAppend,
    remove: emailRemove,
  } = useFieldArray({
    control,
    name: 'emailAddresses',
  });

  const {
    fields: phoneFields,
    append: phoneAppend,
    remove: phoneRemove,
  } = useFieldArray({
    control,
    name: 'phoneNumbers',
  });

  watch('phoneNumbers');
  watch('emailAddresses');

  const validateEmail = (email: string) => {
    if (email.match(REGEX_EMAIL)) {
      return true;
    }

    return false;
  };

  const handleValidateEmail = async (email: string) => {
    if (!validateEmail(email)) return;

    const result = await queryClient.fetchQuery(
      searchUsersQueryBase({ searchTerm: email, limit: 1 }),
    );

    setExistingUser(result[0]);
  };

  const createContactMutation = useCreateContactMutation();

  const handleCancel = () => {
    if (onCancel) {
      onCancel();
      reset(contactBaseDefaultValues);
    }
  };

  const handleContinueClosing = () => {
    reset(contactBaseDefaultValues);
    handleCancel();
  };

  const handleCancelSaveContact = () => {
    if (isDirty) {
      onUnsavedDataWarningOpenChange(true);
    } else {
      handleCancel();
    }
  };

  const submit = (_contact: CreateContactFormValues) => {
    const contactClone = { ..._contact, tenantId: selectedTenant.id };

    // There is automatically an empty default value added to phoneNumbers array
    // i.e. [{ value: '' }]. We need to remove this empty value before submitting
    // Look for a better way of solving this later
    contactClone.phoneNumbers = contactClone.phoneNumbers.filter(
      (phoneNumber) => phoneNumber.value !== '',
    );

    contactClone.emailAddresses = contactClone.emailAddresses.filter(
      (emailAddress) => emailAddress.value !== '',
    );

    if (!isUpdate) {
      createContactMutation.mutate(
        { contact: contactClone },
        {
          onSuccess: (_result) => {
            reset(contactBaseDefaultValues);
            if (onSuccess) {
              toast({
                title: 'Success',
                description: 'The contact has been created successfully',
                variant: 'success',
              });
              onSuccess(_result);
            }
          },
        },
      );
    } else {
      updateContactMutation.mutate(
        { contact: contactClone, id: contactId },
        {
          onSuccess: (_result) => {
            reset(contactBaseDefaultValues);
            if (onSuccess) {
              toast({
                title: 'Success',
                description: 'The contact has been updated successfully',
                variant: 'success',
              });
              onSuccess(_result);
            }
          },
        },
      );
    }
  };

  return (
    <>
      <SelectResourceTenant
        selectedTenant={selectedTenant}
        tenants={tenants}
        entityName={ResourceType.Contact}
        onSelectedTenantChange={setSelectedTenant}
        isDisabled={isUpdate}
      />

      <form onSubmit={handleSubmit(submit)} className="block">
        <div className="grid grid-cols-2 gap-x-4 ">
          <TextFieldInput
            labelText="First Name"
            name="firstName"
            type="text"
            register={register}
            errors={errors}
            validationRules={{
              required: 'Required and between 2 and 50 characters',
              minLength: { value: 2, message: 'Required and between 2 and 50 characters' },
              maxLength: { value: 50, message: 'Required and between 2 and 50 characters' },
            }}
          />
          <TextFieldInput
            labelText="Last Name"
            name="lastName"
            type="text"
            register={register}
            errors={errors}
            validationRules={{
              required: 'Required and between 2 and 50 characters',
              minLength: { value: 2, message: 'Required and between 2 and 50 characters' },
              maxLength: { value: 50, message: 'Required and between 2 and 50 characters' },
            }}
          />

          <div>
            <EmailFieldArray
              fields={emailFields}
              append={emailAppend}
              remove={emailRemove}
              register={register}
              errors={errors}
              getValues={getValues}
              getFieldState={getFieldState}
              onEmailKeyUp={handleValidateEmail}
              existingUser={existingUser}
            />

            {existingUser && (
              <ExistingUserPopover
                existingUser={existingUser}
                onClose={() => setExistingUser(undefined)}
                onSuccess={() => setExistingUser(undefined)}
              />
            )}
          </div>

          <div>
            <PhoneFieldArray
              fields={phoneFields}
              append={phoneAppend}
              remove={phoneRemove}
              register={register}
              getValues={getValues}
            />
          </div>
          <TextFieldInput labelText="Location" name="location" type="text" register={register} />
          <TextFieldInput labelText="Title" name="title" type="text" register={register} />
          <TextFieldInput labelText="Company" name="company" type="text" register={register} />
        </div>
        <div className="mt-6 grid grid-cols-1">
          <TextAreaInput
            name="additionalInformation"
            register={register}
            labelText="Additional Information"
          />
        </div>
        <div className="pt-5">
          <FlexContainer justify="end">
            <Button color={ButtonColors.White} text="Cancel" onClick={handleCancelSaveContact} />
            <SubmitButton
              text={isUpdate ? 'Update' : 'Create'}
              isDisabled={!isValid || createContactMutation.isPending}
            />
          </FlexContainer>
        </div>
      </form>
      <UnsavedDataAlertDialog
        open={unsavedDataWarningOpen}
        onOpenChange={onUnsavedDataWarningOpenChange}
        onLeaveClick={handleContinueClosing}
      />
    </>
  );
}
