import { useNavigate, useParams } from 'react-router';
import useModal from '../../shared/components/modal/hooks/useModal';
import BoardCreateModal from './BoardCreateModal';
import HeaderAddButton from '../../shared/components/buttons/HeaderAddButton';

export default function BoardAddBoardButton() {
  const { openModal, closeModal } = useModal();
  const params = useParams();
  const navigate = useNavigate();

  const handleCreateBoardSuccess = (boardId: string) => {
    closeModal();
    navigate(`boards/${boardId}`);
  };

  return (
    <HeaderAddButton
      onClick={() => {
        openModal({
          content: (
            <BoardCreateModal
              projectId={params.projectId ?? ''}
              onCancel={closeModal}
              onSuccess={handleCreateBoardSuccess}
            />
          ),
        });
      }}
      ariaLabel="Add board"
    />
  );
}
