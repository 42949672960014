import * as React from 'react';
import { Settings } from 'lucide-react';
import logo from '../../../assets/images/logo.svg';
import logoY from '../../../assets/images/logoY.svg';
import {
  Sidebar,
  SidebarContent,
  SidebarFooter,
  SidebarHeader,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
} from './Sidebar';
import { NavMain } from './NavMain';
import { NavCompanies } from './NavCompanies';
import { NavUser } from './NavUser';
import { NavSecondary } from './NavSecondary';

import useMainNavigation from '../hooks/useMainNavigation';
import Notifications from '../../../features/Notifications/Notifications';
import { useResponsiveQueries } from '../../../shared/hooks/useResponsiveQueries';
import FlexContainer from '../../../shared/components/structure/FlexContainer';

const data = {
  navSecondary: [
    {
      title: 'Settings',
      url: '#',
      icon: Settings,
    },
  ],
};
export function AppSidebar({ ...props }: React.ComponentProps<typeof Sidebar>) {
  const navItems = useMainNavigation();

  const { isSmallScreen, isMediumScreen, isLargeScreen } = useResponsiveQueries();

  return (
    <Sidebar
      variant="inset"
      {...props}
      className="bg-white border !border-t-transparent border-b-transparent"
    >
      <SidebarHeader>
        <SidebarMenu>
          <SidebarMenuItem className="flex flex-row items-center justify-between">
            <SidebarMenuButton size="lg" asChild>
              <FlexContainer>
                {(isLargeScreen || isSmallScreen) && <img src={logo} alt="Yoin" className="ml-1.5" />}
                {isMediumScreen && <img src={logoY} alt="Yoin" className="m-1.5" />}
              </FlexContainer>
            </SidebarMenuButton>
            {!isMediumScreen && <Notifications />}
          </SidebarMenuItem>
        </SidebarMenu>
      </SidebarHeader>
      <SidebarContent>
        <NavMain navItems={navItems?.all} />
        <NavCompanies navItems={navItems?.myCompanies} />
        {/* // TODO: Add back when we have settings */}
        {/* <NavSecondary items={data.navSecondary} className="mt-auto" /> */}
      </SidebarContent>
      <SidebarFooter>
        <NavUser />
      </SidebarFooter>
    </Sidebar>
  );
}
