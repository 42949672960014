import { Fragment } from 'react';
import Header1 from '../../shared/components/headers/Header1';
import HoverMenu from '../../shared/components/menus/HoverMenu';
import FlexContainer from '../../shared/components/structure/FlexContainer';
import { IActionItem } from '../../shared/model/IActionItem';
import useIsMobileEntityDetail from '../../shared/hooks/useIsMobileEntityDetail';

export interface IServiceHeaderAction {
  id: string;
  name: string;
  icon: React.ElementType;
  isActive?: boolean;
  onClick: () => Promise<void>;
}

export type ActionPosition = 'start' | 'end';

export interface IServiceHeaderContent {
  id: string;
  content: React.ReactNode;
}

export interface IServiceHeaderProps {
  text: string;
  contentAfterHeader?: IServiceHeaderContent[];
  actions?: IActionItem[];
}

function Layout({ children }: { children: React.ReactNode }) {
  return (
    <FlexContainer align="center" direction="row">
      {children}
    </FlexContainer>
  );
}

export default function ServiceHeader({
  text,
  contentAfterHeader,
  actions = [],
}: IServiceHeaderProps) {
  const isMobileEntityDetail = useIsMobileEntityDetail();
  if (isMobileEntityDetail) return null;

  return (
    <Layout>
      <HoverMenu menuItems={actions} iconClassName="relative top-[18px] left-2">
        <Header1>{text}</Header1>
        <FlexContainer gap="small" align="center" className="mt-2.5 ml-3">
          {contentAfterHeader?.map((item) => (
            <Fragment key={item.id}>{item.content}</Fragment>
          ))}
        </FlexContainer>
      </HoverMenu>
    </Layout>
  );
}
