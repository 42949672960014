import { ListLayout } from '../../../layout/ListLayout';
import FlexContainer from '../../../shared/components/structure/FlexContainer';
import { useContactListColumns } from '../hooks/useContactListColumns';
import { useContactsInfiniteQuery } from '../queries/contactQueries';

export default function ContactAllContactsTab() {
  const columns = useContactListColumns();
  const query = useContactsInfiniteQuery();

  return (
    <FlexContainer direction="column" className="h-full w-full overflow-hidden">
      <ListLayout
        title="Contacts"
        columns={columns}
        query={query}
        // TODO: Add back when we implement expandable rows
        // renderSubComponent={(row) => <ContactSubComponent {...row} />}
      />
    </FlexContainer>
  );
}
