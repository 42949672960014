interface ISpacingContainerItemProps {
  children: React.ReactNode;
}

/**
 * @deprecated This component is obsolete and will be removed in a future release.
 * Should be replaced with FlexContainer
 */
export default function SpacingContainerItem({ children }: ISpacingContainerItemProps) {
  return <div className="text-left">{children}</div>;
}
