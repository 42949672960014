import { PencilIcon } from 'lucide-react';
import { ActivePipelineSettings } from './context/pipeline-context';
import { usePipelineItemQuery } from './queries/itemQueries';
import SidePanelHeadline from '../../layout/building-blocks/SidePanelHeadline';
import TabWrapper from '../../shared/components/tabs/TabWrapper';
import TabHeaderWrapper from '../../shared/components/tabs/TabHeaderWrapper';
import TabNavItem from '../../shared/components/tabs/TabNavItem';
import TabContentWrapper from '../../shared/components/tabs/TabContentWrapper';
import TabContent from '../../shared/components/tabs/TabContent';
import useEntityManifest from '../../shared/hooks/useEntityManifest';
import Text from '../../shared/components/text/Text';
import { ResourceType } from '../../shared/model/ResourceType';
import HorizontalDivider from '../../shared/components/dividers/HorizontalDivider';
import FlexContainer from '../../shared/components/structure/FlexContainer';
import HeaderSubHeaders from '../../shared/components/headers/HeaderSubHeaders';
import { usePipelineQuery } from './queries/pipelineQueries';
import Header2 from '../../shared/components/headers/Header2';
import Header3 from '../../shared/components/headers/Header3';
import { swedishCurrencyFormatter } from '../../shared/utils/numberUtils';
import useModal from '../../shared/components/modal/hooks/useModal';
import { IActionItem } from '../../shared/model/IActionItem';
import PipelineEditItemForm from './PipelineEditItemForm';
import { ProfilePropertyList } from '../Profile/ProfilePropertyList';
import { NetworkPropertyList } from '../Networks/NetworkPropertyList';
import Activities from '../Activities/Activities';
import HoverMenu from '../../shared/components/menus/HoverMenu';
import usePipelineAvatarActions from './hooks/usePipelineAvatarActions';
import useSearchParamsTabNavigation from '../../shared/components/tabs/hooks/useSearchParamsTabNavigation';
import { Card } from '../../shared/components/cards/Card';
import StatusIndicator from '../../shared/components/status-indicator/StatusIndicator';
import { Separator } from '../../layout/separator/Separator';
import { createRoute } from '../../layout/navigation/helpers/routingHelpers';

interface IPipelineEditItemProps {
  activePipelineSettings: ActivePipelineSettings;
  hideHeader?: boolean;
}
// TODO: We will not need hideHeader prop in the future
// Should be built with the SidePanelLayout
export default function PipelineEditItem({
  activePipelineSettings,
  hideHeader,
}: IPipelineEditItemProps) {
  const [activeTab, setActiveTab] = useSearchParamsTabNavigation('info');
  const { openModal, closeModal } = useModal();
  const { pipelineId } = activePipelineSettings;
  const pipelineItemQuery = usePipelineItemQuery({
    pipelineId,
    stageId: activePipelineSettings.stageId,
    itemId: activePipelineSettings.item.id,
  });

  const avatarActions = usePipelineAvatarActions(activePipelineSettings);

  const pipeline = usePipelineQuery(pipelineId);
  const pipelineItem = pipelineItemQuery.data;
  const resourceType = pipelineItem?.sharedResource?.resourceType || ResourceType.PipelineItem;
  const resourceManifest = useEntityManifest(resourceType);

  if (!pipelineItemQuery.isSuccess || !pipelineItem) {
    return <></>;
  }

  const { stageId } = pipelineItem;
  const itemId = pipelineItem.id;
  const itemMenu: IActionItem[] = [];

  itemMenu.push({
    id: 'Edit',
    title: 'Edit',
    icon: PencilIcon,
    onClick: () => {
      openModal({
        title: 'Edit',
        content: (
          <PipelineEditItemForm
            pipelineItem={pipelineItem}
            activePipelineSettings={activePipelineSettings}
            onSaveCallback={closeModal}
          />
        ),
      });
    },
  });

  const renderHeader = () => (
    <FlexContainer direction="row" gap="small" align="stretch" justify="between">
      <HoverMenu className="w-full" menuItems={avatarActions}>
        <FlexContainer className="w-full" gap="small" align="center">
          <Header3>{pipelineItem.sharedResource?.payload?.displayName}</Header3>
          <StatusIndicator
            className="mt-1"
            {...{
              status: pipelineItem.sharedResource?.isLiveUpdated ? 'on' : 'off',
              tooltipMessage: pipelineItem.sharedResource?.isLiveUpdated
                ? 'Sync with source is on'
                : 'Sync with source is off',
            }}
          />
        </FlexContainer>
      </HoverMenu>
    </FlexContainer>
  );

  return (
    <>
      {!hideHeader && (
        <SidePanelHeadline
          headline={pipelineItem?.name}
          imageSrc={resourceManifest.icon}
          resourceId={pipelineItem?.sharedResource?.resourceId}
          // TODO: We need to integrate createRoute with manifest
          // to make this work for all resource types
          resourceLink={createRoute(
            resourceType === ResourceType.Network ? 'networks/detail' : 'contacts/detail',
            pipelineItem?.sharedResource?.resourceId,
          )}
          showLinkToResource={!!pipelineItem?.sharedResource}
          colorSetting={resourceManifest.color}
        />
      )}
      <TabWrapper>
        <TabHeaderWrapper>
          <TabNavItem
            id="info"
            activeId={activeTab}
            title="Info"
            setActiveTab={() => setActiveTab('info')}
          />
          <TabNavItem
            id="activities"
            activeId={activeTab}
            title="Tasks"
            setActiveTab={() => setActiveTab('activities')}
          />
        </TabHeaderWrapper>
        <TabContentWrapper>
          <TabContent id="info" activeTabId={activeTab}>
            <FlexContainer direction="column" gap="small" align="stretch">
              <FlexContainer direction="column" gap="small" align="start">
                <HoverMenu className="w-full" menuItems={itemMenu}>
                  <Header2>Overview</Header2>
                </HoverMenu>
                <Text size="small">{pipelineItem?.description}</Text>
                <HeaderSubHeaders
                  text={`Price (${pipeline.data?.currencyIsoCode})`}
                  size="small"
                  subTexts={[swedishCurrencyFormatter(pipelineItem?.value)]}
                />
              </FlexContainer>

              <HorizontalDivider />

              {pipelineItem.sharedResource && (
                <>
                  <Header2>Connected Resources</Header2>
                  <Card
                    isInteractive={false}
                    avatarUrl={pipelineItem.sharedResource?.payload?.photoUrl}
                    as="div"
                  >
                    {renderHeader()}
                    <Separator className="my-1" />
                    {pipelineItem.sharedResource?.resourceType === ResourceType.Network ? (
                      <NetworkPropertyList network={pipelineItem.sharedResource?.payload} />
                    ) : (
                      <ProfilePropertyList profile={pipelineItem.sharedResource?.payload} />
                    )}
                  </Card>
                </>
              )}
            </FlexContainer>
          </TabContent>
          <TabContent id="activities" activeTabId={activeTab}>
            <Activities
              resourceIds={[pipelineId, stageId, itemId]}
              type={ResourceType.PipelineItem}
            />
          </TabContent>
        </TabContentWrapper>
      </TabWrapper>
    </>
  );
}
