import { Info } from 'lucide-react';
import Header2 from '../headers/Header2';
import FlexContainer from '../structure/FlexContainer';
import Text from '../text/Text';

interface IProps {
  headline: string;
  text: string;
}
export default function InfoBox({ headline, text }: IProps) {
  return (
    <FlexContainer direction="column" className="w-full bg-gray-light rounded-md p-2">
      <FlexContainer direction="row" gap="small" align="center">
        <Info className="w-6 h-6 text" />
        <Header2>{headline}</Header2>
      </FlexContainer>
      <Text size="small">{text}</Text>
    </FlexContainer>
  );
}
