import { ChangeEvent, useMemo, useState } from 'react';
import {
  useListShareContactRequestsQuery,
  useShareContactMutation,
} from '../Request/queries/shareContactRequestQueries';
import { IContactDto } from './models/IContactDto';
import { ShareContactRequestStatuses } from '../../shared/model/IShareContactRequestDto';
import SearchBar from '../../shared/components/search/SearchBar';
import useDebounce from '../../shared/hooks/useDebounce';
import { useContactsForSharedQuery } from './queries/contactQueries';
import { useToast } from '../../shared/components/toasts/use-toast';
import { transform } from '../../lib/utils';
import { transformContactToAvatarListItem } from './models/transformers/contactTransformers';
import { ContactAvatarList, IContactAvatarListItem } from './ContactAvatarList';
import FlexContainer from '../../shared/components/structure/FlexContainer';

interface IProps {
  contact: IContactDto;
  status?: ShareContactRequestStatuses | 'All';
}

export function ContactShareThisContactToList({ contact, status = 'All' }: IProps) {
  const [searchTerm, setSearchTerm] = useState('');

  const debouncedSearchTerm = useDebounce(searchTerm);
  const contactsQuery = useContactsForSharedQuery(debouncedSearchTerm, contact.id);
  const shareContactMutation = useShareContactMutation();
  const { toast } = useToast();

  const sentShareContactRequestsQuery = useListShareContactRequestsQuery({
    sharedContactId: contact.id,
    searchType: 'Sent',
    status: status === 'All' ? ['Accepted', 'Pending', 'Rejected'] : [status],
  });

  const unSentShareContacts = useMemo(() => {
    if (!contactsQuery.isSuccess || !sentShareContactRequestsQuery.isSuccess) {
      return [];
    }

    const contacts = contactsQuery.data;

    const sharedContactsSent = sentShareContactRequestsQuery.data;
    const sharedContactsIds = new Set(sharedContactsSent?.map((sc) => sc.targetUserId));

    return contacts.filter((c) => c.userId && !sharedContactsIds.has(c.userId));
  }, [
    contactsQuery.isSuccess,
    contactsQuery.data,
    sentShareContactRequestsQuery.isSuccess,
    sentShareContactRequestsQuery.data,
  ]);

  const handleOnSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.currentTarget.value);
  };

  const handleShareContact = (selectedContactToShare: IContactAvatarListItem) => {
    if (!selectedContactToShare.userId) return;

    shareContactMutation.mutate(
      {
        contactId: contact.id,
        targetUserIds: [selectedContactToShare.userId],
      },
      {
        onSuccess: () => {
          toast({
            title: 'Success',
            variant: 'default',
            description: `${contact.displayName} has been shared to ${selectedContactToShare.displayName}`,
          });
        },
      },
    );
  };

  return (
    <FlexContainer direction="column">
      <SearchBar searchTerm={searchTerm} onSearchChange={handleOnSearchChange} />

      <ContactAvatarList
        items={unSentShareContacts.map((c) => transform(c, transformContactToAvatarListItem))}
        onAction={handleShareContact}
        actionLabel="Share"
      />
    </FlexContainer>
  );
}
