import { ChartLine, Check, Clock } from 'lucide-react';
import { useNavigate } from 'react-router';
import { useShareContactRequestsCountByStatusQuery } from '../Request/queries/shareContactRequestQueries';
import { IContactDto } from './models/IContactDto';
import StatsIndicator from '../../shared/components/kpi/StatsIndicator';

interface IContactStatsProps {
  contact: IContactDto;
}

export default function ContactStats({ contact }: IContactStatsProps) {
  const navigate = useNavigate();

  const shareContactRequestsQuery = useShareContactRequestsCountByStatusQuery({
    sharedContactId: contact.id,
    searchType: 'Sent',
    status: ['Accepted', 'Pending', 'Rejected'],
  });

  const data = shareContactRequestsQuery?.data;
  const { Total, Pending, Accepted, Rejected } = data || {};

  const navigateToShare = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    navigate('?tab=share', { replace: true });
  };

  return (
    <button
      type="button"
      className="flex flex-row w-full justify-between cursor-pointer"
      onClick={navigateToShare}
    >
      {shareContactRequestsQuery.data && (
        <>
          <StatsIndicator
            label="Total"
            color="cyan"
            value={((Total ?? 0) - (Rejected ?? 0)).toString() || '0'}
            Icon={ChartLine}
          />

          <StatsIndicator
            label="Pending"
            color="yellow"
            value={Pending?.toString() || '0'}
            Icon={Clock}
          />
          <StatsIndicator
            label="Accepted"
            color="green"
            value={Accepted?.toString() || '0'}
            Icon={Check}
          />
        </>
      )}
    </button>
  );
}
