import { useMemo } from 'react';
import { useParams } from 'react-router';
import { abbreviateString } from '../../../shared/utils/stringUtils';
import { useTenantsQuery } from '../../Companies/queries/tenantQueries';
import {
  convertToBreadcrumbNavigatorItems,
  IBreadcrumbNavigatorItem,
} from '../../../layout/navigation/BreadcrumbNavigator/helpers/breadcrumbNavigatorHelper';
import { createRoute } from '../../../layout/navigation/helpers/routingHelpers';
import { useContactQuery } from '../queries/contactQueries';

export default function useContactsBreadcrumbNavigation() {
  const tenantQuery = useTenantsQuery();

  const { id } = useParams();
  const contact = useContactQuery(id);

  const flatTenants = tenantQuery.data?.pages?.flat() || [];
  const contactsTenant = flatTenants.find((tenant) => tenant.id === contact.data?.tenantId);

  const breadcrumbNavigatorItems = useMemo<IBreadcrumbNavigatorItem[]>(() => {
    const breadcrumbNavigatorItemsSorted = [];

    // Step 3: Push tenants to the breadcrumbNavigatorItems if the current page is
    // details of a contact in order to enable tenant switching
    if (window.location.pathname.includes('detail') && contactsTenant) {
      breadcrumbNavigatorItemsSorted.push({
        id: '',
        name: contactsTenant.name,
        abbreviation: abbreviateString(contactsTenant?.name),
        route: createRoute('contacts/list', contactsTenant.id),
        isActive: true,
        isDisabled: true,
        siblings: convertToBreadcrumbNavigatorItems(flatTenants, '/contacts/list/'),
      });
    }
    breadcrumbNavigatorItemsSorted.unshift({
      id: 'contacts',
      name: 'Contacts',
      abbreviation: abbreviateString('Contacts'),
      route: '/',
      isActive: false,
      isDisabled: true,
    });

    return breadcrumbNavigatorItemsSorted;
  }, [flatTenants]);

  return { breadcrumbNavigatorItems };
}
