export type SpacerDistance = 'small' | 'medium' | 'large' | 'xLarge';

export const distanceClassLookup: Record<SpacerDistance, string> = {
  small: 'mt-0.5 mb-0.5',
  medium: 'mt-1 mb-1',
  large: 'mt-2 mb-2',
  xLarge: 'mt-4 mb-4',
};

export interface IHorizontalSpacerProps {
  distance?: SpacerDistance;
}
/**
 * @deprecated This component is obsolete and will be removed in a future release.
 *             Please use `Separator` instead.
 */

export default function HorizontalSpacer({ distance = 'medium' }: IHorizontalSpacerProps) {
  const dividerDistanceClass = distanceClassLookup[distance];
  return <div className={`${dividerDistanceClass} h-[1px] w-full`} />;
}
