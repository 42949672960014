import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useTenants from '../../shared/hooks/useTenant';
import { useTenantsQuery } from '../Companies/queries/tenantQueries';
import { createRoute } from '../../layout/navigation/helpers/routingHelpers';

export default function Index() {
  const navigate = useNavigate();

  const { activeTenant, setActiveTenant } = useTenants();
  const tenants = useTenantsQuery().data?.pages.flat();

  useEffect(() => {
    if (!tenants) return;
    // First time user logs in, set the active tenant to the first tenant
    if (Object.keys(activeTenant).length === 0) {
      setActiveTenant(tenants[0]);
    }

    const redirectId = activeTenant.id || tenants[0].id;

    navigate(createRoute('contacts/list', redirectId), { replace: true });
  }, [activeTenant, navigate, setActiveTenant, tenants]);

  return <></>;
}
