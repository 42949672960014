// From shadcn/ui - https://ui.shadcn.com/docs/components/badge

import * as React from 'react';
import { cva, type VariantProps } from 'class-variance-authority';
import { cn } from '../../../lib/utils';

const badgeVariants = cva(
  'inline-flex rounded-full border w-5 h-5 text-xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 items-center justify-center',
  {
    variants: {
      variant: {
        default: 'border-transparent bg-red text-white shadow hover:bg-red/80',
        blue: 'border-transparent bg-blue text-white hover:bg-blue/80',
        outline: ' border bg-white text-light hover:bg-blue/80',
        unstyled: '!border-none bg-transparent text',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  },
);

export interface BadgeProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof badgeVariants> {}

function Badge({ className, variant, ...props }: BadgeProps) {
  return <div className={cn(badgeVariants({ variant }), className)} {...props} />;
}

export { Badge, badgeVariants };
