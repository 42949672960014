import Text from '../text/Text';

export type TextSize = 'large' | 'normal' | 'small';

interface ISubHeaderProps {
  text: string;
  subTexts?: string[];
  size?: TextSize;
}
export default function HeaderSubHeaders({ text, subTexts, size = 'normal' }: ISubHeaderProps) {
  const mappings = {
    large: (
      <>
        <Text size="large">{text}</Text>
        {subTexts?.map((subText) => (
          <Text key={subText} size="normal" opacity="75" leading="snug">
            {subText}
          </Text>
        ))}
      </>
    ),
    normal: (
      <>
        <Text size="normal">{text}</Text>
        {subTexts?.map((subText) => (
          <Text key={subText} size="xSmall" opacity="75" leading="snug">
            {subText}
          </Text>
        ))}
      </>
    ),
    small: (
      <>
        <Text size="small" weight="medium">
          {text}
        </Text>
        {subTexts?.map((subText) => (
          <Text key={subText} size="xSmall" opacity="75" leading="snug">
            {subText}
          </Text>
        ))}
      </>
    ),
  };

  return <div className="flex flex-col text-left">{mappings[size]}</div>;
}
