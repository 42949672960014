import { Action, INotificationDto } from '../../shared/model/INotificationDto';
import TabNavItem from '../../shared/components/tabs/TabNavItem';
import TabContentWrapper from '../../shared/components/tabs/TabContentWrapper';
import TabContent from '../../shared/components/tabs/TabContent';
import NotificationList from './NotificationList';
import TabDropdownItem from '../../shared/components/tabs/TabDropdownItem';
import Requests from '../Request/Requests';
import useNotificationActions from './hooks/useNotificationActions';
import FlexContainer from '../../shared/components/structure/FlexContainer';
import CloseButton from '../../shared/components/buttons/CloseButton';
import Header2 from '../../shared/components/headers/Header2';

interface IProps {
  closeCallback: () => void;
  onExecuteAction: (
    promise: Promise<unknown>,
    notification: INotificationDto,
    action: Action,
  ) => void;
}

export default function NotificationsTray({ closeCallback, onExecuteAction }: IProps) {
  const {
    items: menuItems,
    activeTabId,
    setActiveTabId,
    numberOfUnreadNotifications,
    numberOfUnreadContactNotifications,
    numberOfUnreadNetworkNotifications,
    allNotificationsQuery,
    unreadNotificationsQuery,
    contactNotificationsQuery,
    networkNotificationsQuery,
    boardNotificationsQuery,
    projectNotificationsQuery,
    connectionRequestsQuery,
    networkInviteRequestsQuery,
    organizationInvitationsQuery,
    boardInviteRequestsQuery,
    invitationsQuery,
    projectInviteRequestsQuery,
    companyNotificationsQuery,
  } = useNotificationActions();

  return (
    <FlexContainer direction="column" className="w-full h-full" gap="none">
      <FlexContainer direction="column" className="p-3" gap="none">
        <FlexContainer gap="none" className="ml-1.5" justify="between">
          <Header2>Activity</Header2>
          <CloseButton onClick={closeCallback} />
        </FlexContainer>

        <FlexContainer className="pb-0.5 mt-4">
          <TabNavItem
            id="unread"
            textColor="gray-dark"
            title="Unread"
            badgeCount={numberOfUnreadNotifications}
            activeId={activeTabId}
            setActiveTab={setActiveTabId}
          />

          <TabNavItem
            id="contacts"
            textColor="gray-dark"
            title="Contacts"
            badgeCount={numberOfUnreadContactNotifications}
            activeId={activeTabId}
            setActiveTab={setActiveTabId}
          />
          <TabNavItem
            id="networks"
            textColor="gray-dark"
            title="Networks"
            badgeCount={numberOfUnreadNetworkNotifications}
            activeId={activeTabId}
            setActiveTab={setActiveTabId}
          />
          <TabDropdownItem
            menuItems={menuItems}
            isActive={menuItems.some((item) => item.id === activeTabId)}
          />
        </FlexContainer>
      </FlexContainer>

      <TabContentWrapper className="!p-0 my-4 max-h-96">
        <TabContent id="unread" activeTabId={activeTabId}>
          <NotificationList
            query={unreadNotificationsQuery}
            closeCallback={closeCallback}
            onExecuteAction={onExecuteAction}
          />
        </TabContent>

        <TabContent id="all" activeTabId={activeTabId}>
          <NotificationList
            query={allNotificationsQuery}
            closeCallback={closeCallback}
            onExecuteAction={onExecuteAction}
          />
        </TabContent>

        <TabContent id="contacts" activeTabId={activeTabId}>
          <NotificationList
            query={contactNotificationsQuery}
            closeCallback={closeCallback}
            onExecuteAction={onExecuteAction}
          />
        </TabContent>

        <TabContent id="networks" activeTabId={activeTabId}>
          <NotificationList
            query={networkNotificationsQuery}
            closeCallback={closeCallback}
            onExecuteAction={onExecuteAction}
          />
        </TabContent>

        <TabContent id="board" activeTabId={activeTabId}>
          <NotificationList
            query={boardNotificationsQuery}
            closeCallback={closeCallback}
            onExecuteAction={onExecuteAction}
          />
        </TabContent>
        <TabContent id="project" activeTabId={activeTabId}>
          <NotificationList
            query={projectNotificationsQuery}
            closeCallback={closeCallback}
            onExecuteAction={onExecuteAction}
          />
        </TabContent>
        <TabContent id="company" activeTabId={activeTabId}>
          <NotificationList
            query={companyNotificationsQuery}
            closeCallback={closeCallback}
            onExecuteAction={onExecuteAction}
          />
        </TabContent>
        <TabContent id="requests" activeTabId={activeTabId}>
          <Requests
            connectionRequestsQuery={connectionRequestsQuery}
            networkInviteRequestsQuery={networkInviteRequestsQuery}
            pipelineInviteRequestsQuery={boardInviteRequestsQuery}
            organizationInviteRequestsQuery={organizationInvitationsQuery}
            invitationsQuery={invitationsQuery}
            projectInviteRequestsQuery={projectInviteRequestsQuery}
          />
        </TabContent>
      </TabContentWrapper>
    </FlexContainer>
  );
}
