import React from 'react';
import { cva, VariantProps } from 'class-variance-authority';
import { TrendType } from '../../../features/Pipelines/models/IPipelineKpiDto';
import FlexContainer from '../structure/FlexContainer';
import StatsIndicator from './StatsIndicator';
import { TextColor } from '../text/Text';

export interface IKpi {
  value: string | number | undefined;
  label?: string;
  color?: TextColor;
  trend?: TrendType;
  icon: React.ElementType;
}

const labelVariants = cva('text-sm', {
  variants: {
    trend: {
      Up: 'arrow-up',
      Down: 'arrow-down',
      Flat: 'arrow-right',
      None: '',
    },
  },
  defaultVariants: {
    trend: 'None',
  },
});

type LabelVariantsProps = VariantProps<typeof labelVariants>;

export function LabelWithTrend({ label, trend }: { label: string; trend?: TrendType }) {
  let trendVariant: LabelVariantsProps['trend'];
  if (trend === 'Up' || trend === 'Down') {
    trendVariant = trend;
  } else if (trend) {
    trendVariant = 'Flat';
  } else {
    trendVariant = 'None';
  }

  return <span className={labelVariants({ trend: trendVariant })}>{label}</span>;
}

export function Trend({ trend }: { trend?: TrendType }) {
  let trendVariant: LabelVariantsProps['trend'];
  if (trend === 'Up' || trend === 'Down') {
    trendVariant = trend;
  } else if (trend) {
    trendVariant = 'Flat';
  } else {
    trendVariant = 'None';
  }

  return <span className={labelVariants({ trend: trendVariant })} />;
}

interface KpiIndicatorProps {
  data: IKpi[];
}

export function KpiIndicator({ data }: KpiIndicatorProps) {
  return (
    <FlexContainer gap="large">
      {data.map((item) => (
        <StatsIndicator
          key={item.label}
          label={item.label || ''}
          color={item.color}
          value={item.value?.toString() || '0'}
          trend={item.trend as TrendType}
          Icon={item.icon}
        />
      ))}
    </FlexContainer>
  );
}
