import { Link } from 'react-router-dom';
import { IContactDto } from './models/IContactDto';
import ContactUserInformation from './ContactUserInformation';
import FlexContainer from '../../shared/components/structure/FlexContainer';
import EmptyState from '../../shared/components/empty-state/EmptyState';
import Button from '../../shared/components/buttons/Button';
import { ButtonColors } from '../../shared/constants/ButtonColors';
import IconButton from '../../shared/components/buttons/IconButton';
import AddIcon from '../../shared/components/icons/AddIcon';
import { Separator } from '../../layout/separator/Separator';

interface IProps {
  contact: IContactDto;
}
export default function ContactInfoMain({ contact }: IProps) {
  return (
    <div className="pr-4">
      <div className="grid grid-cols-[repeat(auto-fit,minmax(250px,1fr))] gap-4">
        <FlexContainer direction="column" gap="small" className="h-full">
          <ContactUserInformation contact={contact} />
        </FlexContainer>
        <FlexContainer align="start" className="mt-3">
          <IconButton
            icon={<AddIcon className="w-5 h-5" />}
            color={ButtonColors.Gray}
            onClick={() => undefined}
            label="Add info"
            orientation="horizontal"
          />
        </FlexContainer>
      </div>
    </div>
  );
}
