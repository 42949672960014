import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { useEffect } from 'react';
import {
  CLEAR_EDITOR_COMMAND,
  COMMAND_PRIORITY_HIGH,
  COMMAND_PRIORITY_LOW,
  KEY_ENTER_COMMAND,
  createCommand,
} from 'lexical';
import { $convertToMarkdownString } from '@lexical/markdown';
import { $isAutoLinkNode } from '@lexical/link';
import { mergeRegister } from '@lexical/utils';
import { IMessageDto } from '../../../shared/model/IMessageDto';
import { useFileDropContext } from '../../../shared/components/file/FileDropContainer';
import { useStandaloneEditor } from '../../../shared/lexical/useStandaloneEditor';
import { useLexicalMarkdownToPlainText } from '../../../shared/lexical/markdown/useLexicalMarkdownToPlainText';
import { $findAllNodesOfType } from '../../../shared/lexical/lexicalUtils';
import { $isMentionNode } from '../../../shared/lexical/mentions/MentionNode';
import { MARKDOWN_TRANSFORMERS, MESSAGE_EDITOR_CONFIG } from './messageEditorConfig';
import { useResponsiveQueries } from '../../../shared/hooks/useResponsiveQueries';

function getReplyText(replyToMessage: IMessageDto) {
  return replyToMessage.text !== ''
    ? replyToMessage.text
    : `[Attachment]: ${replyToMessage.metaData?.files?.at(0)?.name as string}`;
}

export const MESSAGE_SUBMIT_COMMAND = createCommand<undefined>('MESSAGE_SUBMIT_COMMAND');

export type MentionExport = {
  displayName: string;
  type: string;
};

export type ShareContactMentionExport = MentionExport & {
  type: 'share';
  contactId: string;
};

export type AccountMentionExport = MentionExport & {
  type: 'mention';
  userId: string;
};

export type LinkExport = {
  url: string;
};

export type ReplyToMessageExport = {
  id: string;
  text: string;
};

export interface IMessageSubmitProps {
  messageText: string;
  replyTo?: ReplyToMessageExport;
  files?: File[];
  mentions?: MentionExport[];
  links?: LinkExport[];
}

export interface IMessageSubmitCommandProps {
  // eslint-disable-next-line react/no-unused-prop-types
  replyToMessage?: IMessageDto;
  onSubmit?: (props: IMessageSubmitProps) => void;
}

export function useMessageSubmitCommand({ replyToMessage, onSubmit }: IMessageSubmitCommandProps) {
  const [editor] = useLexicalComposerContext();
  const fileDropContext = useFileDropContext();
  const { isSmallScreen } = useResponsiveQueries();
  const { editor: standaloneEditor } = useStandaloneEditor(MESSAGE_EDITOR_CONFIG);
  const markdownToPlainText = useLexicalMarkdownToPlainText(standaloneEditor);

  useEffect(() => {
    const commands = [
      editor.registerCommand(
        MESSAGE_SUBMIT_COMMAND,
        (_, _editor) => {
          if (!onSubmit) return false;

          const files = fileDropContext?.files || [];
          let messageText = '';
          let mentions: MentionExport[] = [];
          let links: LinkExport[] = [];

          const replyTo =
            replyToMessage && replyToMessage.id
              ? {
                  id: replyToMessage.id,
                  text: markdownToPlainText(getReplyText(replyToMessage), 50),
                }
              : undefined;
          const editorState = _editor.getEditorState();
          editorState.read(() => {
            messageText = $convertToMarkdownString(MARKDOWN_TRANSFORMERS);
            mentions = $findAllNodesOfType($isMentionNode).map((node) => ({
              displayName: node.getDisplayName(),
              type: node.getMentionType(),
              contactId: node.getMentionType() === 'share' ? node.getReference() : undefined,
              userId: node.getMentionType() === 'mention' ? node.getReference() : undefined,
            }));
            links = $findAllNodesOfType($isAutoLinkNode).map((node) => ({
              url: node.getURL(),
            }));
          });

          _editor.dispatchCommand(CLEAR_EDITOR_COMMAND, undefined);
          fileDropContext?.clearFiles();
          if (!messageText && files.length === 0) return false;
          onSubmit({ messageText, files, mentions, links, replyTo });

          return true;
        },
        COMMAND_PRIORITY_HIGH,
      ),
    ];
    if (!isSmallScreen) {
      commands.push(
        editor.registerCommand(
          KEY_ENTER_COMMAND,
          (event) => {
            if (!event || event.shiftKey) {
              return false;
            }
            event?.preventDefault();
            return editor.dispatchCommand(MESSAGE_SUBMIT_COMMAND, undefined);
          },
          COMMAND_PRIORITY_LOW,
        ),
      );
    }
    return mergeRegister(...commands);
  }, [editor, isSmallScreen, onSubmit, fileDropContext, replyToMessage]);
}
