import HeaderAddButton from '../../shared/components/buttons/HeaderAddButton';
import useModal from '../../shared/components/modal/hooks/useModal';
import NetworkCreateEditModal from './NetworkCreateEditModal';

export default function NetworkAddNetworkButton() {
  const { openModal, closeModal } = useModal();
  const handleCreateNetworkSuccess = () => {
    closeModal();
  };

  return (
    <HeaderAddButton
      onClick={() => {
        openModal({
          title: 'Add network',
          content: (
            <NetworkCreateEditModal onSuccess={handleCreateNetworkSuccess} onCancel={closeModal} />
          ),
        });
      }}
      ariaLabel="Add network"
    />
  );
}
