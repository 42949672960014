import SidePanelToggleButton from './SidePanelToggleButton';
import SidePanelClosedWrapper from './SidePanelClosedWrapper';
import { IColorSetting } from '../../shared/hooks/useEntityManifest';
import useCallbackOnResize from '../../shared/hooks/useCallbackOnResize';
import { cn } from '../../lib/utils';

const maxScreenSize = 1500;

interface ISidePanelProps {
  children: React.ReactNode;
  isOpen: boolean;
  colorSetting: IColorSetting;
  onToggle: () => void;
  closeSidePanel: () => void;
  openSidePanel: () => void;
  renderClosed: () => JSX.Element;
}

interface ISidepanelWrapperProps {
  isOpen: boolean;
  children: React.ReactNode;
}

function SidePanelWrapper({ isOpen, children }: ISidepanelWrapperProps) {
  return (
    <section
      className={cn(
        'flex flex-col flex-none bg-white text-sm text relative mb-5 mr-4 rounded-lg',
        isOpen ? 'w-[30rem] max-w-xl' : 'w-[5rem]',
      )}
    >
      {children}
    </section>
  );
}

function SidePanelToggleButtonPositioner({ children }: { children: React.ReactNode }) {
  return <div className="absolute left-[-12px] top-5 z-10">{children}</div>;
}

export default function SidePanel({
  children,
  isOpen,
  colorSetting,
  onToggle,
  closeSidePanel,
  openSidePanel,
  renderClosed,
}: ISidePanelProps) {
  useCallbackOnResize(closeSidePanel, openSidePanel, maxScreenSize);

  return (
    <SidePanelWrapper isOpen={isOpen}>
      <SidePanelToggleButtonPositioner>
        <SidePanelToggleButton onToggle={onToggle} isOpen={isOpen} colorSetting={colorSetting} />
      </SidePanelToggleButtonPositioner>
      {isOpen ? (
        children
      ) : (
        <SidePanelClosedWrapper colorSettings={colorSetting}>
          {renderClosed()}
        </SidePanelClosedWrapper>
      )}
    </SidePanelWrapper>
  );
}
