import { useState } from 'react';
import { ConnectionRequestStatuses } from '../../../shared/constants/ConnectionRequestStatuses';
import { IActionItem } from '../../../shared/model/IActionItem';
import { InvitationStatus } from '../../../shared/model/IInvitationDto';
import { getInfiniteQueryDataLength } from '../../../shared/utils/queryClientUtils';
import { useBoardInviteRequestsQuery } from '../../Request/queries/boardInviteQueries';
import { useConnectionRequestsQuery } from '../../Request/queries/connectionRequestQueries';
import { useInvitationsQuery } from '../../Request/queries/invitationQueries';
import { useNetworkInviteRequestsQuery } from '../../Request/queries/networkInviteQueries';
import { useOrganizationInvitationsQuery } from '../../Request/queries/organizationInvitationQueries';
import { useProjectInviteRequestsQuery } from '../../Request/queries/projectInviteQueries';
import { useNotificationsQuery } from '../notificationQueries';
import { INotificationDto } from '../../../shared/model/INotificationDto';

type TabIds =
  | 'unread'
  | 'all'
  | 'contacts'
  | 'networks'
  | 'requests'
  | 'board'
  | 'project'
  | 'company';
const unreadFilter = (n: INotificationDto) => !n.isRead;

export default function useNotificationActions() {
  const [activeTabId, setActiveTabId] = useState<TabIds>('unread');

  const allNotificationsQuery = useNotificationsQuery();
  const unreadNotificationsQuery = useNotificationsQuery({
    isUnread: true,
  });
  const contactNotificationsQuery = useNotificationsQuery({ notificationArea: 'Contact' });
  const networkNotificationsQuery = useNotificationsQuery({ notificationArea: 'Network' });
  const boardNotificationsQuery = useNotificationsQuery({ notificationArea: 'Board' });
  const projectNotificationsQuery = useNotificationsQuery({ notificationArea: 'Project' });
  const companyNotificationsQuery = useNotificationsQuery({ notificationArea: 'Organization' });

  const numberOfUnreadNotifications = getInfiniteQueryDataLength(
    unreadNotificationsQuery.data,
    unreadFilter,
  );
  const numberOfUnreadContactNotifications = getInfiniteQueryDataLength(
    contactNotificationsQuery.data,
    unreadFilter,
  );
  const numberOfUnreadNetworkNotifications = getInfiniteQueryDataLength(
    networkNotificationsQuery.data,
    unreadFilter,
  );
  const numberOfUnreadBoardsNotifications = getInfiniteQueryDataLength(
    boardNotificationsQuery.data,
    unreadFilter,
  );
  const numberOfUnreadProjectsNotifications = getInfiniteQueryDataLength(
    projectNotificationsQuery.data,
    unreadFilter,
  );

  const numberOfUnreadCompanyNotifications = getInfiniteQueryDataLength(
    companyNotificationsQuery.data,
    unreadFilter,
  );

  const invitationsQuery = useInvitationsQuery({
    status: InvitationStatus.Pending,
  });

  const connectionRequestsQuery = useConnectionRequestsQuery({
    status: ConnectionRequestStatuses.Pending,
    type: 'Sent',
  });

  const networkInviteRequestsQuery = useNetworkInviteRequestsQuery({
    status: ConnectionRequestStatuses.Pending,
    type: 'Sent',
  });

  const boardInviteRequestsQuery = useBoardInviteRequestsQuery({
    status: ConnectionRequestStatuses.Pending,
    type: 'Sent',
  });

  const organizationInvitationsQuery = useOrganizationInvitationsQuery({
    status: ['Pending'],
    type: 'Sent',
  });

  const projectInviteRequestsQuery = useProjectInviteRequestsQuery({
    status: ConnectionRequestStatuses.Pending,
    type: 'Sent',
  });

  const allInvitationsData = [
    ...(invitationsQuery.data || []),
    ...(connectionRequestsQuery.data || []),
    ...(networkInviteRequestsQuery.data || []),
    ...(boardInviteRequestsQuery.data || []),
    ...(organizationInvitationsQuery.data || []),
    ...(projectInviteRequestsQuery.data || []),
  ];

  const items: IActionItem[] = [
    {
      id: 'all',
      title: 'All',
      onClick: () => setActiveTabId('all'),
      badgeCount: numberOfUnreadNotifications,
      addDividerAfter: true,
    },
    {
      id: 'company',
      title: 'Companies',
      onClick: () => setActiveTabId('company'),
      badgeCount: numberOfUnreadCompanyNotifications,
    },
    {
      id: 'project',
      title: 'Projects',
      onClick: () => setActiveTabId('project'),
      badgeCount: numberOfUnreadProjectsNotifications,
    },
    {
      id: 'board',
      title: 'Boards',
      onClick: () => setActiveTabId('board'),
      badgeCount: numberOfUnreadBoardsNotifications,
      addDividerAfter: true,
    },

    {
      id: 'requests',
      title: 'Requests',
      onClick: () => setActiveTabId('requests'),
    },
  ];

  return {
    items,
    activeTabId,
    setActiveTabId,
    numberOfUnreadNotifications,
    numberOfUnreadBoardsNotifications,
    numberOfUnreadContactNotifications,
    numberOfUnreadNetworkNotifications,
    allNotificationsQuery,
    unreadNotificationsQuery,
    contactNotificationsQuery,
    networkNotificationsQuery,
    boardNotificationsQuery,
    projectNotificationsQuery,
    connectionRequestsQuery,
    networkInviteRequestsQuery,
    organizationInvitationsQuery,
    companyNotificationsQuery,
    projectInviteRequestsQuery,
    boardInviteRequestsQuery,
    invitationsQuery,
  };
}
