import { Row } from '@tanstack/react-table';
import { ListLayout } from '../../../layout/ListLayout';
import { useActivitiesPagesQuery } from '../../Activities/queries/activitiesQueries';
import { useContactsInfiniteQuery } from '../queries/contactQueries';
import { IContactDto } from '../models/IContactDto';
import { ResourceType } from '../../../shared/model/ResourceType';
import { useContactListColumns } from '../hooks/useContactListColumns';

interface IContactCompanyViewProps {
  tenantId: string;
}

// TODO: Add back and adjust when we implement expandable rows
function ContactSubComponent({ row }: { row: Row<IContactDto> }) {
  const activitiesQuery = useActivitiesPagesQuery(row.original.id, ResourceType.Contact);

  const activities = activitiesQuery.data?.pages.flat() || [];

  if (activitiesQuery.isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="ml-10">
      {activities.map((activity) => (
        <div key={activity.id}>{activity.title}</div>
      ))}
    </div>
  );
}

export default function ContactCompanyView({ tenantId }: IContactCompanyViewProps) {
  const query = useContactsInfiniteQuery({
    tenantId,
  });

  const columns = useContactListColumns();

  if (!query.isSuccess) return <></>;

  return (
    <ListLayout
      title="Contacts"
      columns={columns}
      query={query}
      // TODO: Add back when we implement expandable rows
      // renderSubComponent={(row) => <ContactSubComponent {...row} />}
    />
  );
}
