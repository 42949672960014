import { SubmitHandler, useForm, UseFormReturn } from 'react-hook-form';
import React, { useState } from 'react';
import Button from '../../shared/components/buttons/Button';
import ButtonGroup from '../../shared/components/buttons/ButtonGroup';
import SubmitButton from '../../shared/components/buttons/SubmitButton';
import TextAreaInput from '../../shared/components/inputs/text/TextAreaInput';
import TextFieldInput from '../../shared/components/inputs/text/TextFieldInput';
import { ButtonColors } from '../../shared/constants/ButtonColors';
import {
  ICreateUpdateNetworkDto,
  INetworkDto,
  NetworkTypes,
  NetworkVisibilities,
} from '../../shared/model/INetwork';
import ProfileAvatarForm from '../../shared/components/avatar/ProfileAvatarForm';
import { useNetworkPhotoMutation } from './queries/networkPhotoQueries';
import useEntityManifest from '../../shared/hooks/useEntityManifest';
import { ResourceType } from '../../shared/model/ResourceType';
import FlexContainer from '../../shared/components/structure/FlexContainer';
import SelectResourceTenant from '../Selectors/SelectResourceTenant';
import { useTenantsQuery } from '../Companies/queries/tenantQueries';
import { ITenantDto } from '../Companies/models/ITenantDto';

const defaultValues: ICreateUpdateNetworkDto = {
  name: '',
  description: '',
  tenantId: '',
  type: NetworkTypes.Private,
  visibility: NetworkVisibilities.Hidden,
};

type UseNetworkCreateEditFormProps = {
  values?: INetworkDto;
};

export function useNetworkCreateEditForm({ values }: UseNetworkCreateEditFormProps = {}) {
  const actualValues: ICreateUpdateNetworkDto = !values
    ? defaultValues
    : {
        name: values.name,
        tenantId: values.tenantId,
        description: values.description,
        type: values.type,
        visibility: values.visibility,
      };
  return useForm<ICreateUpdateNetworkDto>({
    mode: 'onChange',
    defaultValues: actualValues,
    values: actualValues,
  });
}

type Props = {
  form: UseFormReturn<ICreateUpdateNetworkDto>;
  submitButtonText?: string;
  cancelButtonText?: string;
  network?: INetworkDto;
  isDisabled?: boolean;
  defaultTenantId: string;
  onSubmit: SubmitHandler<ICreateUpdateNetworkDto>;
  onCancel: (event: React.BaseSyntheticEvent) => void;
};

export function NetworkCreateEditForm({
  form,
  submitButtonText,
  cancelButtonText = 'Cancel',
  network,
  isDisabled = false,
  defaultTenantId,
  onSubmit,
  onCancel,
}: Props) {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid, isDirty },
  } = form;

  const isUpdate = !!network;
  const _submitButtonText = submitButtonText || (isUpdate ? 'Save' : 'Create');

  const tenants = useTenantsQuery().data?.pages.flat();
  const [selectedTenant, setSelectedTenant] = useState<ITenantDto>(
    tenants?.find((t) => t.id === defaultTenantId) || ({} as ITenantDto),
  );

  const _onSubmit = (_network: ICreateUpdateNetworkDto) => {
    if (!isValid || !isDirty) return;

    const updatedNetwork = { ..._network, tenantId: selectedTenant.id || defaultTenantId };

    onSubmit(updatedNetwork);
    reset();
  };
  const manifest = useEntityManifest(ResourceType.Network);
  const photoMutations = useNetworkPhotoMutation();

  return (
    <form onSubmit={handleSubmit(_onSubmit)} className="text-left">
      <FlexContainer direction="column">
        <div>
          {isUpdate && (
            <ProfileAvatarForm
              url={network.photoUrl || manifest.icon}
              altText={network.name}
              onUpload={(file) => photoMutations.mutate({ id: network.id, file })}
              onRemove={() => photoMutations.mutate({ id: network.id })}
              isPending={photoMutations.isPending}
            />
          )}
        </div>

        <SelectResourceTenant
          selectedTenant={selectedTenant}
          tenants={tenants}
          entityName={ResourceType.Network}
          onSelectedTenantChange={setSelectedTenant}
          isDisabled={isUpdate}
        />

        <TextFieldInput
          labelText="Name"
          name="name"
          type="text"
          register={register}
          errors={errors}
          validationRules={{ required: 'Must enter a name' }}
        />

        <TextAreaInput
          labelText="Description"
          name="description"
          rows={3}
          register={register}
          errors={errors}
          errorMessage="Must enter a description"
          validationRules={{ required: true }}
        />

        <ButtonGroup>
          <Button
            color={ButtonColors.White}
            text={cancelButtonText}
            disabled={isDisabled}
            onClick={onCancel}
          />
          <SubmitButton text={_submitButtonText} isDisabled={!isValid || !isDirty || isDisabled} />
        </ButtonGroup>
      </FlexContainer>
    </form>
  );
}
