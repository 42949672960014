import { RefreshCcw, RefreshCwOff, UserPlusIcon } from 'lucide-react';
import { useMyUserId } from '../../../shared/auth/accountHooks';
import useModals from '../../../shared/components/modal/hooks/useModal';
import { IActionItem } from '../../../shared/model/IActionItem';
import { ResourceType } from '../../../shared/model/ResourceType';
import useUserMenuActions from '../../Contacts/hooks/useUserMenuActions';
import { IContactDetailDto, IContactDto } from '../../Contacts/models/IContactDto';
import { useContactsQuery } from '../../Contacts/queries/contactQueries';
import { IPipelineItemDto } from '../models/IPipelineItemDto';
import { usePipelineItemQuery } from '../queries/itemQueries';
import { useShareResourceMutation } from '../queries/pipelineSharedResourcesQueries';
import ContactCreateEditForm, { useContactCreateForm } from '../../Contacts/ContactCreateEditForm';
import { ActivePipelineSettings } from '../context/pipeline-context';

function getEmailAddressIntersection(contacts: IContactDto[], emailAddresses: IContactDetailDto[]) {
  const foundEmailAddresses = contacts
    .map((contact) => contact.emailAddresses.map((email) => email.value))
    .flat();
  return emailAddresses
    .map((email) => email.value)
    .filter((email) => foundEmailAddresses.includes(email));
}

function useValidateIfCopyContactIsAllowed(entity?: IPipelineItemDto) {
  const contact = entity?.sharedResource?.payload as Partial<IContactDto>;

  const findContactsQuery = useContactsQuery({
    searchTerm: contact?.emailAddresses?.map((ea) => ea.value)?.join(' '),
    searchFields: ['EmailAddress'],
    missingSubscriptionOfType: 'InternalUser',
  });

  const isEntityValid =
    (entity?.sharedResource?.resourceType === ResourceType.ContactPassive ||
      entity?.sharedResource?.resourceType === ResourceType.Contact) &&
    !entity?.sharedResource?.payload?.userId;

  if (isEntityValid) {
    const duplicateEmailAddresses =
      (!!findContactsQuery.data &&
        getEmailAddressIntersection(findContactsQuery.data, contact.emailAddresses ?? [])) ||
      [];

    return duplicateEmailAddresses.length === 0;
  }

  return false;
}

interface IContactCreateFormWrapperProps {
  entity: IContactDto;
}

function ContactCreateFormWrapper({ entity }: IContactCreateFormWrapperProps) {
  const { closeModal } = useModals();
  const formObject = useContactCreateForm({ values: entity || {} });
  // TenantId is current user here
  const tenantId = useMyUserId();

  return (
    <ContactCreateEditForm
      defaultTenantId={tenantId ?? ''}
      onSuccess={closeModal}
      onCancel={closeModal}
      form={formObject}
    />
  );
}

export default function usePipelineAvatarActions(
  activePipelineSettings: ActivePipelineSettings,
): IActionItem[] {
  const userId = useMyUserId();
  const { openModal } = useModals();

  const sharingMutation = useShareResourceMutation();

  const pipelineItemQuery = usePipelineItemQuery({
    pipelineId: activePipelineSettings.pipelineId,
    stageId: activePipelineSettings.stageId,
    itemId: activePipelineSettings.item.id,
  });

  const pipelineItem = pipelineItemQuery.data;

  const socialContactActions = useUserMenuActions(pipelineItem?.sharedResource?.payload?.userId);
  const isCopyContactAllowed = useValidateIfCopyContactIsAllowed(pipelineItem);

  const hasSocialContactActions = socialContactActions.menuItems.length > 0;

  const addDividerAfter = isCopyContactAllowed || hasSocialContactActions;

  if (!pipelineItem) {
    return [];
  }

  const stopSharingAction: IActionItem = {
    id: 'stopSync',
    title: 'Stop sync',
    icon: RefreshCwOff,
    addDividerAfter,
    onClick: () => {
      if (!pipelineItem.sharedResource) {
        return;
      }

      sharingMutation.mutate({
        pipelineId: activePipelineSettings.pipelineId,
        request: {
          isLiveUpdated: false,
          resourceId: pipelineItem.sharedResource.resourceId,
          type: pipelineItem.sharedResource.resourceType,
        },
      });
    },
  };

  const startSharingAction: IActionItem = {
    id: 'startSync',
    title: 'Start sync',
    icon: RefreshCcw,
    addDividerAfter,
    onClick: () => {
      if (!pipelineItem.sharedResource) {
        return;
      }
      sharingMutation.mutate({
        pipelineId: activePipelineSettings.pipelineId,
        request: {
          isLiveUpdated: true,
          resourceId: pipelineItem.sharedResource.resourceId,
          type: pipelineItem.sharedResource.resourceType,
        },
      });
    },
  };

  const avatarActions: IActionItem[] = [];

  if (
    pipelineItem.sharedResource &&
    pipelineItem.sharedResource.isLiveUpdated &&
    pipelineItem.sharedResource.sharedBy === userId
  ) {
    avatarActions.push(stopSharingAction);
  }

  if (
    pipelineItem.sharedResource &&
    !pipelineItem.sharedResource.isLiveUpdated &&
    pipelineItem.sharedResource.sharedBy === userId
  ) {
    avatarActions.push(startSharingAction);
  }

  const copyContactActions: IActionItem = {
    id: 'copy',
    title: 'Add to my contacts',
    icon: UserPlusIcon,
    onClick: () =>
      openModal({
        title: '',
        content: <ContactCreateFormWrapper entity={pipelineItem.sharedResource?.payload} />,
      }),
  };

  if (isCopyContactAllowed) {
    avatarActions.push(copyContactActions);
  }

  if (hasSocialContactActions) {
    avatarActions.push(...socialContactActions.menuItems);
  }

  return avatarActions;
}
