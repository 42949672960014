import { useState } from 'react';
import { Bars3Icon, Squares2X2Icon } from '@heroicons/react/24/outline';
import { useNavigate, useParams } from 'react-router';
import { useProjectQuery } from './queries/projectQueries';
import BoardsListView from './BoardsListView';
import BoardsCardView from './BoardsCardView';

import { ResourceType } from '../../shared/model/ResourceType';
import useVisitTracking from '../Tracking/hooks/useVisitTracking';
import { useVisitMutation } from '../Tracking/queries/visitQueries';
import { BaseLayout } from '../../layout/pages/BaseLayout';
import ProjectSidePanel from './ProjectSidePanel';
import useCompanyBreadcrumbNavigation from '../Companies/hooks/useCompanyBreadcrumbNavigation';
import { ViewLayout } from '../../shared/store/slices/viewSlice';
import useProjectNavigation from './hooks/useProjectNavigation';
import WrapperDesktop from '../../shared/components/screen-discriminators/WrapperDesktop';
import { IActionItem } from '../../shared/model/IActionItem';
import AddIcon from '../../shared/components/icons/AddIcon';
import useModal from '../../shared/components/modal/hooks/useModal';
import { useProjectPrivilegesQuery } from './queries/projectPriviligesQueries';
import BoardCreateModal from './BoardCreateModal';
import BoardAddBoardButton from './BoardAddBoardButton';
import DropdownNavigator from '../../layout/navigation/DropdownNavigator';

export default function ProjectsSearchBoards() {
  const [activeView, setActiveView] = useState<ViewLayout>('card');
  const params = useParams();
  const projectQuery = useProjectQuery(params.projectId);

  const { breadcrumbNavigatorItems } = useCompanyBreadcrumbNavigation();

  const { items: projectNavItems } = useProjectNavigation();

  const { openModal, closeModal } = useModal();
  const navigate = useNavigate();

  const projectPriviligesQuery = useProjectPrivilegesQuery(params.projectId);

  const handleCreateBoardSuccess = (boardId: string) => {
    closeModal();
    navigate(`boards/${boardId}`);
  };

  const visitMutation = useVisitMutation();
  useVisitTracking({
    onVisit: () => {
      if (!params.projectId) {
        return;
      }
      visitMutation.mutate({ resourceType: ResourceType.Project, resourceId: [params.projectId] });
    },
  });

  const addAction: IActionItem[] =
    projectPriviligesQuery.data && projectPriviligesQuery.data.canCreateBoard && params.projectId
      ? [
          {
            id: 'board',
            title: 'Boards',
            type: 'header',
          },
          {
            id: 'add',
            title: 'Add board',
            icon: AddIcon,
            onClick: () =>
              openModal({
                content: (
                  <BoardCreateModal
                    projectId={params.projectId ?? ''}
                    onCancel={closeModal}
                    onSuccess={handleCreateBoardSuccess}
                  />
                ),
              }),
          },
        ]
      : [];

  const viewActions: IActionItem[] = [
    {
      id: 'view',
      title: 'Change view',
      type: 'header',
    },
    activeView === 'list'
      ? {
          id: 'cardView',
          title: 'Card view',
          onClick: () => {
            setActiveView('card');
            return Promise.resolve();
          },
          icon: Squares2X2Icon,
        }
      : {
          id: 'listView',
          title: 'List view',
          onClick: () => {
            setActiveView('list');
            return Promise.resolve();
          },
          icon: Bars3Icon,
        },
  ];

  const headerActions = [...addAction, ...viewActions];

  return (
    <>
      <BaseLayout
        title={projectQuery.data?.displayName || ''}
        headerActions={headerActions}
        breadcrumbNavigatorItems={breadcrumbNavigatorItems}
        contentAfterHeader={[
          { id: 'navigatorItems', content: <DropdownNavigator items={projectNavItems} /> },
          { id: 'addBoardButton', content: <BoardAddBoardButton /> },
        ]}
      >
        {projectQuery.data &&
          params.projectId &&
          (activeView === 'card' ? (
            <BoardsCardView projectId={params.projectId} />
          ) : (
            <BoardsListView projectId={params.projectId} />
          ))}
      </BaseLayout>
      <WrapperDesktop>
        <ProjectSidePanel />
      </WrapperDesktop>
    </>
  );
}
