import { InteractionRequiredAuthError, InteractionType } from '@azure/msal-browser';
import {
  MsalAuthenticationResult,
  MsalAuthenticationTemplate,
  useAccount,
  useMsal,
} from '@azure/msal-react';
import { useEffect, useMemo, useRef } from 'react';

import Layout from './Layout';
import { getLoginRequest, getSsoRequest } from '../../shared/auth/authConfig';
import { getEmail } from '../../shared/auth/authUtils';
import { AppProvider } from '../../shared/context/context';

function LoginOnError({ login, error }: MsalAuthenticationResult) {
  const loginStarted = useRef(false);
  const { instance } = useMsal();
  const activeAccount = useAccount();

  useEffect(() => {
    // Prevent calling loginRedirect multiple times
    if (loginStarted.current) return;

    loginStarted.current = true;
    if (error && error instanceof InteractionRequiredAuthError) {
      const authRequest = getLoginRequest(activeAccount?.tenantId);
      // Try to login without prompting the user.
      // This might work when silentSso fails due to third-party cookie policies.
      // If it still fails, the user will be redirected to the login page.
      login(InteractionType.Popup, { ...authRequest, prompt: 'none' })
        .then((result) => {
          if (result) {
            instance.setActiveAccount(result.account);
          }
        })
        .catch(() => {
          login(InteractionType.Redirect, authRequest);
        });
    }
  }, [activeAccount, error, login, instance]);

  return null;
}

export function AuthenticatedLayout() {
  const activeAccount = useAccount();
  const authRequest = useMemo(
    () => getSsoRequest(getEmail(activeAccount?.idTokenClaims), activeAccount?.tenantId),
    [activeAccount],
  );

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Silent}
      authenticationRequest={authRequest}
      errorComponent={LoginOnError}
    >
      <AppProvider>
        <Layout />
      </AppProvider>
    </MsalAuthenticationTemplate>
  );
}
