import { useEffect } from 'react';
import { useParams } from 'react-router';
import { ViewName } from '../../shared/store/slices/viewSlice';
import { useGlobalStore } from '../../shared/store/useGlobalStore';
import ProfileAvatar from '../../shared/components/avatar/ProfileAvatar';
import useEntityManifest from '../../shared/hooks/useEntityManifest';
import { ResourceType } from '../../shared/model/ResourceType';
import Project from './Project';
import SidePanel from '../../layout/building-blocks/SidePanel';
import { useProjectQuery } from './queries/projectQueries';
import SidePanelHeadline from '../../layout/building-blocks/SidePanelHeadline';

function ProjectClosedSidePanel({ projectId }: { projectId: string }) {
  const resourceManifest = useEntityManifest(ResourceType.Project);
  const { data: project } = useProjectQuery(projectId);
  return (
    <ProfileAvatar
      avatarProps={{
        src: project?.photoUrl || resourceManifest.icon,
        widthClass: 'w-14',
        heightClass: 'h-14',
      }}
    />
  );
}

export default function ProjectSidePanel() {
  const { getViewSettings, toggleSidePanel, setSidePanelOpen } = useGlobalStore();
  const currentView: ViewName = 'project';
  const viewSettings = getViewSettings('project');
  const resourceType = ResourceType.Project;
  const resourceManifest = useEntityManifest(resourceType);
  const params = useParams();
  const { projectId } = params;
  const projectQuery = useProjectQuery(projectId);
  const projectManifest = useEntityManifest(ResourceType.Project);

  useEffect(() => {
    setSidePanelOpen(currentView, true);
  }, [projectId]);

  if (!projectQuery.isSuccess) {
    return <></>;
  }

  const project = projectQuery.data;

  return (
    <>
      {projectId && (
        <SidePanel
          renderClosed={() => <ProjectClosedSidePanel projectId={projectId} />}
          colorSetting={resourceManifest.color}
          isOpen={viewSettings.sidepanel.isOpen}
          openSidePanel={() => setSidePanelOpen(currentView, true)}
          closeSidePanel={() => setSidePanelOpen(currentView, false)}
          onToggle={() => toggleSidePanel(currentView)}
        >
          <SidePanelHeadline
            headline={project.name}
            subHeadline={project.description || ''}
            colorSetting={projectManifest.color}
            imageSrc={project.photoUrl || projectManifest.icon}
          />
          <Project project={project} />
        </SidePanel>
      )}
    </>
  );
}
