import { Undo2 } from 'lucide-react';
import { Outlet, useNavigate, useParams } from 'react-router';
import { BaseLayout } from '../../layout/pages/BaseLayout';
import useContactsBreadcrumbNavigation from './hooks/useContactsBreadcrumbNavigation';
import { useResponsiveQueries } from '../../shared/hooks/useResponsiveQueries';
import useIsBaseRoute from '../../shared/hooks/useIsNavigationMode';
import { useContactQuery } from './queries/contactQueries';
import useTenant from '../../shared/hooks/useTenant';
import { createRoute } from '../../layout/navigation/helpers/routingHelpers';

export function ContactDetailLayout() {
  const { isSmallScreen } = useResponsiveQueries();
  const isBaseRoute = useIsBaseRoute();

  const { activeTenant } = useTenant();

  const navigate = useNavigate();
  const { breadcrumbNavigatorItems } = useContactsBreadcrumbNavigation();

  const { id } = useParams();
  const contact = useContactQuery(id);

  if (!contact.data) return null;

  return (
    <BaseLayout
      title={contact.data?.displayName}
      contentAfterHeader={[
        {
          id: 'back',
          content: (
            <Undo2
              className="cursor-pointer"
              onClick={() => navigate(createRoute('contacts/list', activeTenant.id))}
            />
          ),
        },
      ]}
      breadcrumbNavigatorItems={breadcrumbNavigatorItems}
    >
      {(!isSmallScreen || !isBaseRoute) && (
        <div className="flex-1 h-full overflow-hidden">
          <Outlet />
        </div>
      )}
    </BaseLayout>
  );
}
