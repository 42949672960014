import { useResponsiveQueries } from '../../hooks/useResponsiveQueries';

interface IProps {
  children: React.ReactNode;
}

export default function WrapperMobile({ children }: IProps) {
  const { isSmallScreen } = useResponsiveQueries();

  if (!isSmallScreen) return <></>;

  return <>{children}</>;
}
