import { IContactDto } from './models/IContactDto';
import { UserProfileDto } from '../Profile/models/UserProfile';
import { ProfilePropertyList } from '../Profile/ProfilePropertyList';
import { IContactProfileDto } from '../../shared/model/IProfile';

interface IProps {
  user: IContactDto | UserProfileDto | IContactProfileDto | undefined | null;
}

export default function ContactUserCard({ user }: IProps) {
  if (!user) return <div />;
  return <ProfilePropertyList profile={user} />;
}
