import { useParams } from 'react-router';
import { ChartColumnDecreasing, Clock } from 'lucide-react';
import { useBoardQuery } from './queries/boardQueries';
import useVisitTracking from '../Tracking/hooks/useVisitTracking';
import { ResourceType } from '../../shared/model/ResourceType';
import { useVisitMutation } from '../Tracking/queries/visitQueries';
import { BaseLayout } from '../../layout/pages/BaseLayout';
import useCompanyBreadcrumbNavigation from '../Companies/hooks/useCompanyBreadcrumbNavigation';
import PipelineSidePanel from '../Pipelines/PipelineSidePanel';
import {
  PipelineContextProvider,
  usePipelineStoreContext,
} from '../Pipelines/context/pipeline-context';
import Pipeline from '../Pipelines/Pipeline';
import useBoardNavigation from './hooks/useBoardsNavigation';
import WrapperDesktop from '../../shared/components/screen-discriminators/WrapperDesktop';
import { IActionItem } from '../../shared/model/IActionItem';
import DropdownNavigator from '../../layout/navigation/DropdownNavigator';

function useBoardActions() {
  const { isKPIsVisible, setIsKPIsVisible, recalculateValue, kpiPeriodKey } =
    usePipelineStoreContext();

  const menuItems: IActionItem[] = [];

  menuItems.push({
    id: 'ToggleKPIs',
    title: isKPIsVisible ? 'Hide KPIs' : 'Show KPIs',
    icon: ChartColumnDecreasing,
    onClick: () => setIsKPIsVisible((prev) => !prev),
  });

  menuItems.push({
    id: 'ToggleBudgetPeriod',
    title: kpiPeriodKey === 'week' ? 'Show Monthly Budget' : 'Show Weekly Budget',
    icon: Clock,
    onClick: () => {
      if (kpiPeriodKey === 'month') {
        recalculateValue('week');
      } else {
        recalculateValue('month');
      }
    },
  });

  return { items: menuItems };
}

function BoardWrapper() {
  const params = useParams();
  const { projectId, boardId } = params;
  const boardQuery = useBoardQuery(projectId, boardId);
  const { data } = boardQuery;

  const { items } = useBoardActions();

  const { breadcrumbNavigatorItems } = useCompanyBreadcrumbNavigation();
  const { items: boardNavItems } = useBoardNavigation();

  const visitMutation = useVisitMutation();
  useVisitTracking({
    onVisit: () => {
      if (!projectId || !boardId) {
        return;
      }
      visitMutation.mutate({ resourceType: ResourceType.Board, resourceId: [projectId, boardId] });
    },
  });

  if (!data) {
    return <></>;
  }

  if (!data.resourceId) {
    return (
      <p>
        The required resources for the board has not yet been created. Please refresh and try
        again...
      </p>
    );
  }

  return (
    <>
      <BaseLayout
        title={data.name}
        breadcrumbNavigatorItems={breadcrumbNavigatorItems}
        headerActions={items}
        contentAfterHeader={[
          { id: 'networkNavigator', content: <DropdownNavigator items={boardNavItems} /> },
        ]}
      >
        <Pipeline pipelineId={data.resourceId} />
      </BaseLayout>
      <WrapperDesktop>
        <PipelineSidePanel
          projectId={data.projectId}
          boardId={data.id}
          pipelineId={data.resourceId}
        />
      </WrapperDesktop>
    </>
  );
}

export default function Board() {
  return (
    <PipelineContextProvider>
      <BoardWrapper />
    </PipelineContextProvider>
  );
}
