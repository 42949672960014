import { Outlet } from 'react-router';
import { useContactsActions } from './hooks/useContactsActions';
import { BaseLayout } from '../../layout/pages/BaseLayout';
import useContactsBreadcrumbNavigation from './hooks/useContactsBreadcrumbNavigation';
import useIsAllRoute from '../../shared/hooks/useIsAllRoute';
import { SwitchTenantDropdown } from '../../shared/components/drop-down-menu/SwitchTenantDropdown';
import ContactAddContactButton from './ContactAddContactButton';
import useTenants from '../../shared/hooks/useTenant';

export function ContactListLayout() {
  const { breadcrumbNavigatorItems } = useContactsBreadcrumbNavigation();
  const actions = useContactsActions();
  const isAllRoute = useIsAllRoute();

  const { activeTenant } = useTenants();

  return (
    <BaseLayout
      title={isAllRoute ? 'All contacts' : activeTenant?.name}
      headerActions={actions}
      breadcrumbNavigatorItems={breadcrumbNavigatorItems}
      contentAfterHeader={[
        { id: 'switchTenant', content: <SwitchTenantDropdown /> },
        {
          id: 'addContactButton',
          content: <ContactAddContactButton />,
        },
      ]}
    >
      <Outlet />
    </BaseLayout>
  );
}
