import { ChangeEvent, useMemo, useState } from 'react';
import { IContactDto } from './models/IContactDto';
import useDebounce from '../../shared/hooks/useDebounce';
import {
  useListShareContactRequestsQuery,
  useShareContactMutation,
} from '../Request/queries/shareContactRequestQueries';
import SearchBar from '../../shared/components/search/SearchBar';
import { useContactsForSharedQuery } from './queries/contactQueries';
import { useToast } from '../../shared/components/toasts/use-toast';
import { transformContactToAvatarListItem } from './models/transformers/contactTransformers';
import { transform } from '../../lib/utils';
import { ContactAvatarList, IContactAvatarListItem } from './ContactAvatarList';
import FlexContainer from '../../shared/components/structure/FlexContainer';

interface IContactShareContactToListProps {
  contact: IContactDto;
}

export default function ContactsShareContactToThisList({
  contact,
}: IContactShareContactToListProps) {
  const [searchTerm, setSearchTerm] = useState('');

  const debouncedSearchTerm = useDebounce(searchTerm);
  const contactsQuery = useContactsForSharedQuery(debouncedSearchTerm, contact.id);
  const shareContactMutation = useShareContactMutation();
  const { toast } = useToast();

  const shareContactRequestsQuery = useListShareContactRequestsQuery({
    targetUserId: contact.userId,
    searchType: 'Sent',
    status: ['Accepted', 'Pending', 'Rejected'],
  });

  const unSentShareContacts = useMemo(() => {
    if (!contactsQuery.isSuccess || !shareContactRequestsQuery.isSuccess) {
      return [];
    }

    const contacts = contactsQuery.data;

    const sharedContacts = shareContactRequestsQuery.data;
    const sharedContactsIds = new Set(sharedContacts.map((sc) => sc.sharedContactId));

    return contacts.filter((c) => c.id && !sharedContactsIds.has(c.id));
  }, [
    contactsQuery.isSuccess,
    contactsQuery.data,
    shareContactRequestsQuery.isSuccess,
    shareContactRequestsQuery.data,
  ]);

  const handleOnSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.currentTarget.value);
  };

  const handleShareContact = (selectedContactToShare: IContactAvatarListItem) => {
    if (!contact.userId) return;

    shareContactMutation.mutate(
      {
        contactId: selectedContactToShare.id,
        targetUserIds: [contact.userId],
      },
      {
        onSuccess: () => {
          toast({
            title: 'Success',
            variant: 'default',
            description: `${selectedContactToShare.displayName} has been shared to  ${contact.displayName}`,
          });
        },
      },
    );
  };

  return (
    <FlexContainer direction="column">
      <SearchBar searchTerm={searchTerm} onSearchChange={handleOnSearchChange} />

      <ContactAvatarList
        items={unSentShareContacts.map((c) => transform(c, transformContactToAvatarListItem))}
        onAction={handleShareContact}
        actionLabel="Share"
      />
    </FlexContainer>
  );
}
