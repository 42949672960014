import { recursiveFlatMap } from '../../../../shared/utils/arrayUtils';
import { abbreviateString } from '../../../../shared/utils/stringUtils';

export interface IBreadcrumbNavigatorItem {
  id: string;
  name: string;
  abbreviation?: string;
  route: string;
  isDisabled?: boolean;
  isActive?: boolean;
  siblings?: IBreadcrumbNavigatorItem[];
  children?: IBreadcrumbNavigatorItem[];
}

/**
 * Processes a breadcrumb item recursively, checking if it or its children are active.
 *
 * @param item - The breadcrumb item to process.
 * @param ids - An array of active item IDs.
 * @returns The processed breadcrumb item if active or has active children, otherwise null.
 */
function processBreadcrumbItem(
  item: IBreadcrumbNavigatorItem,
  ids: string[],
): IBreadcrumbNavigatorItem | null {
  const isActive = ids.includes(item.id);
  const children = item.children || [];

  const newChildren = children
    .map((child) => processBreadcrumbItem(child, ids))
    .filter((child): child is IBreadcrumbNavigatorItem => child !== null);

  if (isActive || newChildren.length > 0) {
    return {
      ...item,
      isActive,
      children: newChildren.length > 0 ? newChildren : undefined,
    };
  }

  return null;
}

/**
 * Returns the active breadcrumb items by processing a list of items.
 *
 * @param items - The list of breadcrumb items to process.
 * @param ids - The IDs of active items.
 * @returns An array of active breadcrumb items.
 */
function getActiveBreadcrumbItems(
  items: IBreadcrumbNavigatorItem[],
  ...ids: string[]
): IBreadcrumbNavigatorItem[] {
  return items
    .map((item) => processBreadcrumbItem(item, ids))
    .filter((item): item is IBreadcrumbNavigatorItem => item !== null);
}

/**
 * Gets active breadcrumb navigator items and flattens them into a single-level array.
 * This gives us the ability to render the active items in the breadcrumb navigator.
 * The last item is excluded from the result since that item is shown as the page headline.
 *
 * @param breadcrumbNavigatorItems - The list of breadcrumb navigator items to process.
 * @param ids - The IDs of active items.
 * @returns A flattened array of active breadcrumb navigator items, excluding the last item.
 */
export default function getActiveBreadcrumbNavigatorItems(
  breadcrumbNavigatorItems: IBreadcrumbNavigatorItem[],
  ...ids: string[]
) {
  const activeBreadcrumbItems = getActiveBreadcrumbItems(breadcrumbNavigatorItems, ...ids);
  const activeBreadcrumbItemsFlat = recursiveFlatMap(activeBreadcrumbItems, (node) => node);
  activeBreadcrumbItemsFlat.pop();

  return activeBreadcrumbItemsFlat;
}

/**
 * Converts a list of items into breadcrumb navigator items.
 *
 * @param items - The list of items to convert.
 * @param route - The route associated with the breadcrumb items.
 * @returns An array of breadcrumb navigator items.
 */
export function convertToBreadcrumbNavigatorItems<T extends { id: string; name: string }>(
  items: T[],
  route: string,
): IBreadcrumbNavigatorItem[] {
  return items.map((item) => ({
    id: item.id,
    name: item.name,
    abbreviation: abbreviateString(item.name),
    siblings: items
      .filter((i) => i.id !== item.id)
      .map((sibling) => ({
        id: sibling.id,
        name: sibling.name,
        abbreviation: abbreviateString(sibling.name),
        route: `${route}${sibling.id}`,
      })),
    route: item.id ? `${route}${item.id}` : route,
  }));
}
