import HeaderAddButton from '../../shared/components/buttons/HeaderAddButton';

import useModal from '../../shared/components/modal/hooks/useModal';
import ProjectCreateEditModal from './ProjectCreateEditModal';

export default function ProjectAddProjectButton() {
  const { openModal } = useModal();

  return (
    <HeaderAddButton
      onClick={() => {
        openModal({
          title: 'Create new project',
          content: <ProjectCreateEditModal />,
        });
      }}
      ariaLabel="Add project"
    />
  );
}
