import { cn } from '../../lib/utils';
import ProfileAvatar from '../../shared/components/avatar/ProfileAvatar';
import IconButton from '../../shared/components/buttons/IconButton';
import HeaderSubHeaders from '../../shared/components/headers/HeaderSubHeaders';
import AddIcon from '../../shared/components/icons/AddIcon';
import FlexContainer from '../../shared/components/structure/FlexContainer';
import { ButtonColors } from '../../shared/constants/ButtonColors';

export interface IContactAvatarListItem {
  id: string;
  userId?: string;
  photoUrl: string;
  displayName: string;
  emailAddresses: { value: string }[];
}

interface ListProps {
  items: IContactAvatarListItem[];
  actionLabel?: string;
  onAction?: (item: IContactAvatarListItem) => void;
}

function ContactAvatarListItem({ item }: { item: IContactAvatarListItem }): JSX.Element {
  return (
    <FlexContainer direction="row">
      <ProfileAvatar avatarProps={{ src: item.photoUrl, widthClass: 'w-8', heightClass: 'h-8' }} />
      <FlexContainer direction="column" gap="none">
        <HeaderSubHeaders
          size="small"
          text={item.displayName}
          subTexts={[item.emailAddresses[0].value]}
        />
      </FlexContainer>
    </FlexContainer>
  );
}

export function ContactAvatarList({
  items,
  actionLabel = 'Action',
  onAction,
}: ListProps): JSX.Element {
  return (
    <FlexContainer direction="column" gap="medium">
      {items.map((item) => (
        <FlexContainer
          key={item.id}
          gap="none"
          align="center"
          className={cn('relative group  ', onAction && 'hover:cursor-pointer')}
        >
          <ContactAvatarListItem item={item} />
          {onAction && (
            <span className="opacity-0 group-hover:opacity-100 transition-opacity absolute right-2">
              <IconButton
                icon={<AddIcon className="w-5 h-5" />}
                color={ButtonColors.Gray}
                onClick={() => onAction(item)}
                label={actionLabel}
                orientation="horizontal"
              />
            </span>
          )}
        </FlexContainer>
      ))}
    </FlexContainer>
  );
}
