import { InteractionStatus } from '@azure/msal-browser';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { useLocation, useNavigate, useParams } from 'react-router';
import { useEffect, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import { signUpRequest } from '../../shared/auth/authConfig';
import Center from '../../layout/Center';
import { getEmail } from '../../shared/auth/authUtils';
import { INVITED_EMAIL_SEARCH_PARAM } from './Constants';
import ButtonGroup from '../../shared/components/buttons/ButtonGroup';
import { ButtonColors } from '../../shared/constants/ButtonColors';
import Button from '../../shared/components/buttons/Button';
import { acceptInvitation } from '../../shared/services/invitationService';
import Text from '../../shared/components/text/Text';
import HorizontalSpacer from '../../shared/components/spacing/HorizontalSpacer';

export default function AcceptInvitation() {
  const { invitationId } = useParams();
  const [searchParams] = useSearchParams();
  const { instance: msalInstance, inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const navigate = useNavigate();
  const location = useLocation();
  const redirectStartedRef = useRef(false);

  const continueWithCurrentAccount = async () => {
    if (!invitationId) return;
    await acceptInvitation(invitationId);
    navigate('/contacts');
  };

  const logoutAndTryAgain = () => {
    const account = msalInstance.getActiveAccount();
    if (!account) return;
    msalInstance.logoutRedirect({
      account,
      postLogoutRedirectUri: `${location.pathname}${location.search}`,
    });
  };

  useEffect(() => {
    if (
      isAuthenticated ||
      inProgress === InteractionStatus.Startup ||
      inProgress === InteractionStatus.Logout ||
      redirectStartedRef.current
    ) {
      return;
    }

    redirectStartedRef.current = true;
    msalInstance.loginRedirect({
      ...signUpRequest,
      redirectStartPage: '/profile',
      loginHint: searchParams.get(INVITED_EMAIL_SEARCH_PARAM) as string,
      extraQueryParameters: {
        invitationId: invitationId || '',
        signUp: 'true',
      },
    });
  }, [isAuthenticated, inProgress]);

  if (isAuthenticated) {
    const activeAccount = msalInstance.getActiveAccount();
    return (
      <div className="flex h-screen w-screen">
        <Center>
          <div className="max-w-md bg-white rounded shadow p-4 text-center">
            <Text as="p">You are already logged in!</Text>
            <HorizontalSpacer distance="small" />
            <Text as="p" size="small">
              Do you want to continue with your current account (
              <b>{getEmail(activeAccount?.idTokenClaims)}</b>) or create a new?
            </Text>
            <HorizontalSpacer />
            <ButtonGroup>
              <Button color={ButtonColors.Blue} onClick={logoutAndTryAgain} text="Create account" />
              <Button
                buttonStyle="filled"
                color={ButtonColors.Blue}
                onClick={continueWithCurrentAccount}
                text="Continue"
              />
            </ButtonGroup>
          </div>
        </Center>
      </div>
    );
  }

  return <div />;
}
