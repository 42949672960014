import { XIcon } from 'lucide-react';
import { ButtonColors } from '../../constants/ButtonColors';
import IconButton from './IconButton';
import { cn } from '../../../lib/utils';

interface IAddButtonProps {
  className?: string;
  onClick?: () => void;
}

export default function CloseButton({ className, onClick }: IAddButtonProps) {
  return (
    <IconButton
      onClick={onClick}
      color={ButtonColors.Gray}
      icon={<XIcon className={cn('h-6 w-6', className)} aria-hidden="true" />}
    />
  );
}
