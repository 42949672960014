import { Pen, X } from 'lucide-react';
import IconButton from '../../shared/components/buttons/IconButton';
import { ButtonColors } from '../../shared/constants/ButtonColors';
import { IContactDto } from './models/IContactDto';
import useModal from '../../shared/components/modal/hooks/useModal';
import useConfirm from '../../shared/components/alert-dialog/hooks/useConfirm';
import { useDeleteContactProfileMutation } from './queries/contactQueries';
import { resolveDeleteConfirm } from '../../shared/components/alert-dialog/resolvers/confirmResolvers';
import FlexContainer from '../../shared/components/structure/FlexContainer';
import { ContactFormWrapper } from './ContactFormWrapper';

interface IContactActionProps {
  contact: IContactDto;
  profileId: string;
}

export default function ContactActionButtons({ contact, profileId }: IContactActionProps) {
  const { openModal } = useModal();
  const { openConfirm, closeConfirm } = useConfirm();
  const deleteContactProfileMutation = useDeleteContactProfileMutation();

  const editContact = () => {
    openModal({
      title: 'Edit contact',
      content: <ContactFormWrapper contactId={contact.id} />,
    });
  };

  const deleteContactProfile = () => {
    openConfirm({
      ...resolveDeleteConfirm('contact'),
      onAction: () => {
        deleteContactProfileMutation.mutate({
          contactId: contact.id,
          profileId,
        });
        closeConfirm();
      },
    });
  };

  return (
    <FlexContainer direction="row" justify="end" className="mt-4">
      <IconButton
        icon={<Pen className="w-4 h-4 text-light" />}
        color={ButtonColors.Gray}
        onClick={editContact}
        label="Edit"
        orientation="horizontal"
      />

      <IconButton
        icon={<X className="w-4 h-4 text-red" />}
        color={ButtonColors.Gray}
        onClick={deleteContactProfile}
        label="Delete"
        orientation="horizontal"
      />
    </FlexContainer>
  );
}
