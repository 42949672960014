import { useMemo } from 'react';
import { ColumnDef } from '@tanstack/react-table';
import useEntityManifest from '../../../shared/hooks/useEntityManifest';
import { ResourceType } from '../../../shared/model/ResourceType';
import { createDataTableColumnHelper } from '../../../shared/components/table/DataTable';
import { IContactDto } from '../models/IContactDto';
import ExpandableRowCell from '../../../shared/components/table/cells/ExpandableRowCell';
import ProfileRowCell from '../../../shared/components/table/cells/ProfileRowCell';
import { createRoute } from '../../../layout/navigation/helpers/routingHelpers';

export function useContactListColumns() {
  const contactManifest = useEntityManifest(ResourceType.Contact);
  return useMemo(() => {
    const columnHelper = createDataTableColumnHelper<IContactDto>();

    return [
      // columnHelper.multiSelect(),
      columnHelper.accessor('displayName', {
        id: 'displayName',
        header: 'Name',
        cell: ({ row, getValue }) => (
          <div className="w-max">
            <ExpandableRowCell row={row}>
              <ProfileRowCell
                photoUrl={row.original.photoUrl || contactManifest.icon}
                url={createRoute('contacts/detail', row.original.id, 'info')}
                name={getValue()}
              />
            </ExpandableRowCell>
          </div>
        ),
      }),

      columnHelper.accessor('title', {
        id: 'title',
        header: 'Title',
      }),
      columnHelper.accessor('phoneNumbers', {
        id: 'phoneNumbers',
        header: 'Phone',
        cell: ({ getValue }) =>
          getValue()
            .map((i) => i.value)
            .join(','),
      }),
      columnHelper.accessor('emailAddresses', {
        id: 'emailAddresses',
        header: 'Email',
        cell: ({ getValue }) =>
          getValue()
            .map((i) => i.value)
            .join(','),
      }),
      columnHelper.accessor('additionalInformation', {
        id: 'additionalInformation',
        header: 'Additional Information',
      }),
      columnHelper.accessor('location', {
        id: 'location',
        header: 'Location',
        meta: {
          size: '140px',
        },
      }),
      columnHelper.accessor('company', {
        id: 'company',
        header: 'Company',
        meta: {
          size: '140px',
        },
      }),
    ] as ColumnDef<IContactDto>[];
  }, []);
}
