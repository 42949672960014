import Text from '../../text/Text';

interface IProps {
  text: string;
}

export default function StandardRowCell({ text }: IProps) {
  return (
    <div className="flex items-center max-w-96 md:max-w-fit truncate">
      {text && (
        <Text brightness="dark" truncate>
          {text}
        </Text>
      )}
    </div>
  );
}
