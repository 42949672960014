import { useMsal } from '@azure/msal-react';
import { Link } from 'react-router-dom';
import { SidebarMenu, SidebarMenuButton, SidebarMenuItem, useSidebar } from './Sidebar';
import ProfileAvatar, {
  IProfileAvatarImageProps,
} from '../../../shared/components/avatar/ProfileAvatar';
import { useMyUserProfileQuery } from '../../../features/Profile/queries/userProfileQueries';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '../../../shared/components/drop-down-menu/DropdownMenu';
import { useResponsiveQueries } from '../../../shared/hooks/useResponsiveQueries';

const defaultAvatarProps: IProfileAvatarImageProps = {
  src: '',
  alt: 'User',
  widthClass: 'w-8',
  heightClass: 'h-8',
};

function FallbackAvatar() {
  return <ProfileAvatar avatarProps={defaultAvatarProps} />;
}

export function NavUser() {
  const { setOpenMobile } = useSidebar();
  const { isSmallScreen, isMediumScreen } = useResponsiveQueries();

  const { instance } = useMsal();
  const myProfileQuery = useMyUserProfileQuery();

  if (!myProfileQuery.isSuccess) {
    return <FallbackAvatar />;
  }

  const avatarProps = {
    ...defaultAvatarProps,
    src: myProfileQuery.data.photoUrl,
    alt: myProfileQuery.data.firstName,
  };

  const dropdownContent = (
    <DropdownMenuContent
      className="w-[--radix-dropdown-menu-trigger-width] min-w-56 rounded-lg"
      side={isSmallScreen ? 'bottom' : 'right'}
      align="end"
      sideOffset={4}
    >
      <DropdownMenuItem asChild>
        <Link to="/profile" onClick={() => setOpenMobile(false)}>
          Your Profile
        </Link>
      </DropdownMenuItem>

      <DropdownMenuItem asChild>
        <a href="/user_agreements/Privacy_Notice_v1.0.pdf" target="_blank">
          Privacy Notice
        </a>
      </DropdownMenuItem>
      <DropdownMenuItem asChild>
        <a href="/user_agreements/Terms_of_use_v1.0.pdf" target="_blank">
          Terms of Use
        </a>
      </DropdownMenuItem>

      <DropdownMenuItem onClick={() => instance.logoutRedirect()}>Sign out</DropdownMenuItem>
    </DropdownMenuContent>
  );

  if (isMediumScreen) {
    return (
      <DropdownMenu>
        <DropdownMenuTrigger>
          <ProfileAvatar avatarProps={avatarProps} />
        </DropdownMenuTrigger>
        {dropdownContent}
      </DropdownMenu>
    );
  }

  return (
    <SidebarMenu>
      <SidebarMenuItem>
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <SidebarMenuButton
              size="lg"
              className="data-[state=open]:bg-sidebar-accent data-[state=open]:text-sidebar-accent-foreground"
            >
              <ProfileAvatar avatarProps={avatarProps} />
              <div className="grid flex-1 text-left text-sm leading-tight">
                <span className="truncate font-semibold">{myProfileQuery.data.displayName}</span>
                <span className="truncate text-xs text-light">
                  {myProfileQuery.data.emailAddresses[0].value}
                </span>
              </div>
            </SidebarMenuButton>
          </DropdownMenuTrigger>
          {dropdownContent}
        </DropdownMenu>
      </SidebarMenuItem>
    </SidebarMenu>
  );
}
