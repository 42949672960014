import { useState } from 'react';
import { Ellipsis } from 'lucide-react';
import { IActionItem } from '../../model/IActionItem';
import { DropdownMenu, DropdownMenuContent, DropdownMenuTrigger } from './DropdownMenu';
import { renderDropdownMenuItems } from './utils';
import Spinner from '../loading/Spinner';

export type MenuTriggerIconSize = 'small' | 'medium' | 'large' | 'xLarge';

export interface ActionsDropdownMenuProps {
  resolver: (isOpen: boolean) => { items: IActionItem[]; isPending: boolean };
  menuTriggerIconSize?: MenuTriggerIconSize;
}

export const menuTriggerIconSizeLookup: Record<MenuTriggerIconSize, string> = {
  small: 'w-6 h-6',
  medium: 'w-8 h-8',
  large: 'w-9 h-9',
  xLarge: 'w-10 h-10',
};

export default function ActionsDropdownMenu({
  resolver,
  menuTriggerIconSize = 'small',
}: ActionsDropdownMenuProps) {
  const [isOpen, setIsOpen] = useState(false);
  const { items, isPending } = resolver(isOpen);
  const menuTriggerIconSizeClassName = menuTriggerIconSizeLookup[menuTriggerIconSize];
  return (
    <DropdownMenu open={isOpen} onOpenChange={setIsOpen}>
      <DropdownMenuTrigger className="flex justify-center items-center ml-auto">
        <Ellipsis className={menuTriggerIconSizeClassName} />
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        {isPending ? (
          <div className="p-8 flex items-center justify-center">
            <Spinner size={10} />
          </div>
        ) : (
          renderDropdownMenuItems(items)
        )}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
