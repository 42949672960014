import HeaderAddButton from '../../shared/components/buttons/HeaderAddButton';
import useModal from '../../shared/components/modal/hooks/useModal';
import { ContactFormWrapper } from './ContactFormWrapper';

export default function ContactAddContactButton() {
  const { openModal } = useModal();

  return (
    <HeaderAddButton
      ariaLabel="Create contact"
      onClick={() =>
        openModal({
          title: 'Create contact',
          content: <ContactFormWrapper />,
        })
      }
    />
  );
}
