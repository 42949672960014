import useModal from '../../shared/components/modal/hooks/useModal';
import useTenant from '../../shared/hooks/useTenant';
import ContactCreateEditForm, { useContactCreateForm } from './ContactCreateEditForm';
import { useContactQuery } from './queries/contactQueries';

interface IContactFormWrapperProps {
  contactId?: string;
}

// Wrapping is needed in order for the form to work in modal
export function ContactFormWrapper({ contactId }: IContactFormWrapperProps) {
  const { closeModal } = useModal();
  const { activeTenant } = useTenant();

  const contact = useContactQuery(contactId);

  const formObject = useContactCreateForm({
    values: contact.data || {},
  });

  return (
    <ContactCreateEditForm
      defaultTenantId={activeTenant.id}
      form={formObject}
      onSuccess={closeModal}
      onCancel={closeModal}
      contactId={contactId}
    />
  );
}
