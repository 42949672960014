import ProfileAvatar from '../../shared/components/avatar/ProfileAvatar';
import { CardList } from '../../shared/components/cards/CardList';
import ListItemLink from '../../shared/components/lists/ListItemLink';
import Text from '../../shared/components/text/Text';
import { NavigationListLoader } from './NavigationListLoader';

interface IProps<T> {
  isPending?: boolean;
  headline?: string;
  data: T[];
  itemIdProperty: (item: T) => string;
  itemUrlProperty: (item: T) => string;
  itemUrlPropertyPrefix?: string;
  itemHeadlineProperty: (item: T) => string;
  itemInformationProperty: (item: T) => string;
  itemSecondaryInformationProperty?: (item: T) => string;
  itemImgSrcProperty: (item: T) => string;
  highlightInformationProperty?: (item: T) => boolean;
  showHighlightIndicator?: (item: T) => boolean;
}

export default function NavigationList<T extends object>({
  data,
  isPending = false,
  headline,
  itemIdProperty,
  itemUrlProperty,
  itemUrlPropertyPrefix = '',
  itemHeadlineProperty,
  itemInformationProperty,
  itemSecondaryInformationProperty = () => '',
  itemImgSrcProperty,
  highlightInformationProperty = () => false,
  showHighlightIndicator = () => false,
}: IProps<T>) {
  if (isPending) {
    return (
      <div className="flex flex-col h-full items-start justify-start">
        <NavigationListLoader />
      </div>
    );
  }

  if ((!data && !isPending) || (data?.length === 0 && !isPending)) return <></>;

  return (
    <>
      {headline && <h1 className="text-1xl ml-6 mt-4 mb-2 font-medium text">{headline}</h1>}

      <CardList>
        {data?.map((item) => (
          <ListItemLink
            key={itemIdProperty(item)}
            to={`${itemUrlPropertyPrefix}${itemUrlProperty(item)}`}
          >
            <div className="flex-shrink-0">
              <ProfileAvatar
                avatarProps={{
                  src: itemImgSrcProperty(item),
                  alt: itemHeadlineProperty(item),
                  widthClass: 'w-12',
                  heightClass: 'h-12',
                }}
              />
            </div>
            <div className="flex-1 min-w-0">
              <Text as="p" size="small" weight="bold">
                {itemHeadlineProperty(item)}
              </Text>
              <div className="flex flex-row gap-1 w-full">
                <Text
                  as="span"
                  size="xSmall"
                  brightness={highlightInformationProperty(item) ? 'normal' : 'light'}
                  weight={highlightInformationProperty(item) ? 'bold' : 'normal'}
                  truncate
                >
                  {itemInformationProperty(item)}
                </Text>
                {itemSecondaryInformationProperty(item) && (
                  <Text as="span" size="xSmall" brightness="light" truncate>
                    {`• ${itemSecondaryInformationProperty(item)}`}
                  </Text>
                )}

                {showHighlightIndicator(item) && (
                  <div className="absolute right-2 top-2 h-2 w-2 rounded-full bg-cyan-alternative" />
                )}
              </div>
            </div>
          </ListItemLink>
        ))}
      </CardList>
    </>
  );
}
