import { IContactDto } from '../models/IContactDto';
import { IMoveContactNoteDto } from '../models/IMoveContactNoteDto';
import { IContactNoteDto } from '../models/IContactNoteDto';
import { ICreateContactDto } from '../models/ICreateContactDto';
import { IShareContactRequestDto } from '../../../shared/model/IShareContactRequestDto';
import apiClient from '../../../shared/utils/apiClient';
import QueryParam from '../../../shared/utils/query-string-builder/QueryParam';
import queryStringBuilder from '../../../shared/utils/query-string-builder/queryStringBuilder';
import { IPagedResult } from '../../../shared/model/IPagedResult';
import { GetContactsParams } from '../models/GetContactsParams';
import { IUpdateContactDto } from '../models/IUpdateContactDto';
import { IContactProfileDto } from '../../../shared/model/IProfile';

export const getContacts = (params: GetContactsParams) =>
  apiClient
    .get('v1/contacts', { params })
    .then<Array<IContactDto>>((response) => response.data.data);

// TODO: Should probably replace the getContacts function in the long run
export const getContactsPaged = (params: GetContactsParams) =>
  apiClient
    .get<IPagedResult<IContactDto>>('v1/contacts', { params })
    .then<IPagedResult<IContactDto>>((response) => response.data);

export const getContact = (id: string) =>
  apiClient.get(`v1/contacts/${id}`).then<IContactDto>((response) => response.data);

export const createContact = (contact: ICreateContactDto) =>
  apiClient.post('v1/contacts', contact).then<IContactDto>((response) => response.data);

export const updateContact = (contact: IUpdateContactDto, id: string) =>
  apiClient.patch(`v1/contacts/${id}`, contact);

export const deleteContact = (id: string) => apiClient.delete(`v1/contacts/${id}`);

export const getContactProfiles = (id: string) =>
  apiClient
    .get(`v1/contacts/${id}/profiles`)
    .then<IContactProfileDto[]>((response) => response.data.data);

export const deleteContactProfile = (id: string, profileId: string) =>
  apiClient.delete(`v1/contacts/${id}/profiles/${profileId}`);

export const getContactNotes = (id: string, limit?: number) => {
  const queryString = limit
    ? queryStringBuilder().add(new QueryParam('limit', limit)).toQueryString()
    : '';
  return apiClient
    .get(`v1/contacts/${id}/notes${queryString}`)
    .then<IPagedResult<IContactNoteDto>>((response) => response.data);
};

export const createContactNote = (id: string, note: IContactNoteDto) =>
  apiClient.post(`v1/contacts/${id}/notes`, note);

export const updateContactNote = (id: string, noteId: string, note: IContactNoteDto) =>
  apiClient.patch(`v1/contacts/${id}/notes/${noteId}`, note);

export const deleteContactNote = (id: string, noteId: string) =>
  apiClient.delete(`v1/contacts/${id}/notes/${noteId}`);

export const shareContact = (id: string, targetUserIds: string[]) =>
  apiClient
    .post(`v1/contacts/${id}/share`, { targetUserIds })
    .then<IShareContactRequestDto>((response) => response.data);

export const moveContactNote = (id: string, noteId: string, moveNote: IMoveContactNoteDto) =>
  apiClient.put(`v1/contacts/${id}/notes/${noteId}/move`, moveNote);
