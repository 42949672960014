import { useFilters } from '../../../../shared/components/filters/hooks/useFilters';
import { networkFilterConfig } from '../../config/networkFilterConfig';
import { ListLayout } from '../../../../layout/ListLayout';
import { useNetworksInfiniteQuery } from '../../queries/networkQueries';
import useNetworkListColumns from '../../hooks/useNetworkListColumns';

interface IContactCompanyViewProps {
  tenantId: string;
}

export default function NetworkCompanyView({ tenantId }: IContactCompanyViewProps) {
  const filters = useFilters(networkFilterConfig);
  const networkQuery = useNetworksInfiniteQuery({ ...filters.filterState, tenantId });
  const columns = useNetworkListColumns();

  if (!networkQuery.isSuccess) return <></>;

  return (
    <ListLayout
      title="Networks"
      columns={columns}
      query={networkQuery}
      filters={filters}
      // TODO: Add back when we implement expandable rows
      // renderSubComponent={(row) => <ContactSubComponent {...row} />}
    />
  );
}
