import { ViewName } from '../../../../store/slices/viewSlice';
import { useGlobalStore } from '../../../../store/useGlobalStore';

export default function usePageTabs(viewName: ViewName) {
  const tabs = useGlobalStore((state) => state.getViewSettings(viewName).tabs);
  const removeTab = useGlobalStore((state) => state.removeTab);
  const addTab = useGlobalStore((state) => state.addTab);

  return { tabs, removeTab, addTab };
}
