import { CardList, ICardListProps } from '../../shared/components/cards/CardList';
import { ProfileCard } from '../../shared/components/cards/ProfileCard';
import { ButtonColors } from '../../shared/constants/ButtonColors';
import { MessageType } from '../../shared/constants/MessageTypes';
import { ISharedContactDto } from '../../shared/model/IMessageDto';
import { useHasConnectionQuery } from '../Contacts/queries/connectionQueries';
import { useIsFollowingQuery } from '../Contacts/queries/contactQueries';
import { useConnectWithUserMutation } from '../Request/queries/connectionRequestQueries';
import {
  useAcceptShareContactRequestMutation,
  useRejectShareContactRequestMutation,
  useListShareContactRequestsQuery,
} from '../Request/queries/shareContactRequestQueries';

interface ISharedContactCardProps {
  sharedContact: ISharedContactDto;
  messageType?: MessageType;
}

function SharedContactCard({ sharedContact, messageType }: ISharedContactCardProps) {
  const hasConnectionQuery = useHasConnectionQuery(sharedContact.sharedUserId);
  const isFollowingQuery = useIsFollowingQuery(sharedContact.sharedUserId);
  const shareContactRequestQuery = useListShareContactRequestsQuery({
    sharedContactId: sharedContact.sharedContactId,
    searchType: 'Received',
  });
  const connectWithProfileMutation = useConnectWithUserMutation();
  const acceptShareContactRequestMutation = useAcceptShareContactRequestMutation();
  const rejectShareContactRequestMutation = useRejectShareContactRequestMutation();

  const getActions = () => {
    if (
      !shareContactRequestQuery.isSuccess ||
      !shareContactRequestQuery.data[0] ||
      shareContactRequestQuery.data[0].status === 'Rejected' ||
      !(!sharedContact.sharedUserId || hasConnectionQuery.isSuccess) ||
      !(!sharedContact.sharedUserId || isFollowingQuery.isSuccess)
    ) {
      return undefined;
    }

    let actions;
    if (shareContactRequestQuery.data[0].sharedUserId) {
      const { sharedUserId: userId, displayName } = shareContactRequestQuery.data[0];
      actions = [
        {
          key: 'connect',
          label: 'Connect',
          color: ButtonColors.Blue,
          onClick: () => {
            acceptShareContactRequestMutation.mutate(shareContactRequestQuery.data[0]);
            connectWithProfileMutation.mutate({ userId, displayName });
          },
          isDisabled:
            hasConnectionQuery.data ||
            acceptShareContactRequestMutation.isPending ||
            connectWithProfileMutation.isPending ||
            (acceptShareContactRequestMutation.isSuccess && connectWithProfileMutation.isSuccess),
        },
        {
          key: 'follow',
          label: 'Add',
          color: ButtonColors.Blue,
          onClick: () => acceptShareContactRequestMutation.mutate(shareContactRequestQuery.data[0]),
          isDisabled:
            isFollowingQuery.data ||
            acceptShareContactRequestMutation.isPending ||
            acceptShareContactRequestMutation.isSuccess,
        },
      ];
    } else {
      actions = [
        {
          key: 'create',
          label: 'Create contact',
          color: ButtonColors.Blue,
          onClick: () => acceptShareContactRequestMutation.mutate(shareContactRequestQuery.data[0]),
          isDisabled:
            acceptShareContactRequestMutation.isPending ||
            acceptShareContactRequestMutation.isSuccess,
        },
      ];
    }

    actions.push({
      key: 'ignore',
      label: 'Ignore',
      color: ButtonColors.Gray,
      onClick: () => rejectShareContactRequestMutation.mutate(shareContactRequestQuery.data[0]),
      isDisabled:
        rejectShareContactRequestMutation.isPending || rejectShareContactRequestMutation.isSuccess,
    });

    return actions;
  };

  const profile = {
    emailAddresses: [{ value: sharedContact.emailAddress, label: '' }],
    ...sharedContact,
  };

  return (
    <ProfileCard
      profile={profile}
      actions={getActions()}
      linkToUserId={sharedContact.sharedUserId}
      linkToContactId={
        messageType === MessageType.Outgoing ? sharedContact.sharedContactId : undefined
      }
      actionsPosition="below"
      width="medium"
    />
  );
}

interface IProps extends Pick<ICardListProps, 'alignHorizontal'> {
  sharedContacts: ISharedContactDto[];
  messageType?: MessageType;
}

export function MessageSharedContacts({ sharedContacts, messageType, ...cardListProps }: IProps) {
  return (
    <CardList direction="row" {...cardListProps}>
      {sharedContacts.map((sharedContact) => (
        <SharedContactCard
          key={sharedContact.sharedContactId}
          sharedContact={sharedContact}
          messageType={messageType}
        />
      ))}
    </CardList>
  );
}
