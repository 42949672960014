import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import {
  CLEAR_EDITOR_COMMAND,
  COMMAND_PRIORITY_HIGH,
  COMMAND_PRIORITY_LOW,
  KEY_ESCAPE_COMMAND,
  createCommand,
} from 'lexical';
import { useEffect } from 'react';
import { mergeRegister } from '@lexical/utils';
import { useResponsiveQueries } from '../../../shared/hooks/useResponsiveQueries';

export const MESSAGE_CANCEL_COMMAND = createCommand<undefined>('MESSAGE_CANCEL_COMMAND');

export interface IMessageCancelCommandProps {
  onCancel: () => void;
}

export function useMessageCancelCommand({ onCancel }: IMessageCancelCommandProps) {
  const [editor] = useLexicalComposerContext();
  const { isSmallScreen } = useResponsiveQueries();

  useEffect(() => {
    const commands = [
      editor.registerCommand(
        MESSAGE_CANCEL_COMMAND,
        () => {
          editor.dispatchCommand(CLEAR_EDITOR_COMMAND, undefined);
          onCancel();
          return true;
        },
        COMMAND_PRIORITY_HIGH,
      ),
    ];
    if (!isSmallScreen) {
      commands.push(
        editor.registerCommand(
          KEY_ESCAPE_COMMAND,
          () => {
            editor.dispatchCommand(MESSAGE_CANCEL_COMMAND, undefined);
            return true;
          },
          COMMAND_PRIORITY_LOW,
        ),
      );
    }
    return mergeRegister(...commands);
  }, [editor, onCancel, isSmallScreen]);
}
