import {
  DescriptionList,
  IDescriptionListItem,
} from '../../shared/components/lists/DescriptionList';
import { IProfile } from '../../shared/model/IProfile';
import { useUserProfileQuery } from './queries/userProfileQueries';
import { ProfileIdentifier } from './Types';

function toItems(profile: Partial<IProfile>, hideNameAttributes = true): IDescriptionListItem[] {
  const EMPTY_VALUE = '-';

  /**
   * Returns the provided value if it is truthy and not just whitespace.
   * Otherwise returns EMPTY_VALUE.
   */
  const getValue = (value?: string): string => {
    if (!value?.trim()) {
      return EMPTY_VALUE;
    }
    return value;
  };

  const items: IDescriptionListItem[] = [];

  // Only add name attributes if they're not hidden
  if (!hideNameAttributes) {
    items.push({
      name: 'First name',
      value: getValue(profile.firstName),
    });
    items.push({
      name: 'Last name',
      value: getValue(profile.lastName),
    });
  }

  // Always add the rest, using the helper to ensure non-empty values
  items.push({
    name: 'Email',
    value: getValue(profile.emailAddresses?.map((d) => d.value).join(', ')),
    type: 'email',
  });
  items.push({
    name: 'Phone',
    value: getValue(profile.phoneNumbers?.map((p) => p.value).join(', ')),
    type: 'phone',
  });
  items.push({
    name: 'Title',
    value: getValue(profile.title),
  });
  items.push({
    name: 'Company',
    value: getValue(profile.company),
  });
  items.push({
    name: 'Location',
    value: getValue(profile.location),
  });
  items.push({
    name: 'Additional information',
    value: getValue(profile.additionalInformation),
    columnSpan: 2,
  });

  return items;
}

interface IProfilePropertyListProps {
  profile: Partial<IProfile>;
  hideNameAttributes?: boolean;
  columns?: 1 | 2 | 3;
}

export function ProfilePropertyList({
  profile,
  hideNameAttributes,
  columns = 2,
}: IProfilePropertyListProps) {
  return <DescriptionList items={toItems(profile, hideNameAttributes)} columns={columns} />;
}

export function LazyProfilePropertyList({ id }: ProfileIdentifier) {
  const profileQuery = useUserProfileQuery(id);
  if (profileQuery.isSuccess) return <DescriptionList items={toItems(profileQuery.data)} />;
  return <div />;
}
