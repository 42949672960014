import { addYears, endOfYear, format, startOfYear, isValid as isValidDate } from 'date-fns';
import { Controller, useForm } from 'react-hook-form';
import { useEffect } from 'react';
import { IPipelineBudgetDto, IPipelineCreateBudgetDto } from './models/IPipelineDto';
import FieldWrapper from '../../shared/components/form/FieldWrapper';
import TextInput from '../../shared/components/form/TextInput';
import { validateIfBudgetIsWithinAcceptedRange } from './validation/budgetValidation';
import CurrencyInput from '../../shared/components/inputs/number/CurrencyInput';
import convertFormattedCurrencyToNumber from '../../shared/utils/numberUtils';
import {
  useCreatePipelineBudgetMutation,
  usePipelineQuery,
  useUpdatePipelineBudgetMutation,
} from './queries/pipelineQueries';
import ButtonGroup from '../../shared/components/buttons/ButtonGroup';
import Button from '../../shared/components/buttons/Button';
import { ButtonColors } from '../../shared/constants/ButtonColors';
import { Separator } from '../../layout/separator/Separator';

type BudgetFormProps = {
  pipelineId: string;
  budget?: IPipelineBudgetDto;
  onClose: () => void;
};

export function PipelineBudgetForm({ pipelineId, budget, onClose }: BudgetFormProps) {
  const pipeline = usePipelineQuery(pipelineId);

  const budgets = pipeline.data?.budgets;

  const budgetsSorted = budgets?.sort(
    (a, b) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime(),
  );

  const endOfDateRange = budgetsSorted?.at(-1)?.endDate;
  const nextYear = endOfDateRange ? addYears(new Date(endOfDateRange), 1) : new Date();

  const form = useForm<IPipelineCreateBudgetDto>({
    mode: 'onChange',
    defaultValues: {
      startDate: format(startOfYear(nextYear), 'yyyy-MM-dd'),
      endDate: format(endOfYear(nextYear), 'yyyy-MM-dd'),
      amount: 0,
    },
    values: budget,
  });

  const {
    control,
    getValues,
    watch,
    trigger,
    formState: { errors, isDirty, isValid },
  } = form;

  const createPipelineBudgetMutation = useCreatePipelineBudgetMutation();
  const updatePipelineBudgetMutation = useUpdatePipelineBudgetMutation();

  const watchVariables = watch(['startDate', 'endDate']);

  const save = () => {
    if (!isDirty || !isValid) {
      return;
    }

    const updatedForm = {
      ...getValues(),
      amount: convertFormattedCurrencyToNumber(getValues().amount),
    };

    if (budget) {
      updatePipelineBudgetMutation.mutate({
        pipelineId,
        budgetId: budget.id,
        pipelineBudget: updatedForm,
      });

      onClose();
      return;
    }

    createPipelineBudgetMutation.mutate({
      pipelineId,
      pipelineBudget: updatedForm,
    });

    onClose();
  };

  const validateDate = () => {
    const startDate = getValues('startDate');
    const endDate = getValues('endDate');

    if (!isValidDate(new Date(startDate)) || !isValidDate(new Date(endDate))) {
      return 'The date must be a valid date';
    }

    if (new Date(startDate).getTime() > new Date(endDate).getTime()) {
      return 'The end date must be after the start date';
    }

    return validateIfBudgetIsWithinAcceptedRange(startDate, endDate, budgetsSorted, budget);
  };

  useEffect(() => {
    const subscription = watch(() => {
      trigger('startDate');
      trigger('endDate');
    });
    return () => subscription.unsubscribe();
  }, [watchVariables]);

  return (
    <>
      <div className="grid grid-cols-3 gap-4">
        <div>
          <FieldWrapper
            label="Start Date"
            name="startDate"
            infoText={'Must be a valid date written like "1990-01-01"'}
            errors={errors}
          >
            <Controller
              name="startDate"
              control={control}
              rules={{ required: true, validate: validateDate }}
              render={({ field }) => <TextInput {...field} />}
            />
          </FieldWrapper>
        </div>
        <div>
          <FieldWrapper
            label="End Date"
            name="endDate"
            infoText={'Must be a valid date written like "1990-01-01"'}
          >
            <Controller
              name="endDate"
              control={control}
              rules={{ required: true, validate: validateDate }}
              render={({ field }) => <TextInput {...field} />}
            />
          </FieldWrapper>
        </div>
        <div>
          <FieldWrapper
            label="Amount"
            name="amount"
            infoText="Amount must be greater than 0"
            errors={errors}
          >
            <Controller
              name="amount"
              control={control}
              rules={{
                required: true,
                min: { value: 1, message: 'Amount must be greater than 0' },
              }}
              render={({ field }) => <CurrencyInput {...field} />}
            />
          </FieldWrapper>
        </div>
      </div>
      <Separator />
      <ButtonGroup>
        <Button color={ButtonColors.Blue} text="Save" disabled={!isValid} onClick={save} />
        <Button color={ButtonColors.White} text="Cancel" onClick={() => onClose()} />
      </ButtonGroup>
    </>
  );
}
