import { StateCreator } from 'zustand';
import { CombinedSlices } from '../useGlobalStore';
import { ITenantDto } from '../../../features/Companies/models/ITenantDto';

export interface TenantSlice {
  setActiveTenant: (activeTenant: ITenantDto) => void;
  activeTenant: ITenantDto;
}

export const createTenantSlice: StateCreator<
  CombinedSlices,
  [['zustand/persist', unknown], ['zustand/devtools', never]],
  [],
  TenantSlice
> = (set) => ({
  activeTenant: {} as ITenantDto,
  setActiveTenant: (activeTenant) => {
    set({ activeTenant });
  },
});
