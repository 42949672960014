import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useEffect } from 'react';
import { DragDropContext, Draggable, DropResult, Droppable } from 'react-beautiful-dnd';
import { XMarkIcon } from '@heroicons/react/24/outline';
import {
  useCreateStageMutation,
  useDeleteStageMutation,
  useMoveStageMutation,
  useStageQuery,
  useStagesQuery,
  useUpdateStageMutation,
} from './queries/stageQueries';
import { usePipelineStoreContext } from './context/pipeline-context';
import { ErrorComponent } from '../../shared/components/error-boundary/ErrorComponent';
import { IStageDto } from './models/IStageDto';
import { Card } from '../../shared/components/cards/Card';
import { CardList } from '../../shared/components/cards/CardList';
import ScrollBarWrapper from '../../shared/components/scrolling/ScrollBarWrapper';
import { CalculateDragDropPositions } from '../../shared/utils/dragDropUtils';
import { ButtonColors } from '../../shared/constants/ButtonColors';
import ButtonGroup from '../../shared/components/buttons/ButtonGroup';
import IconButton from '../../shared/components/buttons/IconButton';
import Button from '../../shared/components/buttons/Button';
import FieldWrapper from '../../shared/components/form/FieldWrapper';
import { Input } from '../../shared/components/form/Input';
import Howto from '../../shared/components/message-popups/HowTo';
import AddButton from '../../shared/components/buttons/AddButton';
import EmptyState from '../../shared/components/empty-state/EmptyState';
import { Separator } from '../../layout/separator/Separator';

export default function PipelineEditStages() {
  const { selectStage, activePipelineSettings } = usePipelineStoreContext();

  const createStageMutation = useCreateStageMutation();
  const updateStageMutation = useUpdateStageMutation();
  const deleteStageMutation = useDeleteStageMutation();

  const { setActiveSidePanelView } = usePipelineStoreContext();

  const stageQuery = useStageQuery(
    activePipelineSettings.pipelineId,
    activePipelineSettings.stageId,
  );

  const stagesQuery = useStagesQuery({ pipelineId: activePipelineSettings.pipelineId });

  const moveStageMutation = useMoveStageMutation();

  const {
    control,
    getValues,
    reset,
    formState: { isDirty },
  } = useForm({
    defaultValues: { stages: stagesQuery.data as IStageDto[] },
    values: { stages: stagesQuery.data as IStageDto[] },
  });

  const { fields, remove, move } = useFieldArray({
    control,
    name: 'stages',
  });

  useEffect(() => {
    reset({ stages: stagesQuery.data as IStageDto[] });
  }, [stagesQuery?.data?.length, reset]);

  const save = () => {
    const items = getValues().stages;

    // TODO: Convert to updateStagesMutation that takes an array of stages
    items.forEach((item) => {
      updateStageMutation.mutate({
        stageId: item.id,
        stage: {
          name: item.name,
          weight: item.weight,
        },
        pipelineId: item.pipelineId,
      });
    });
  };

  const deleteStage = (index: number) => {
    const items = getValues().stages;
    const item = items[index];

    deleteStageMutation.mutate({
      stageId: item.id,
      pipelineId: activePipelineSettings.pipelineId,
    });

    // TODO: Comes from useFieldArray. Should be better combined with the mutation
    remove(index);
    setActiveSidePanelView('EditPipeline');
  };

  const handleMove = (result: DropResult) => {
    const { source, destination, draggableId } = result;

    if (!destination) return;

    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }

    const stages = stagesQuery.data as IStageDto[];

    const { moveToPositionAfterItemId } = CalculateDragDropPositions<IStageDto>(
      stages,
      stages,
      draggableId,
      destination.index,
    );

    moveStageMutation.mutate({
      pipelineId: activePipelineSettings.pipelineId,
      stageId: draggableId,
      destinationIndex: destination.index,
      moveSettings: { moveToPositionAfterStageId: moveToPositionAfterItemId },
    });

    move(source.index, destination.index);
  };

  const handleCreateStage = () => {
    createStageMutation.mutate(
      {
        pipelineId: activePipelineSettings.pipelineId,
        stage: { name: 'New Stage', weight: 100 },
      },
      {
        onSuccess: (stage) => {
          selectStage(stage.id);
        },
      },
    );
  };

  if (stagesQuery.isSuccess) {
    return (
      <>
        {fields.length === 0 && (
          <EmptyState
            className="my-4"
            headline="Let's build up your pipeline"
            text="Start by adding stages"
          >
            <AddButton text="Add stage" onClick={handleCreateStage} />
          </EmptyState>
        )}

        <ScrollBarWrapper>
          <DragDropContext onDragEnd={handleMove}>
            <Droppable droppableId="stage" direction="vertical">
              {(_provided) => (
                <div ref={_provided.innerRef} {..._provided.droppableProps}>
                  <CardList>
                    {fields.map((item, index) => (
                      <Draggable
                        key={getValues().stages[index].id}
                        draggableId={getValues().stages[index].id}
                        index={index}
                      >
                        {(provided) => (
                          <Card
                            key={item.id}
                            isInteractive={false}
                            draggableProvided={provided}
                            className="group"
                          >
                            <div className="grid grid-cols-2 gap-2">
                              <div>
                                <FieldWrapper label="Name" name={`stages.${index}.name`}>
                                  <Controller
                                    name={`stages.${index}.name`}
                                    control={control}
                                    render={({ field }) => <Input type="text" {...field} />}
                                  />
                                </FieldWrapper>
                              </div>
                              <div>
                                <FieldWrapper label="Weight" name={`stages.${index}.weight`}>
                                  <Controller
                                    name={`stages.${index}.weight`}
                                    control={control}
                                    render={({ field }) => <Input type="number" {...field} />}
                                  />
                                </FieldWrapper>
                              </div>
                            </div>
                            <IconButton
                              icon={
                                <XMarkIcon className="w-4 h-4 absolute right-1.5 top-1.5 invisible group-hover:visible" />
                              }
                              color={ButtonColors.Red}
                              onClick={() => deleteStage(index)}
                            />
                          </Card>
                        )}
                      </Draggable>
                    ))}
                  </CardList>

                  {fields.length > 0 && (
                    <>
                      <Separator />
                      <ButtonGroup>
                        <AddButton text="Add stage" onClick={handleCreateStage} />
                        <Button
                          color={ButtonColors.Blue}
                          text="Save"
                          disabled={!isDirty}
                          onClick={() => save()}
                        />
                      </ButtonGroup>
                    </>
                  )}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </ScrollBarWrapper>
        <div className="flex pt-2">
          <Howto
            title="Stages"
            description="Build your business process in stages. For each stage add name and weight. The stage weight affect the Network or Contact value in that stage in the following way: amount * weight current Network/Contact value."
          />
        </div>
      </>
    );
  }

  return (
    <ErrorComponent
      queryResults={[stageQuery]}
      defaultErrorTexts={[{ code: 404, text: 'The stage could not be found.' }]}
    />
  );
}
