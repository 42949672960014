import React from 'react';
import AddIcon from '../icons/AddIcon';

interface IHeaderAddButtonProps {
  ariaLabel: string;
  onClick: () => void;
}

export default function HeaderAddButton({ ariaLabel, onClick }: IHeaderAddButtonProps) {
  return (
    <button type="button" aria-label={ariaLabel} onClick={onClick}>
      <AddIcon className="w-6 h-6 cursor-pointer" />
    </button>
  );
}
