import { Outlet } from 'react-router-dom';
import { SidebarProvider, SidebarTrigger } from './sidebar/Sidebar';
import { AppSidebar } from './sidebar/AppSidebar';
import { Toaster } from '../../shared/components/toasts/Toaster';
import Modal from '../../shared/components/modal/Modal';
import ConfirmDialog from '../../shared/components/alert-dialog/ConfirmDialog';
import FlexContainer from '../../shared/components/structure/FlexContainer';
import Notifications from '../../features/Notifications/Notifications';
import useIsBaseRoute from '../../shared/hooks/useIsNavigationMode';
import { useResponsiveQueries } from '../../shared/hooks/useResponsiveQueries';

export default function Layout() {
  const { isSmallScreen } = useResponsiveQueries();
  const isBaseRoute = useIsBaseRoute();

  return (
    <SidebarProvider>
      <FlexContainer flex={1} className="h-dscreen overflow-hidden" direction="column" gap="none">
        {isBaseRoute && (
          <div className="absolute right-2 top-2 z-50">
            {isSmallScreen && (
              <span className="relative top-[-2px]">
                <Notifications />
              </span>
            )}
            <SidebarTrigger />
          </div>
        )}

        <FlexContainer flex={1} className="overflow-hidden" gap="none">
          <AppSidebar />

          {/* Main area */}
          <FlexContainer
            flex={1}
            direction="column"
            className="md:flex-row overflow-hidden md:mt-4"
          >
            <Outlet />
          </FlexContainer>
        </FlexContainer>
      </FlexContainer>
      <Toaster />
      <Modal />
      <ConfirmDialog />
    </SidebarProvider>
  );
}
