import { useParams } from 'react-router';
import { useTenantsQuery } from '../queries/tenantQueries';
import { IDropdownNavigationItem } from '../../../layout/navigation/DropdownNavigator';
import { createRoute } from '../../../layout/navigation/helpers/routingHelpers';

export default function useCompanyNavigation() {
  const tenantsQuery = useTenantsQuery();
  const { tenantId } = useParams();

  if (!tenantsQuery.isSuccess) {
    return { items: [] };
  }

  const tenants = tenantsQuery.data?.pages?.flat().filter((tenant) => tenant.id !== tenantId);

  if (tenants.length === 0) {
    return { items: [] };
  }

  const items: IDropdownNavigationItem[] = tenants.map((company) => ({
    id: company.id,
    name: company.name,
    url: createRoute('companies', company.id),
  }));

  return { items };
}
