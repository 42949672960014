import { ElementType } from 'react';
import { TextColor, textColorMapping } from '../text/Text';
import { Trend } from './KpiIndicator';
import { TrendType } from '../../../features/Pipelines/models/IPipelineKpiDto';
import FlexContainer from '../structure/FlexContainer';

interface StatsCardProps {
  label: string;
  value: string;
  Icon?: ElementType;
  color?: TextColor;
  trend?: TrendType;
}

function StatsIndicator({ label, value, Icon, color = 'green', trend }: StatsCardProps) {
  const textColorClass = textColorMapping[color];

  return (
    <FlexContainer align="start" className="p-2 grow-0" gap="small">
      {Icon && <Icon className={`w-7 h-7 ${textColorClass} mr-3`} />}
      <div className="flex-grow">
        <div className="text text-sm font-medium ">{label}</div>
        <span className={`text-2xl font-bold ${textColorClass} text-green-dark`}>{value}</span>
      </div>
      {trend && <Trend trend={trend as TrendType} />}
    </FlexContainer>
  );
}

export default StatsIndicator;
