import { Checkbox } from '../../shared/components/checkbox/Checkbox';
import Header2 from '../../shared/components/headers/Header2';
import FlexContainer from '../../shared/components/structure/FlexContainer';
import usePageTabs from '../../shared/components/tabs/PageTabs/hooks/usePageTabs';
import { recommendedTab } from '../../shared/store/slices/viewSlice';

export default function NetworkTabSettings() {
  const { tabs, removeTab, addTab } = usePageTabs('network');

  return (
    <FlexContainer className="w-fit">
      <FlexContainer direction="column" className="p-4">
        <Header2>Standard</Header2>
        <ul>
          <li>
            <label htmlFor="recommended" className="flex flex-row items-center">
              <Checkbox
                value="recommended"
                checked={tabs?.open.map((i) => i.id).includes('recommended')}
                onCheckedChange={(_isChecked) => {
                  if (_isChecked) {
                    addTab('network', recommendedTab);
                  } else {
                    removeTab('network', 'recommended');
                  }
                }}
              />
              <span className="ml-2">Recommended</span>
            </label>
          </li>
        </ul>
      </FlexContainer>
    </FlexContainer>
  );
}
