import { forwardRef, ReactNode } from 'react';
import Header2 from '../headers/Header2';
import FlexContainer from '../structure/FlexContainer';
import Text from '../text/Text';
import { cn } from '../../../lib/utils';

export interface EmptyStateProps extends React.HTMLAttributes<HTMLDivElement> {
  headline?: string;
  text?: string;
  children?: ReactNode;
}

function EmptyStateInternal(
  { className, headline, text, children, ...props }: EmptyStateProps,
  ref: React.Ref<HTMLDivElement>,
) {
  return (
    <FlexContainer
      ref={ref}
      direction="column"
      align="center"
      justify="center"
      gap="small"
      className={cn('text-center', className)}
      {...props}
    >
      <Header2>{headline}</Header2>
      <Text size="small">{text}</Text>
      {children}
    </FlexContainer>
  );
}

const EmptyState = forwardRef<HTMLDivElement, EmptyStateProps>(EmptyStateInternal);
EmptyState.displayName = 'EmptyState';

export default EmptyState;
