import {
  CellContext,
  ColumnDef,
  ColumnFiltersState,
  ColumnHelper,
  createColumnHelper,
  DisplayColumnDef,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  Row,
  RowData,
  RowSelectionState,
  useReactTable,
} from '@tanstack/react-table';
import { Dispatch, Fragment, SetStateAction } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  TableRowHeader,
} from './Table';
import { Checkbox } from '../checkbox/Checkbox';
import StandardRowCell from './cells/StandardRowCell';

export type DataTableColumnHelper<TData extends RowData> = ColumnHelper<TData> & {
  multiSelect: () => DisplayColumnDef<TData>;
  actions: (
    cellCallback: (context: CellContext<TData, unknown>) => JSX.Element,
  ) => DisplayColumnDef<TData>;
};

export const createDataTableColumnHelper = <
  TData extends RowData,
>(): DataTableColumnHelper<TData> => {
  const columnHelper = createColumnHelper<TData>();
  return {
    display: columnHelper.display,
    accessor: columnHelper.accessor,
    group: columnHelper.group,
    multiSelect: () =>
      columnHelper.display({
        id: 'select',
        meta: {
          size: '50px',
        },
        minSize: 50,
        header: ({ table }) => (
          <Checkbox
            checked={
              table.getIsAllPageRowsSelected() ||
              (table.getIsSomePageRowsSelected() && 'indeterminate')
            }
            onCheckedChange={(value) => {
              table.toggleAllPageRowsSelected(!!value);
            }}
            aria-label="Select all"
          />
        ),
        cell: ({ row }) => (
          <Checkbox
            checked={row.getIsSelected()}
            onCheckedChange={(value) => {
              row.toggleSelected(!!value);
            }}
            aria-label="Select row"
          />
        ),
      }),
    actions: (cellCallback) =>
      columnHelper.display({
        id: 'action',
        meta: {
          size: '65px',
        },
        minSize: 65,
        cell: cellCallback,
      }),
  };
};

export interface DataTableProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[];
  data: TData[];
  renderSubComponent?: (props: { row: Row<TData> }) => React.ReactElement;
  getRowId?: (row: TData) => string;
  globalFilter?: string;
  columnFilters: ColumnFiltersState;
  onColumnFiltersChange: Dispatch<SetStateAction<ColumnFiltersState>>;
  rowSelection?: RowSelectionState;
  onRowSelectionChange?: Dispatch<SetStateAction<RowSelectionState>>;
  isLoading?: boolean;
}

export default function DataTable<TData, TValue>({
  columns,
  data,
  renderSubComponent,
  getRowId,
  globalFilter,
  columnFilters,
  rowSelection = {},
  onColumnFiltersChange,
  onRowSelectionChange,
  isLoading = false,
}: DataTableProps<TData, TValue>) {
  const table = useReactTable({
    defaultColumn: {
      cell: (info) => <StandardRowCell text={info.getValue() as string} />,
    },
    data,
    columns,
    getRowId,
    state: {
      columnFilters,
      globalFilter,
      rowSelection,
    },
    getCoreRowModel: getCoreRowModel(),
    getRowCanExpand: () => !!renderSubComponent,
    getFilteredRowModel: getFilteredRowModel(),
    onColumnFiltersChange,
    onRowSelectionChange,
  });
  return (
    <Table className="table-auto rounded-lg w-full">
      <TableHeader>
        {table.getHeaderGroups().map((headerGroup) => (
          <TableRowHeader key={headerGroup.id}>
            {headerGroup.headers.map((header) => {
              const startWidth =
                (header.column.columnDef.meta as { size?: string })?.size || 'auto';
              const minWidth = header.column.columnDef.minSize;
              const styles = {
                width: startWidth,
                minWidth,
              };
              return (
                <TableHead key={header.id} style={styles}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(header.column.columnDef.header, header.getContext())}
                </TableHead>
              );
            })}
          </TableRowHeader>
        ))}
      </TableHeader>
      <TableBody>
        {isLoading && (
          <TableRow>
            <TableCell colSpan={columns.length}>Loading...</TableCell>
          </TableRow>
        )}
        {!isLoading &&
          table.getRowModel().rows.length > 0 &&
          table.getRowModel().rows.map((row) => (
            <Fragment key={row.id}>
              <TableRow data-state={row.getIsSelected() && 'selected'}>
                {row.getVisibleCells().map((cell) => (
                  <TableCell key={cell.id} className="p-1">
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                ))}
              </TableRow>
              {row.getIsExpanded() && (
                <TableRow>
                  <TableCell colSpan={table.getAllColumns().length}>
                    {renderSubComponent && renderSubComponent({ row })}
                  </TableCell>
                </TableRow>
              )}
            </Fragment>
          ))}
        {!isLoading && table.getRowModel().rows.length === 0 && (
          <TableRow>
            <TableCell colSpan={columns.length} className="italic">
              No results.
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
}
