import { useLocation } from 'react-router';
import { useResponsiveQueries } from './useResponsiveQueries';

export default function useIsMobileEntityDetail() {
  const { isSmallScreen } = useResponsiveQueries();
  const { pathname } = useLocation();

  if (isSmallScreen && pathname.includes('/detail')) return true;

  return false;
}
