import ContentLoader, { IContentLoaderProps } from 'react-content-loader';
import HeaderSubHeaders from '../headers/HeaderSubHeaders';
import { cn } from '../../../lib/utils';

export interface INameValueItem {
  name: string;
  value: string;
}

export interface IDescriptionListItem extends INameValueItem {
  columnSpan?: 1 | 2;
  type?: 'general' | 'email' | 'phone';
}

interface IDescriptionListProps {
  columns?: 1 | 2 | 3;
  items: IDescriptionListItem[];
}

const columnClassMap = {
  1: '',
  2: 'md:grid-cols-2',
  3: 'md:grid-cols-3',
};

export function DescriptionList({ items, columns = 2 }: IDescriptionListProps) {
  return (
    <div className={cn('grid gap-y-3 grid-cols-2 gap-x-5', columnClassMap[columns])}>
      {items &&
        items.map((item) => {
          const colSpan = item.columnSpan || 1; // Default colSpan to 1 if not provided
          const colSpanClass = `col-span-${colSpan}`;

          return (
            <div key={item.name} className={colSpanClass}>
              <HeaderSubHeaders size="small" text={item.name} subTexts={[item.value]} />
            </div>
          );
        })}
    </div>
  );
}

export function DescriptionListLoader({ speed = 2 }: Pick<IContentLoaderProps, 'speed'>) {
  return (
    <ContentLoader
      speed={speed}
      backgroundColor="#eeeff1"
      foregroundColor="#e0e3e6"
      style={{ width: '100%', height: '100px' }}
    >
      <rect x="0" y="4" rx="4" ry="4" width="40%" height="16" />
      <rect x="0" y="28" rx="4" ry="4" width="100%" height="16" />
      <rect x="0" y="60" rx="4" ry="4" width="40%" height="16" />
      <rect x="0" y="84" rx="4" ry="4" width="100%" height="16" />
    </ContentLoader>
  );
}
