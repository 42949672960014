import AddIcon from '../../../shared/components/icons/AddIcon';
import useModal from '../../../shared/components/modal/hooks/useModal';
import { IActionItem } from '../../../shared/model/IActionItem';
import { ContactFormWrapper } from '../ContactFormWrapper';

export function useContactsActions(): IActionItem[] {
  const { openModal } = useModal();

  return [
    {
      id: 'resource',
      title: 'Contacts',
      type: 'header',
    },
    {
      id: 'create',
      title: 'Create contact',
      icon: AddIcon,
      onClick: () => {
        openModal({
          title: 'Create contact',
          content: <ContactFormWrapper />,
        });
      },
    },
  ];
}
