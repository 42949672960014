import { ToastProvider } from '@radix-ui/react-toast';
import { ThumbsUp, TriangleAlert } from 'lucide-react';
import { useToast } from './use-toast';
import { Toast, ToastClose, ToastDescription, ToastTitle, ToastViewport } from './Toast';

export function Toaster() {
  const { toasts } = useToast();

  return (
    <ToastProvider>
      {toasts.map(({ id, title, description, action, variant, ...props }) => (
        <Toast key={id} {...props}>
          <div className="grid gap-1">
            {title && (
              <ToastTitle className="flex flex-row gap-2 items-center">
                {variant === 'success' && <ThumbsUp className="w-4 h-4" />}
                {variant === 'destructive' && <TriangleAlert className="w-4 h-4 text-red-dark" />}
                {title}
              </ToastTitle>
            )}
            {description && <ToastDescription>{description}</ToastDescription>}
          </div>
          {action}
          <ToastClose />
        </Toast>
      ))}
      <ToastViewport />
    </ToastProvider>
  );
}
