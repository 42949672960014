// Can this be integrated with our entity manifest and our notifications?
// Connected to SidePanelLayout and our Routes, perhaps it can be
// even more centralized and connected to where we create our tabs
const tabsByResource = {
  'contacts/detail': ['info', 'notes', 'activities', 'attachments', 'share', 'chat'] as const,
  'contacts/list': [] as const,
  'contacts/list/all': [] as const,
  'networks/list': [] as const,
  'networks/list/all': [] as const,
  'networks/detail': ['info', 'members', 'activities', 'attachments', 'chat'] as const,
  companies: ['info'] as const,
};

// Extract the resource types
type Resource = keyof typeof tabsByResource;

// Create a type that maps each resource to its valid tabs
type TabsByResource = {
  [R in Resource]: (typeof tabsByResource)[R][number];
};

// Define a generic type that gets the valid tabs for a given resource
type TabForResource<R extends Resource> = TabsByResource[R];

export function createRoute<R extends Resource>(
  resource: R,
  resourceId?: string,
  tab?: TabForResource<R>,
): string {
  let path = '/';

  if (resource) {
    path += resource;
    if (resourceId) {
      // Add slash before resourceId only if resource is present
      path += `/${resourceId}`;
    }
  } else if (resourceId) {
    // If there's no resource, but there is a resourceId, just append it directly
    path += resourceId;
  }

  if (tab) {
    // Use ?tab= only if tab is truthy
    path += `?tab=${tab}`;
  }

  return path;
}

export function isCurrentRouteActive<R extends Resource>(
  resource: R,
  id: string,
  currentPath: string,
): boolean {
  const pattern = new RegExp(`^/${resource}/${id}(/|$)`, 'i');
  return pattern.test(currentPath);
}
