import { cn } from '../../../lib/utils';

interface IProps {
  text: string | React.ReactNode;
  type?: 'Information' | 'Warning' | 'Danger' | 'Success';
}

export default function Alert({ text, type = 'Information' }: IProps) {
  let classNames = 'text-blue bg-blue-light';
  if (type === 'Danger') {
    classNames = 'text-red bg-red-50';
  } else if (type === 'Success') {
    classNames = 'text-green bg-green-50';
  } else if (type === 'Warning') {
    classNames = ' text-yellow bg-yellow-50';
  }
  return (
    <div className={cn('p-2 text-sm rounded-lg', classNames)} role="alert">
      {text}
    </div>
  );
}
