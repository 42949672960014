import { IConnectionWithShareInfoDto } from '../../../../shared/model/IConnectionDto';
import { IContactAvatarListItem } from '../../ContactAvatarList';
import { IContactDto } from '../IContactDto';

export function transformContactToAvatarListItem(c: IContactDto): IContactAvatarListItem {
  return {
    id: c.id,
    userId: c.userId,
    photoUrl: c.photoUrl,
    displayName: c.displayName,
    emailAddresses: [{ value: c.emailAddresses[0].value }],
  };
}

export function transformConnectionWithShareInfoDtoToAvatarListItem(
  c: IConnectionWithShareInfoDto,
): IContactAvatarListItem {
  return {
    id: c.id,
    userId: c.userId,
    photoUrl: c.photoUrl,
    displayName: c.displayName,
    emailAddresses: [{ value: c.emailAddresses[0].value }],
  };
}
