import { StateCreator } from 'zustand';
import { CombinedSlices } from '../useGlobalStore';

export type ViewName =
  | 'contact'
  | 'network'
  | 'pipeline'
  | 'organization'
  | 'settings'
  | 'project'
  | 'board'
  | 'userTenant';

export type ViewLayout = 'card' | 'list';

export type TabType = 'standard' | 'primary';

export interface ITabSetting {
  name: string;
  id: string;
  type: TabType;
}

export interface IViewSettings {
  name: ViewName;
  activeTenantId?: string;
  tabs?: {
    open: ITabSetting[];
  };
  sidepanel: {
    isOpen: boolean;
  };
  activeViewLayout?: ViewLayout;
}

export const allContactsTab: ITabSetting = {
  name: 'Contacts',
  id: 'allContacts',
  type: 'primary',
};

export const recommendedTab: ITabSetting = {
  name: 'Recommended',
  id: 'recommended',
  type: 'standard',
};

const defaultOpenTabs: ITabSetting[] = [allContactsTab, recommendedTab];
const defaultActiveViewLayout = 'card';

const defaultViewSettings: IViewSettings = {
  name: 'contact',
  sidepanel: {
    isOpen: true,
  },
  activeViewLayout: defaultActiveViewLayout,
};

const initialViews: IViewSettings[] = [
  {
    ...defaultViewSettings,
    tabs: {
      open: [...defaultOpenTabs],
    },
  },
  {
    ...defaultViewSettings,
    name: 'network',
    tabs: {
      open: [...defaultOpenTabs],
    },
  },
  {
    ...defaultViewSettings,
    name: 'pipeline',
  },
  {
    ...defaultViewSettings,
    name: 'organization',
  },
  {
    ...defaultViewSettings,
    name: 'project',
  },
  {
    ...defaultViewSettings,
    name: 'board',
  },
  {
    ...defaultViewSettings,
    name: 'userTenant',
  },
];

export interface ViewsSlice {
  views: IViewSettings[];
  setSidePanelOpen: (name: ViewName, isOpen: boolean) => void;
  setActiveViewLayout: (name: ViewName, setting: ViewLayout) => void;
  toggleSidePanel: (name: ViewName) => void;
  getViewSettings: (name: ViewName) => IViewSettings;
  addTab: (name: ViewName, tab: ITabSetting) => void;
  removeTab: (name: ViewName, tabName: string) => void;
}

export const createViewSlice: StateCreator<
  CombinedSlices,
  [['zustand/persist', unknown], ['zustand/devtools', never]],
  [],
  ViewsSlice
> = (set, get) => ({
  views: initialViews,
  setSidePanelOpen: (name, isOpen) =>
    set((state) => ({
      views: state.views.map((view) => {
        if (view.name === name) {
          return {
            ...view,
            sidepanel: {
              isOpen,
            },
          };
        }
        return view;
      }),
    })),
  toggleSidePanel: (name) =>
    set((state) => ({
      views: state.views.map((view) => {
        if (view.name === name) {
          return {
            ...view,
            sidepanel: {
              isOpen: !view.sidepanel.isOpen,
            },
          };
        }
        return view;
      }),
    })),
  getViewSettings: (name) => get().views.find((view) => view.name === name) as IViewSettings,
  setActiveViewLayout: (name, activeViewLayout) =>
    set((state) => ({
      views: state.views.map((view) => {
        if (view.name === name) {
          return {
            ...view,
            activeViewLayout,
          };
        }
        return view;
      }),
    })),

  addTab: (viewName: ViewName, tab: ITabSetting) => {
    set((state) => {
      const views = state.views
        .map((viewSetting) => {
          if (viewSetting.name !== viewName) return viewSetting;

          if (viewSetting.tabs) {
            if (tab.type === 'primary') {
              const updatedTabs = viewSetting.tabs.open.filter((t) => t.type !== 'primary');
              updatedTabs.unshift(tab);
              return {
                ...viewSetting,
                tabs: {
                  ...viewSetting.tabs,
                  open: updatedTabs,
                },
              };
            }

            return {
              ...viewSetting,
              tabs: {
                ...viewSetting.tabs,
                open: [...viewSetting.tabs.open, tab],
              },
            };
          }
          return viewSetting;
        })
        .filter((view): view is IViewSettings => view !== undefined);

      return { views };
    });
  },
  removeTab: (viewName: ViewName, tabId: string) => {
    set((state) => {
      const views = state.views.map((view) => {
        if (view.name !== viewName) return view;

        if (view.tabs && view.tabs.open) {
          const openTabs = view.tabs.open.filter((tab) => tab.id !== tabId);

          return {
            ...view,
            tabs: {
              ...view.tabs,
              open: openTabs,
            },
          };
        }

        return view;
      });

      return { views };
    });
  },
});
