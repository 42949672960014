import { isWithinInterval } from 'date-fns';
import { usePipelineStoreContext } from './context/pipeline-context';
import { ErrorComponent } from '../../shared/components/error-boundary/ErrorComponent';
import { ButtonColors } from '../../shared/constants/ButtonColors';
import ButtonGroup from '../../shared/components/buttons/ButtonGroup';

import { usePipelineQuery } from './queries/pipelineQueries';

import { usePipelinePrivilegesQuery } from './queries/pipelinePriviligesQueries';
import { DescriptionList } from '../../shared/components/lists/DescriptionList';
import { Card } from '../../shared/components/cards/Card';
import { CardList } from '../../shared/components/cards/CardList';
import { ButtonGroupJustifyTypes } from '../../shared/constants/ButtonGroupJustifyTypes';
import { ExpandableCard } from '../../shared/components/cards/ExpandableCard';
import Button from '../../shared/components/buttons/Button';
import Text from '../../shared/components/text/Text';
import { Pill } from '../../shared/components/indicators/Pill';
import HeaderSubHeaders from '../../shared/components/headers/HeaderSubHeaders';

import Howto from '../../shared/components/message-popups/HowTo';

import AddButton from '../../shared/components/buttons/AddButton';
import useModal from '../../shared/components/modal/hooks/useModal';
import { PipelineBudgetForm } from './PipelineBudgetForm';

export default function PipelineEditBudget() {
  const { activePipelineSettings } = usePipelineStoreContext();
  const pipelineQuery = usePipelineQuery(activePipelineSettings.pipelineId);
  const privilegesQuery = usePipelinePrivilegesQuery(activePipelineSettings.pipelineId);

  const { openModal, closeModal } = useModal();

  const currentBudget = pipelineQuery?.data?.budgets.find((b) =>
    isWithinInterval(new Date(), {
      start: new Date(b.startDate),
      end: new Date(b.endDate),
    }),
  );

  const budgets = pipelineQuery?.data?.budgets.filter((b) => b.id !== currentBudget?.id);

  if (pipelineQuery.isPending || privilegesQuery.isPending) {
    return <></>;
  }

  if (pipelineQuery.isSuccess && privilegesQuery.isSuccess) {
    return (
      <>
        {privilegesQuery.data.canEditBudgets && (
          <ButtonGroup justify={ButtonGroupJustifyTypes.End}>
            <AddButton
              onClick={() => {
                openModal({
                  content: (
                    <PipelineBudgetForm
                      pipelineId={activePipelineSettings.pipelineId}
                      budget={undefined}
                      onClose={closeModal}
                    />
                  ),
                });
              }}
              text="Add budget"
            />
          </ButtonGroup>
        )}

        {currentBudget && (
          <Card as="div" isInteractive={false} showBorder>
            <div className="flex flex-row items-center justify-between space-x-2">
              <Pill color="green">Active</Pill>
              {privilegesQuery.data.canEditBudgets && (
                <ButtonGroup justify={ButtonGroupJustifyTypes.End}>
                  <Button
                    onClick={() => {
                      openModal({
                        content: (
                          <PipelineBudgetForm
                            pipelineId={activePipelineSettings.pipelineId}
                            budget={currentBudget}
                            onClose={closeModal}
                          />
                        ),
                      });
                    }}
                    text="Edit"
                    color={ButtonColors.White}
                  />
                </ButtonGroup>
              )}
            </div>

            <div className="p-2">
              <DescriptionList
                columns={3}
                items={[
                  { name: 'Start date', value: currentBudget?.startDate ?? '-' },
                  { name: 'End date', value: currentBudget?.endDate ?? '-' },
                  {
                    name: 'Amount',
                    value: `${
                      currentBudget
                        ? new Intl.NumberFormat('sv-SE', {
                            maximumFractionDigits: 0,
                          }).format(currentBudget.amount as number)
                        : '-'
                    } ${currentBudget?.currencyIsoCode ? currentBudget.currencyIsoCode : '[N/A]'}`,
                  },
                ]}
              />
            </div>
          </Card>
        )}
        <div className="flex-1 overflow-hidden">
          <CardList>
            {budgets?.map((budget) => (
              <ExpandableCard
                key={budget.id}
                renderHead={() => (
                  <div className="flex flex-row items-center justify-start space-x-2">
                    <Pill
                      color={
                        new Date(budget.startDate).getTime() > new Date().getTime()
                          ? 'purple'
                          : 'yellow'
                      }
                    >
                      {new Date(budget.startDate).getTime() > new Date().getTime()
                        ? 'Future'
                        : 'Past'}
                    </Pill>
                    <Text weight="medium" size="xSmall">
                      {budget.startDate} - {budget.endDate}
                    </Text>
                  </div>
                )}
              >
                <div className="p-2">
                  <HeaderSubHeaders
                    text="Amount"
                    size="small"
                    subTexts={[
                      `${
                        budget
                          ? new Intl.NumberFormat('sv-SE', {
                              maximumFractionDigits: 0,
                            }).format(budget.amount as number)
                          : '-'
                      }
                ${budget?.currencyIsoCode ?? '[N/A]'}`,
                    ]}
                  />

                  {privilegesQuery.data.canEditBudgets && (
                    <ButtonGroup justify={ButtonGroupJustifyTypes.End}>
                      <Button
                        onClick={() => {
                          openModal({
                            content: (
                              <PipelineBudgetForm
                                pipelineId={activePipelineSettings.pipelineId}
                                budget={budget}
                                onClose={closeModal}
                              />
                            ),
                          });
                        }}
                        text="Edit"
                        color={ButtonColors.White}
                      />
                    </ButtonGroup>
                  )}
                </div>
              </ExpandableCard>
            ))}
          </CardList>
        </div>

        <div className="flex pt-2">
          <Howto
            title="Budget"
            description={
              "Change your previous, current, and future budget to align with the board's targets"
            }
          />
        </div>
      </>
    );
  }

  return (
    <ErrorComponent
      queryResults={[pipelineQuery, privilegesQuery]}
      defaultErrorTexts={[{ code: 404, text: 'The pipeline could not be found.' }]}
    />
  );
}
