import { useMemo } from 'react';
import { useParams } from 'react-router';
import { abbreviateString } from '../../../shared/utils/stringUtils';
import {
  convertToBreadcrumbNavigatorItems,
  IBreadcrumbNavigatorItem,
} from '../../../layout/navigation/BreadcrumbNavigator/helpers/breadcrumbNavigatorHelper';
import { createRoute } from '../../../layout/navigation/helpers/routingHelpers';
import { useTenantsQuery } from '../../Companies/queries/tenantQueries';
import { useNetworkQuery } from '../queries/networkQueries';

export default function useNetworksBreadcrumbNavigation() {
  const tenantQuery = useTenantsQuery();

  const { id } = useParams();
  const contact = useNetworkQuery(id);

  const flatTenants = tenantQuery.data?.pages?.flat() || [];
  const networkTenant = flatTenants.find((tenant) => tenant.id === contact.data?.tenantId);

  const breadcrumbNavigatorItems = useMemo<IBreadcrumbNavigatorItem[]>(() => {
    const breadcrumbNavigatorItemsSorted = [];
    // Step 3: Push tenants to the breadcrumbNavigatorItems if the current page is
    // details of a network in order to enable tenant switching
    if (window.location.pathname.includes('detail') && networkTenant) {
      breadcrumbNavigatorItemsSorted.push({
        id: '',
        name: networkTenant.name,
        abbreviation: abbreviateString(networkTenant.name),
        route: createRoute('networks/list', networkTenant.id),
        isActive: true,
        isDisabled: true,
        siblings: convertToBreadcrumbNavigatorItems(flatTenants, '/networks/list/'),
      });
    }

    breadcrumbNavigatorItemsSorted.unshift({
      id: 'networks',
      name: 'Networks',
      abbreviation: abbreviateString('Networks'),
      route: '/',
      isActive: false,
      isDisabled: true,
    });

    return breadcrumbNavigatorItemsSorted;
  }, [flatTenants]);

  return { breadcrumbNavigatorItems };
}
