import { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import {
  ShareIcon as ShareIconSolid,
  UsersIcon as UsersIconSolid,
  BuildingOffice2Icon as BuildingOfficeIconSolid,
} from '@heroicons/react/24/solid';
import { ShareIcon, UsersIcon, BuildingOffice2Icon } from '@heroicons/react/24/outline';
import { INavGroups, INavItem } from '../../../shared/model/INavItem';
import {
  connectionConversationFilter,
  networkConversationFilter,
} from '../../../shared/filters/filters';
import { AppContext } from '../../../shared/context/context';
import useEntityManifest, { resolvePhotoUrl } from '../../../shared/hooks/useEntityManifest';
import { ResourceType } from '../../../shared/model/ResourceType';
import { useTenantsQuery } from '../../../features/Companies/queries/tenantQueries';
import { useMyUserProfileQuery } from '../../../features/Profile/queries/userProfileQueries';
import { useMyUserId } from '../../../shared/auth/accountHooks';
import { ITenantDto } from '../../../features/Companies/models/ITenantDto';
import useTenant from '../../../shared/hooks/useTenant';
import { createRoute, isCurrentRouteActive } from '../../navigation/helpers/routingHelpers';

const staticNavItems: Array<INavItem> = [
  {
    name: 'Your Profile',
    id: 'profile',
    route: 'profile',
    icon: BuildingOffice2Icon,
    isMobileOnly: true,
    activeIcon: BuildingOfficeIconSolid,
    current: false,
  },
];

const useMainNavigation = () => {
  const location = useLocation();
  const [sidebarNavItems, setSidebarNavItems] = useState<INavGroups>({
    all: [],
    myCompanies: [],
    settings: [],
  });
  const { state } = useContext(AppContext);

  const organizationManifest = useEntityManifest(ResourceType.Organization);
  const userTenantManifest = useEntityManifest(ResourceType.Contact);
  const tenantQuery = useTenantsQuery();
  const myProfileQuery = useMyUserProfileQuery();
  const myUserId = useMyUserId();

  const { activeTenant } = useTenant();

  const currentRoute = location.pathname.substring(1).toLowerCase();

  const updateMenu = () => {
    // flatten and filter out my own tenant (special handling)
    const flatTenants = (tenantQuery.data?.pages?.flat() || []).filter(
      (item) => item.id !== myUserId,
    );

    const contactsNavItem: INavItem = {
      name: 'Contacts',
      id: 'contacts',
      route: createRoute('contacts/list', activeTenant.id),
      icon: UsersIcon,
      activeIcon: UsersIconSolid,
      current:
        currentRoute.startsWith('contacts/list') || currentRoute.startsWith('contacts/detail'),
      conversationFilter: connectionConversationFilter,
    };

    const networksNavItem: INavItem = {
      name: 'Networks',
      id: 'networks',
      route: createRoute('networks/list', activeTenant.id),
      icon: ShareIcon,
      activeIcon: ShareIconSolid,
      current:
        currentRoute.startsWith('networks/list') || currentRoute.startsWith('networks/detail'),
      conversationFilter: networkConversationFilter,
    };

    const personalTenant = {
      id: myUserId,
      name: myProfileQuery.data?.displayName,
      type: 'User',
      photoUrl: myProfileQuery.data?.photoUrl,
    } as ITenantDto;
    const allOrganizations = [personalTenant].concat(flatTenants);
    const navItems = [
      contactsNavItem,
      networksNavItem,
      ...staticNavItems.map((item) => ({
        ...item,
        numberOfUnreadNotifications:
          state.conversations?.length && item.conversationFilter
            ? state.conversations
                ?.filter(item.conversationFilter)
                .filter((m) => m.latestMessage?.isUnread === true).length
            : 0,

        current: currentRoute.startsWith(item.route.toLowerCase()),
      })),
      {
        name: 'My Companies',
        id: 'my-companies',
        route: '',
        icon: BuildingOffice2Icon,
        activeIcon: BuildingOfficeIconSolid,
        isDisabled: true,
        current: false,
        children: allOrganizations.map(({ id, name, type, photoUrl, photoUpdateTime }) => ({
          id,
          name,
          route: createRoute('companies', id),
          current: isCurrentRouteActive('companies', id, location.pathname),
          photoUrl:
            photoUrl ||
            resolvePhotoUrl(ResourceType[type], id, photoUpdateTime || new Date().toISOString()),
          entityManifest: type === 'User' ? userTenantManifest : organizationManifest,
        })),
      },
    ];

    const navItemsWithSelectedParent = navItems.map((item) => {
      const itemCopy = { ...item };

      if (item.children && item.children.some((child) => child.current)) {
        itemCopy.current = true;
      }

      return itemCopy;
    });

    const navGroups: INavGroups = {
      all: navItemsWithSelectedParent.filter((item) => item.name !== 'My Companies'),
      myCompanies: navItemsWithSelectedParent
        .filter((item) => item.name === 'My Companies')
        .flatMap((item) => item.children || []),
      settings: [],
    };

    setSidebarNavItems(navGroups);
  };

  useEffect(() => {
    if (!tenantQuery.data) return;
    if (!state.conversations) return;
    if (!myProfileQuery.data) return;
    if (!myUserId) return;

    updateMenu();
  }, [tenantQuery.data, location, state.conversations, myProfileQuery.data, myUserId]);

  return sidebarNavItems;
};

export default useMainNavigation;
