import { ListLayout } from '../../../layout/ListLayout';
import { useFilters } from '../../../shared/components/filters/hooks/useFilters';
import FlexContainer from '../../../shared/components/structure/FlexContainer';
import { networkFilterConfig } from '../config/networkFilterConfig';
import useNetworkListColumns from '../hooks/useNetworkListColumns';
import { useNetworksActions } from '../hooks/useNetworksActions';
import { useNetworksInfiniteQuery } from '../queries/networkQueries';

export default function NetworkAllNetworksTab() {
  const actions = useNetworksActions();
  const columns = useNetworkListColumns();
  const filters = useFilters(networkFilterConfig);
  const networkQuery = useNetworksInfiniteQuery({ ...filters.filterState });
  return (
    <FlexContainer direction="column" className="h-full w-full overflow-hidden">
      <ListLayout
        title="Networks"
        headerActions={actions}
        columns={columns}
        query={networkQuery}
        filters={filters}
      />
    </FlexContainer>
  );
}
