import { HTMLProps } from 'react';
import clsx from 'clsx';
import { As } from '../../utils/types';

type Size = 'small' | 'medium' | 'large';
type ValidTags = 'div' | 'section' | 'header' | 'footer' | 'main' | 'aside' | 'article' | 'nav';
type Orientation = 'horizontal' | 'vertical' | 'both';

export type ISpacingContainerProps = As<ValidTags> & {
  size?: Size;
  orientation?: Orientation;
  className?: string;
  children: React.ReactNode;
};

const horizontalSizeMapping: Record<Size, string> = {
  small: 'space-x-2',
  medium: 'space-x-4',
  large: 'space-x-6',
};

const verticalSizeMapping: Record<Size, string> = {
  small: 'space-y-2',
  medium: 'space-y-4',
  large: 'space-y-6',
};

/**
 * @deprecated This component is obsolete and will be removed in a future release.
 * Should be replaced with FlexContainer
 */

export default function SpacingContainer({
  as = 'div',
  size = 'medium',
  orientation = 'vertical',
  className,
  children,
}: ISpacingContainerProps) {
  const classes = clsx(
    className || '',
    orientation === 'vertical' && verticalSizeMapping[size],
    orientation === 'horizontal' && horizontalSizeMapping[size],
    orientation === 'both' && `${verticalSizeMapping[size]} ${horizontalSizeMapping[size]}`,
  );
  const Tag: string = as;
  const attributes: HTMLProps<HTMLElement> = {
    className: classes,
  };

  return <Tag {...attributes}>{children}</Tag>;
}
