import ProfileAvatar from '../avatar/ProfileAvatar';
import FlexContainer from '../structure/FlexContainer';

interface ICardAvatarProps {
  avatarUrl: string;
}

export default function CardAvatar({ avatarUrl }: ICardAvatarProps) {
  return (
    <FlexContainer
      className="w-5 h-5 rounded-full absolute left-[7px] top-[-10px] bg-white border"
      gap="none"
      align="center"
      justify="center"
    >
      <ProfileAvatar
        avatarProps={{
          src: avatarUrl,
          alt: 'avatar',
          widthClass: 'w-4',
          heightClass: 'h-4',
        }}
      />
    </FlexContainer>
  );
}
