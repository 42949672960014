import { ChevronDown } from 'lucide-react';
import { useNavigate } from 'react-router';
import { cn } from '../../../lib/utils';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuDivider,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuTrigger,
} from './DropdownMenu';
import { useMyUserProfileQuery } from '../../../features/Profile/queries/userProfileQueries';
import useTenant from '../../hooks/useTenant';
import { useMyUserId } from '../../auth/accountHooks';
import { useTenantsQuery } from '../../../features/Companies/queries/tenantQueries';
import { ITenantDto } from '../../../features/Companies/models/ITenantDto';

export function SwitchTenantDropdown() {
  const navigate = useNavigate();
  const myUserId = useMyUserId();
  const myProfileQuery = useMyUserProfileQuery();
  const tenantQuery = useTenantsQuery();

  const { setActiveTenant } = useTenant();
  const flatTenants = (tenantQuery.data?.pages?.flat() || []).filter(
    (item) => item.id !== myUserId,
  );

  const personalTenant = {
    id: myUserId,
    name: myProfileQuery.data?.displayName,
    type: 'User',
    photoUrl: myProfileQuery.data?.photoUrl,
  } as ITenantDto;

  const allTenants = [personalTenant, ...flatTenants];

  return (
    <DropdownMenu>
      <DropdownMenuTrigger>
        <ChevronDown className={cn('w-6 h-6 text-black')} />
      </DropdownMenuTrigger>

      <DropdownMenuContent>
        <DropdownMenuLabel>My Companies</DropdownMenuLabel>
        <DropdownMenuItem onClick={() => navigate('all')}>All</DropdownMenuItem>
        <DropdownMenuDivider />
        {allTenants?.map((item) => (
          <DropdownMenuItem
            key={item.name}
            onClick={() => {
              setActiveTenant(item);
              navigate(item.id);
            }}
          >
            {item.name}
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
