import { useMemo } from 'react';
import ScrollBarWrapper from '../../shared/components/scrolling/ScrollBarWrapper';
import { IContactDto } from './models/IContactDto';
import { useListShareContactRequestsQuery } from '../Request/queries/shareContactRequestQueries';
import { ContactShareThisContactToList } from './ContactShareThisContactToList';
import { mergeConnectionsAndShareContactRequests } from './mergecontacts/MergeContactsAndShareRequests';
import HowTo from '../../shared/components/message-popups/HowTo';
import { useConnectionsQuery, useHasConnectionQuery } from './queries/connectionQueries';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '../../shared/components/accordion/Accordion';
import ContactsShareContactToThisList from './ContactsShareContactToThisList';
import { transform } from '../../lib/utils';
import { transformConnectionWithShareInfoDtoToAvatarListItem } from './models/transformers/contactTransformers';
import { ContactAvatarList } from './ContactAvatarList';
import FlexContainer from '../../shared/components/structure/FlexContainer';
import Button from '../../shared/components/buttons/Button';
import { ButtonColors } from '../../shared/constants/ButtonColors';
import { useConnectWithUserMutation } from '../Request/queries/connectionRequestQueries';
import Text from '../../shared/components/text/Text';
import { Separator } from '../../layout/separator/Separator';

interface IProps {
  contact: IContactDto;
}

export default function ContactShareContact({ contact }: IProps) {
  const hasConnectionQuery = useHasConnectionQuery(contact.userId);
  const connectionsQuery = useConnectionsQuery({
    searchTerm: '',
    excludeContactIds: [contact.id],
  });
  const shareContactRequestsQuery = useListShareContactRequestsQuery({
    sharedContactId: contact.id,
    searchType: 'Sent',
  });

  const connectWithUserMutation = useConnectWithUserMutation();

  const contactsWithShareStatus = useMemo(() => {
    if (!connectionsQuery.isSuccess || !shareContactRequestsQuery.isSuccess) {
      return [];
    }

    return mergeConnectionsAndShareContactRequests(
      connectionsQuery.data,
      shareContactRequestsQuery.data,
    );
  }, [
    connectionsQuery.data,
    shareContactRequestsQuery.data,
    connectionsQuery.isSuccess,
    shareContactRequestsQuery.isSuccess,
  ]);

  const hasPendingShares = contactsWithShareStatus.some(
    (c) => c.shareInformation?.status === 'Pending',
  );
  const hasAcceptedShares = contactsWithShareStatus.some(
    (c) => c.shareInformation?.status === 'Accepted',
  );

  return (
    <FlexContainer direction="column" className="h-full" gap="none">
      <ScrollBarWrapper className="flex flex-col ">
        <Accordion type="single" collapsible>
          <AccordionItem value="shareThisContactTo">
            <AccordionTrigger>Share {contact.firstName} to</AccordionTrigger>
            <AccordionContent>
              <ContactShareThisContactToList contact={contact} />
            </AccordionContent>
          </AccordionItem>

          <AccordionItem value="shareContactsTo">
            <AccordionTrigger>{`Share contacts to ${contact.firstName}`}</AccordionTrigger>
            <AccordionContent>
              {contact.userId && hasConnectionQuery.data && (
                <ContactsShareContactToThisList contact={contact} />
              )}

              {contact.userId && !hasConnectionQuery.data && (
                <>
                  <Text as="p" size="small" brightness="light">
                    In order to share contacts with this contact you need to connect first.
                  </Text>
                  <Separator />
                  <FlexContainer direction="row" justify="end">
                    <Button
                      color={ButtonColors.Blue}
                      text="Connect"
                      size="small"
                      onClick={() => {
                        if (contact.userId) {
                          connectWithUserMutation.mutate({
                            userId: contact.userId,
                            displayName: contact.displayName,
                          });
                        }
                      }}
                    />
                  </FlexContainer>
                </>
              )}
            </AccordionContent>
          </AccordionItem>

          <AccordionItem value="pending" disabled={!hasPendingShares}>
            <AccordionTrigger
              badgeProps={{
                value: contactsWithShareStatus.filter(
                  (c) => c.shareInformation?.status === 'Pending',
                ).length,
              }}
            >
              Pending
            </AccordionTrigger>
            <AccordionContent>
              <AccordionContent>
                <ContactAvatarList
                  items={contactsWithShareStatus
                    .filter((c) => c.shareInformation?.status === 'Pending')
                    .map((c) => transform(c, transformConnectionWithShareInfoDtoToAvatarListItem))}
                />
              </AccordionContent>
            </AccordionContent>
          </AccordionItem>

          <AccordionItem value="accepted" disabled={!hasAcceptedShares}>
            <AccordionTrigger
              badgeProps={{
                value: contactsWithShareStatus.filter(
                  (c) => c.shareInformation?.status === 'Accepted',
                ).length,
              }}
            >
              Accepted
            </AccordionTrigger>
            <AccordionContent>
              <ContactAvatarList
                items={contactsWithShareStatus
                  .filter((c) => c.shareInformation?.status === 'Accepted')
                  .map((c) => transform(c, transformConnectionWithShareInfoDtoToAvatarListItem))}
              />
            </AccordionContent>
          </AccordionItem>
        </Accordion>
      </ScrollBarWrapper>

      <HowTo
        title="Share"
        description="Share this contact to one or more of your connections or share contacts to this contact."
      />
    </FlexContainer>
  );
}
