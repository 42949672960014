import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

/**
 * Helper hook to manage tab navigation using a search param (url query).
 * @param defaultValue The initial tab to be selected.
 * @returns
 */
export default function useSearchParamsTabNavigation(defaultValue: string) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [activeTab, setActiveTab] = useState<string>(searchParams.get('tab') || defaultValue);

  const handleSetActiveTab = useCallback(
    (value: SetStateAction<string>) => {
      if (typeof value === 'function') {
        setActiveTab((prev) => {
          const next = value(prev);
          setSearchParams({ tab: next });
          return next;
        });

        return;
      }

      setActiveTab(value);
      setSearchParams({ tab: value });
    },
    [setActiveTab, setSearchParams],
  );

  useEffect(() => {
    const tab = searchParams.get('tab');

    // This covers the case where we have a new default value that is not
    // found in the query params.
    if (!tab && defaultValue !== activeTab) {
      setActiveTab(`${defaultValue}`);
      return;
    }

    if (tab && tab !== activeTab) {
      if (tab) setActiveTab(tab);
    }
  }, [searchParams.get('tab'), defaultValue]);

  return [activeTab, handleSetActiveTab] as [string, Dispatch<SetStateAction<string>>];
}
