import * as React from 'react';
import { Fragment, useEffect, useRef, useState } from 'react';
import { X } from 'lucide-react';
import { Tabs, TabsContent, TabsList, TabsTrigger } from './TabsList';
import { cn } from '../../../../lib/utils';
import AddIcon from '../../icons/AddIcon';
import { Popover, PopoverContent, PopoverTrigger } from '../../popovers/Popover';
import { TabType } from '../../../store/slices/viewSlice';

export interface TabItem {
  /** A unique string to identify this tab */
  id: string;
  /** The name that appears on the tab trigger */
  name: string;
  /** Optional flag to hide this tab */
  isHidden?: boolean;
  /** Tab type */
  type: TabType;
  /** The content rendered when this tab is active */
  content: React.ReactNode;
}

interface PageTabsProps {
  /** The tab items to render */
  items: TabItem[];
  /**
   * A default tab id to be selected initially.
   * If this value changes, the component updates the active tab accordingly.
   * If this value does not change, the component becomes uncontrolled after initial mount.
   */
  defaultTabId?: string;
  /** Optional callback when a tab is hidden */
  onHideItem?: (tab: TabItem) => void;
  /** Optional callback when the active tab changes */
  onValueChange?: (name: string) => void;
  /** Optional className for the Tabs container */
  className?: string;
  /** Optional settings component to render in a popover */
  settingsComponent?: React.ReactNode;
}

export function PageTabs({
  items,
  defaultTabId,
  settingsComponent,
  onHideItem,
  onValueChange,
  className,
}: PageTabsProps) {
  const fallbackId = items?.[0]?.id ?? '';
  const [activeTab, setActiveTab] = useState(defaultTabId ?? fallbackId);

  // Keep track of previous defaultTabId to detect changes
  const prevDefaultRef = useRef<string | undefined>(defaultTabId);

  // Keep track of number of items to detect changes
  const prevNumberOfItemsRef = useRef<number>(items.length);

  useEffect(() => {
    // If defaultTabId changes after initial mount, update the active tab.
    if (
      (defaultTabId !== prevDefaultRef.current && defaultTabId !== undefined) ||
      items.length < prevNumberOfItemsRef.current
    ) {
      setActiveTab(defaultTabId ?? fallbackId);
    }
    prevDefaultRef.current = defaultTabId;
    prevNumberOfItemsRef.current = items.length;
  }, [defaultTabId, items]);

  const handleChange = (newTabId: string) => {
    setActiveTab(newTabId);
    onValueChange?.(newTabId);
  };

  return (
    <Tabs
      className={cn('flex flex-col pl-0', className)}
      value={activeTab}
      onValueChange={handleChange}
    >
      <TabsList className="pl-0 pb-0 w-full items-center justify-start">
        {items.map((item) => (
          <Fragment key={`tab-trigger-${item.id}`}>
            {!item.isHidden && (
              <TabsTrigger
                value={item.id}
                className="relative px-4 py-2 text-sm font-medium border-r border data-[state=active]:bg-white data-[state=active]:border-b-white data-[state=inactive]:border-transparent flex items-center"
              >
                {item.name}
                {item.type === 'standard' && (
                  <X className="h-4 w-4 ml-2 text" onClick={() => onHideItem && onHideItem(item)} />
                )}
              </TabsTrigger>
            )}
          </Fragment>
        ))}
        {settingsComponent && (
          <Popover>
            <PopoverTrigger>
              <span className="flex flex-row space-x-1.5 items-center ml-2">
                <AddIcon className="h-5 w-5" />
                <span className="text-sm text">Add view</span>
              </span>
            </PopoverTrigger>
            <PopoverContent align="start" sideOffset={-15} alignOffset={15}>
              {settingsComponent}
            </PopoverContent>
          </Popover>
        )}
      </TabsList>

      {items.map((item) => (
        <Fragment key={`tab-content-${item.id}`}>
          {!item.isHidden && (
            <TabsContent
              key={item.id}
              value={item.id}
              className="border border-gray-300 border-t-0 rounded-b-mdp-4 p-4 bg-white w-full"
            >
              {item.content}
            </TabsContent>
          )}
        </Fragment>
      ))}
    </Tabs>
  );
}
