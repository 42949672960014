import { useNavigate, useParams } from 'react-router';
import { useState } from 'react';
import SelectCardList, {
  SelectCardListItem,
  SelectCardListPreviewItem,
} from '../../shared/components/lists/SelectCardList';
import projectDummy from '../../assets/mocks/projectsMock.png';
import SelectPrompt from '../../shared/components/prompts/SelectPrompt';
import SearchBar from '../../shared/components/search/SearchBar';
import useDebounce from '../../shared/hooks/useDebounce';
import { useProjectsInfiniteQuery } from './queries/projectQueries';
import { IProjectDto } from './models/IProjectDto';
import { BoardType } from './models/IBoardDto';
import { ButtonColors } from '../../shared/constants/ButtonColors';
import BoardPipelineIcon from '../../shared/components/icons/BoardPipelineIcon';
import BoardCreateModal from './BoardCreateModal';
import { InfiniteApiQueryScroll } from '../../shared/components/scrolling/InfiniteApiQueryScroll';
import useEntityManifest from '../../shared/hooks/useEntityManifest';
import { ResourceType } from '../../shared/model/ResourceType';
import WrapperDesktop from '../../shared/components/screen-discriminators/WrapperDesktop';
import WrapperMobile from '../../shared/components/screen-discriminators/WrapperMobile';
import useProjectActions from './hooks/useProjectActions';
import { useMyUserId } from '../../shared/auth/accountHooks';
import { IActivityItem } from '../../shared/components/activity/IActivityItem';
import useModal from '../../shared/components/modal/hooks/useModal';
import NavigationList from '../../layout/navigation/NavigationList';

export default function ProjectsCardView() {
  const navigate = useNavigate();
  const myUserId = useMyUserId();
  const [searchTerm, setSearchTerm] = useState('');
  const { openModal, closeModal } = useModal();
  const debounceSearchTerm = useDebounce(searchTerm);

  const { tenantId } = useParams();

  const projectsQuery = useProjectsInfiniteQuery({
    searchTerm: debounceSearchTerm,
    tenantId,
  });
  const projectManifest = useEntityManifest(ResourceType.Project);

  const handleCreateBoardSuccess = (boardId: string, projectId: string) => {
    navigate(`projects/${projectId}/boards/${boardId}`);
    closeModal();
  };

  const createPreviewItems = (project: IProjectDto): SelectCardListPreviewItem[] => {
    const previewItems = project.boards.map(
      (item) =>
        ({
          id: item.id,
          color: item.type === BoardType.Pipeline ? ButtonColors.Green : ButtonColors.Blue,
          icon:
            item.type === BoardType.Pipeline ? (
              <BoardPipelineIcon height="100%" width="100%" />
            ) : (
              <BoardPipelineIcon height="100%" width="100%" />
            ),
          onClick: () => navigate(`projects/${item.projectId}/boards/${item.id}`),
        } as SelectCardListPreviewItem),
    );
    return previewItems;
  };
  const showPrompt =
    projectsQuery.isFetched && !searchTerm && projectsQuery.data?.pages.length === 0;

  return (
    <>
      <div className="flex items-center gap-3">
        <div className="w-full lg:w-96">
          <SearchBar
            placeholder="Search to find projects"
            searchTerm={searchTerm}
            onSearchChange={(e) => {
              setSearchTerm(e.currentTarget.value);
            }}
          />
        </div>
      </div>
      {showPrompt && (
        <SelectPrompt
          imageUrl={projectDummy}
          text={
            <>
              Start your collaboration by creating a project
              <br /> Click on the &apos;Create new project&apos; to get started
            </>
          }
        />
      )}
      {!showPrompt && (
        <InfiniteApiQueryScroll query={projectsQuery}>
          {({ data }) => (
            <>
              <WrapperMobile>
                <NavigationList
                  data={data}
                  isPending={projectsQuery.isPending}
                  itemIdProperty={({ id }) => id}
                  itemUrlProperty={({ id }) => id}
                  itemUrlPropertyPrefix="projects/"
                  itemHeadlineProperty={({ name }) => name}
                  itemInformationProperty={({ name }) => name}
                  itemImgSrcProperty={({ photoUrl }) => photoUrl || projectManifest.icon}
                />
              </WrapperMobile>
              <WrapperDesktop>
                <SelectCardList
                  orientation="horizontal"
                  isEmpty={data.length === 0}
                  isLoading={projectsQuery.isPending}
                >
                  {data.map((item) => {
                    const myVisit = item.visits.find((visit) => visit.userId === myUserId);
                    const items = [
                      { type: 'Contact', value: myVisit?.contactsCount || 0 },
                      { type: 'Conversation', value: myVisit?.conversationsCount || 0 },
                      { type: 'Task', value: myVisit?.tasksCount || 0 },
                      { type: 'Mention', value: myVisit?.mentionsCount || 0 },
                    ] as IActivityItem[];
                    return (
                      <SelectCardListItem
                        id={item.id}
                        key={item.id}
                        title={item.displayName}
                        updatedTime={item.updatedTime}
                        previewItems={createPreviewItems(item)}
                        previewItemsAppendPlaceholders
                        onPreviewPlaceholderClick={() => {
                          openModal({
                            content: (
                              <BoardCreateModal
                                projectId={item.id}
                                onCancel={closeModal}
                                onSuccess={handleCreateBoardSuccess}
                              />
                            ),
                          });
                        }}
                        totalItemCount={item.boards.length}
                        totalItemText="boards"
                        activityItems={items}
                        actionsResolver={() => useProjectActions(item)}
                        onClick={() => navigate(`projects/${item.id}`)}
                      />
                    );
                  })}
                </SelectCardList>
              </WrapperDesktop>
            </>
          )}
        </InfiniteApiQueryScroll>
      )}
    </>
  );
}
