import { Outlet, useParams } from 'react-router';
import { BaseLayout } from '../../layout/pages/BaseLayout';
import useNetworksBreadcrumbNavigation from './hooks/useNetworksBreadcrumbNavigation';
import NetworkAddNetworkButton from './NetworkAddNetworkButton';
import { SwitchTenantDropdown } from '../../shared/components/drop-down-menu/SwitchTenantDropdown';
import useIsAllRoute from '../../shared/hooks/useIsAllRoute';
import { useTenantsQuery } from '../Companies/queries/tenantQueries';
import { useNetworksActions } from './hooks/useNetworksActions';
import useTenants from '../../shared/hooks/useTenant';

export function NetworksListLayout() {
  const { breadcrumbNavigatorItems } = useNetworksBreadcrumbNavigation();
  const isAllRoute = useIsAllRoute();
  const tenantQuery = useTenantsQuery();
  const actions = useNetworksActions();

  const { activeTenant } = useTenants();

  if (!tenantQuery.isSuccess) return null;

  return (
    <BaseLayout
      title={isAllRoute ? 'All Networks' : activeTenant?.name}
      headerActions={actions}
      breadcrumbNavigatorItems={breadcrumbNavigatorItems}
      contentAfterHeader={[
        { id: 'switchTenant', content: <SwitchTenantDropdown /> },
        { id: 'addNetworkButton', content: <NetworkAddNetworkButton /> },
      ]}
    >
      <Outlet />
    </BaseLayout>
  );
}
