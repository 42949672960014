import { CardAction } from '../../../shared/components/cards/ProfileCard';
import { ButtonColors } from '../../../shared/constants/ButtonColors';
import { UserProfileDto } from '../../Profile/models/UserProfile';
import { useConnectWithUserMutation } from '../../Request/queries/connectionRequestQueries';
import { useHasConnectionQuery } from '../queries/connectionQueries';
import { useFollowMutation, useIsFollowingQuery } from '../queries/contactQueries';

type IProps = {
  userProfile: UserProfileDto;
  contactId?: string;
  onSuccess?: () => void;
};

export function useAddUserAsContactCardAction({
  userProfile,
  contactId,
  onSuccess,
}: IProps): CardAction {
  const isFollowingQuery = useIsFollowingQuery(userProfile.id);
  const followMutation = useFollowMutation();
  return {
    key: 'add',
    label: followMutation.isPending || (isFollowingQuery.data ?? false) ? 'Already added' : 'Add',
    color: ButtonColors.Blue,
    isDisabled: followMutation.isPending || (isFollowingQuery.data ?? false),
    onClick: () =>
      followMutation.mutate({ userId: userProfile.id, contactId, ...userProfile }, { onSuccess }),
  };
}

export function useConnectWithUserCardAction({
  userProfile,
  contactId,
  onSuccess,
}: IProps): CardAction {
  const hasConnectionQuery = useHasConnectionQuery(userProfile.id);
  const connectWithUserMutation = useConnectWithUserMutation();
  const followMutation = useFollowMutation();
  return {
    key: 'connect',
    label:
      connectWithUserMutation.isPending || (hasConnectionQuery.data ?? false)
        ? 'Already connected'
        : 'Connect',
    color: ButtonColors.Blue,
    isDisabled: connectWithUserMutation.isPending || (hasConnectionQuery.data ?? false),
    onClick: () => {
      followMutation.mutate({ userId: userProfile.id, contactId, ...userProfile });
      connectWithUserMutation.mutate({ userId: userProfile.id, ...userProfile }, { onSuccess });
    },
  };
}
