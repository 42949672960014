import { ChevronRight } from 'lucide-react';
import Alert from '../../shared/components/alerts/Alert';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '../../shared/components/drop-down-menu/DropdownMenu';
import FlexContainer from '../../shared/components/structure/FlexContainer';
import Text from '../../shared/components/text/Text';
import { ITenantDto } from '../Companies/models/ITenantDto';
import { ResourceType } from '../../shared/model/ResourceType';

interface ISelectTenantProps {
  tenants?: ITenantDto[];
  selectedTenant: ITenantDto;
  entityName: ResourceType;
  isDisabled?: boolean;
  onSelectedTenantChange: (tenant: ITenantDto) => void;
}

export default function SelectResourceTenant({
  tenants,
  selectedTenant,
  entityName,
  isDisabled,
  onSelectedTenantChange,
}: ISelectTenantProps) {
  return (
    <Alert
      type="Information"
      text={
        <DropdownMenu>
          <DropdownMenuTrigger className="flex flex-row items-center" disabled={isDisabled}>
            <Text size="small">
              <FlexContainer direction="row" gap="none" align="center">
                The {entityName.toLowerCase()} will be added to{' '}
                <span className="text-cyan ml-1">{selectedTenant?.name}</span>
                <ChevronRight className="w-5 h-5 mt-1" />
              </FlexContainer>
            </Text>
          </DropdownMenuTrigger>

          <DropdownMenuContent>
            {tenants?.map((company) => (
              <DropdownMenuItem
                key={company.id}
                onClick={() => {
                  onSelectedTenantChange(company);
                }}
              >
                <span>{company.name}</span>
              </DropdownMenuItem>
            ))}
          </DropdownMenuContent>
        </DropdownMenu>
      }
    />
  );
}
