import { DraggableProvided } from 'react-beautiful-dnd';
import { HTMLProps } from 'react';
import clsx from 'clsx';
import { As } from '../../utils/types';
import CardAvatar from './CardAvatar';

type ValidTags = 'div' | 'li';

/**
 * Wrap the provided childrens to support the combindation
 * isInteractive=true and isChildrenInteractive=true.
 * Will allow handling the hover effects between parent and children correctly in this case
 */
export function CardInteractivePart({
  className,
  children,
}: {
  className?: string;
  children: React.ReactNode;
}) {
  const classes = clsx(className, 'interactive');
  return (
    <div
      role="presentation"
      className={classes}
      onClick={(e) => {
        // Do this to trap any click from propigating to parent to avoid click handlers
        // outside of the interactive part to react on the click
        e.preventDefault();
        e.stopPropagation();
      }}
      onKeyDown={(e) => {
        // Need this for lint (and accessability)
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      {children}
    </div>
  );
}

export type ICardProps = As<ValidTags> & {
  className?: string;
  width?: 'fit' | 'full' | 'small' | 'medium' | 'large';
  height?: 'fit' | 'full' | 'small' | 'medium' | 'large';
  draggableProvided?: DraggableProvided;
  isInteractive?: boolean;
  avatarUrl?: string;
  /**
   * If the children element of the card should be interactive.
   * Wrap all "interactable" children inside a <CardInteractivePart>...</CardInteractivePart>
   *
   * Note: If IsInteractive=false then the wapping is not needed
   */
  isChildrenInteractive?: boolean;
  showBorder?: boolean;
  onClick?: (args?: unknown) => void;
  children: React.ReactNode;
};

/**
 * Card component for displaying a card.
 * By passing the draggableProvided prop, the card will be draggable and have a drag handle style.
 * */
export function Card({
  as = 'li',
  className,
  width,
  height,
  draggableProvided,
  isInteractive = true,
  isChildrenInteractive = false,
  showBorder = false,
  children,
  avatarUrl,
  ...rest
}: ICardProps) {
  const classes = clsx(
    'p-2 relative rounded-lg bg-white border-slate-300 border-[1px] my-2 h-fit',
    className,
    isInteractive &&
      !isChildrenInteractive &&
      'cursor-pointer hover:outline-cyan-500 hover:outline hover:outline-offset-[-3px]',
    isInteractive &&
      isChildrenInteractive &&
      'cursor-pointer [&:not(:has(.interactive:hover))]:hover:outline-cyan-500 [&:not(:has(.interactive:hover))]:hover:outline [&:not(:has(.interactive:hover))]:hover:outline-offset-[-3px]',
    draggableProvided &&
      'relative pl-6 before:w-5 before:rounded-tl-sm before:rounded-bl-sm before:rounded-tl-md before:rounded-bl-md before:cursor-move before:absolute before:h-full before:top-[1px] before:left-0 drag-dots',
    showBorder && 'outline-cyan-500 outline outline-offset-[-3px]',
  );

  let sizeClasses = '';

  if (width) {
    switch (width) {
      case 'full':
        sizeClasses = 'w-full';
        break;
      case 'small':
        sizeClasses = 'w-full sm:w-56';
        break;
      case 'medium':
        sizeClasses = 'w-full sm:w-80';
        break;
      case 'large':
        sizeClasses = 'w-full sm:w-96';
        break;
      case 'fit':
      default:
        sizeClasses = 'w-full sm:w-fit';
        break;
    }
  }

  let heightSizeClasses = '';
  if (height) {
    switch (height) {
      case 'full':
        heightSizeClasses = 'h-full';
        break;
      case 'small':
        heightSizeClasses = 'h-full sm:h-56';
        break;
      case 'medium':
        heightSizeClasses = 'h-full sm:h-80';
        break;
      case 'large':
        heightSizeClasses = 'h-full sm:h-96';
        break;
      case 'fit':
      default:
        heightSizeClasses = 'h-full sm:h-fit';
        break;
    }
  }

  const _className = clsx(classes, sizeClasses, heightSizeClasses);

  const Tag: string = as;

  const attributes: HTMLProps<HTMLElement> = {
    ref: draggableProvided?.innerRef,
    className: _className,
    ...draggableProvided?.draggableProps,
    ...draggableProvided?.dragHandleProps,
    ...rest,
  };

  return (
    <Tag {...attributes}>
      {avatarUrl && <CardAvatar avatarUrl={avatarUrl} />}
      {children}
    </Tag>
  );
}
