import { useState } from 'react';
import { ChevronDown } from 'lucide-react';
import DataTable, { DataTableProps } from '../shared/components/table/DataTable';
import { IBaseLayoutProps } from './pages/BaseLayout';
import { ILayoutActionItem } from './pages/types';
import { Popover, PopoverContent, PopoverTrigger } from '../shared/components/popovers/Popover';
import SearchBar from '../shared/components/search/SearchBar';
import { UseInfiniteApiQueryResult } from '../shared/hooks/useInfiniteApiQuery';
import { FiltersSettings } from '../shared/components/filters/hooks/types';
import FlexContainer from '../shared/components/structure/FlexContainer';
import FlexItem from '../shared/components/structure/FlexItem';
import Filters from '../shared/components/filters/Filters';
import FiltersActiveList from '../shared/components/filters/FiltersActiveList';
import { InfiniteApiQueryScroll } from '../shared/components/scrolling/InfiniteApiQueryScroll';
import { useOptionalControlledState } from '../shared/hooks/useOptionalControlledState';
// Potentially, we can add KPI's here.

export interface IListLayoutProps<TData, TValue, TFilter>
  extends Omit<IBaseLayoutProps, 'children'>,
    Omit<
      DataTableProps<TData, TValue>,
      'data' | 'isLoading' | 'globalFilter' | 'columnFilters' | 'onColumnFiltersChange'
    >,
    Partial<Pick<DataTableProps<TData, TValue>, 'columnFilters' | 'onColumnFiltersChange'>> {
  query: UseInfiniteApiQueryResult<TData>;
  actions?: ILayoutActionItem[];
  searchPlaceholder?: string;
  filters?: FiltersSettings<TFilter>;
}

export function ListLayout<TData, TValue, TFilter>({
  query,
  filters,
  searchPlaceholder,
  columnFilters: controlledColumnFilters,
  onColumnFiltersChange,
  ...dataTableProps
}: IListLayoutProps<TData, TValue, TFilter>) {
  const [searchTerm, setSearchTerm] = useState('');

  const [columnFilters, setColumnFilters] = useOptionalControlledState(
    controlledColumnFilters,
    onColumnFiltersChange,
    [],
  );

  return (
    <>
      <FlexContainer className="w-full overflow-hidden ">
        <FlexContainer className="w-full">
          <FlexItem className="basis-4/6 md:basis-2/6">
            <SearchBar
              searchTerm={searchTerm}
              onSearchChange={(e) => setSearchTerm(e.target.value)}
              placeholder={searchPlaceholder}
            />
          </FlexItem>
          {filters && (
            <Popover>
              <PopoverTrigger>
                <FlexContainer align="center">
                  Filter <ChevronDown className="h-5 w-5 ml-1" />
                </FlexContainer>
              </PopoverTrigger>
              <PopoverContent align="start" sideOffset={-15} alignOffset={15} className="min-w-64">
                <Filters filter={filters} />
              </PopoverContent>
            </Popover>
          )}
        </FlexContainer>
      </FlexContainer>
      {filters && (
        <FlexContainer align="start">
          <FiltersActiveList filter={filters} />
        </FlexContainer>
      )}

      <div className="flex-1 w-full overflow-hidden">
        <InfiniteApiQueryScroll query={query}>
          {({ data }) => (
            <DataTable
              data={data}
              isLoading={query.isPending}
              globalFilter={searchTerm}
              columnFilters={columnFilters}
              onColumnFiltersChange={setColumnFilters}
              {...dataTableProps}
            />
          )}
        </InfiniteApiQueryScroll>
      </div>
    </>
  );
}
