import { Link } from 'react-router-dom';
import { IResourceLink, NotificationResourceType } from '../../shared/model/INotificationDto';
import { ContactUserProfileLink } from '../Contacts/ContactUserProfileLink';
import { ConversationLink } from '../Messaging/ConversationLink';

interface IProps extends IResourceLink {
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
}

export function ResourceLink({ type, ids, url, text, onClick }: IProps) {
  const _text = text || 'Go to resource';
  const className = 'text-cyan';
  const rootId = Array.isArray(ids) ? ids[0] : ids;
  if (type === NotificationResourceType.Profile) {
    return (
      <ContactUserProfileLink onClick={onClick} userId={rootId} className={className}>
        {_text}
      </ContactUserProfileLink>
    );
  }
  if (type === NotificationResourceType.Conversation) {
    return (
      <ConversationLink onClick={onClick} conversationId={rootId} className={className}>
        {_text}
      </ConversationLink>
    );
  }

  return (
    <Link onClick={onClick} to={url} className={className}>
      {_text}
    </Link>
  );
}
