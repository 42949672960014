import { ColumnDef, FilterFn } from '@tanstack/react-table';
import { Eye, EyeOff } from 'lucide-react';
import { useMemo } from 'react';
import { IActivityItem } from '../../../shared/components/activity/IActivityItem';
import { INetworkDto } from '../../../shared/model/INetwork';
import { useMyUserId } from '../../../shared/auth/accountHooks';
import useEntityManifest from '../../../shared/hooks/useEntityManifest';
import { ResourceType } from '../../../shared/model/ResourceType';
import { createDataTableColumnHelper } from '../../../shared/components/table/DataTable';
import ProfileRowCell from '../../../shared/components/table/cells/ProfileRowCell';
import { createRoute } from '../../../layout/navigation/helpers/routingHelpers';
import MemberRowCell from '../../../shared/components/table/cells/MembersRowCell';
import StandardHeaderCell from '../../../shared/components/table/cells/StandardHeaderCell';
import IconRowCell from '../../../shared/components/table/cells/IconRowCell';
import { createActivityColumnDefinition } from '../../Tracking/trackingHelpers';
import ActionRowCell from '../../../shared/components/table/cells/ActionRowCell';
import useNetworkActions from './useNetworkActions';

const activityHeaderItems = [
  { type: 'Contact', label: 'New contacts' },
  { type: 'Conversation', label: 'New conversations' },
  { type: 'Task', label: 'New tasks' },
  { type: 'Mention', label: 'New mentions' },
] as IActivityItem[];

const customFilterFunction: FilterFn<INetworkDto> = (row, columnId, filterValue) => {
  const value = row.getValue(columnId) as string;
  return value.toLowerCase().includes(filterValue.toLowerCase());
};

export default function useNetworkListColumns() {
  const myUserId = useMyUserId();
  const networkManifest = useEntityManifest(ResourceType.Network);
  const contactManifest = useEntityManifest(ResourceType.Contact);
  return useMemo(() => {
    const columnHelper = createDataTableColumnHelper<INetworkDto>();

    return [
      // columnHelper.multiSelect(),
      columnHelper.accessor('name', {
        id: 'name',
        header: 'Name',
        filterFn: customFilterFunction,
        cell: ({ row, getValue }) => (
          <div className="w-max">
            <ProfileRowCell
              photoUrl={row.original.photoUrl || networkManifest.icon}
              url={createRoute('networks/detail', row.original.id, 'info')}
              name={getValue()}
            />
          </div>
        ),
      }),
      columnHelper.accessor('description', {
        id: 'description',
        header: 'Description',
        filterFn: customFilterFunction,
      }),
      columnHelper.accessor('memberCount', {
        meta: {
          size: '175px',
        },
        header: () => 'Members',
        cell: ({ getValue }) => (
          <MemberRowCell
            members={Array(getValue())
              .fill(1)
              .map((_, i) => ({ id: i.toString(), photoUrl: contactManifest.icon }))}
          />
        ),
      }),
      columnHelper.accessor('type', {
        id: 'privacy',
        meta: {
          size: '140px',
        },
        header: () => <StandardHeaderCell alignment="center">Privacy</StandardHeaderCell>,
        cell: ({ getValue }) => <IconRowCell icon={getValue() === 'Private' ? EyeOff : Eye} />,
        filterFn: ({ getValue }, columnId, filterValue: string[]) => {
          if (filterValue.length === 0) {
            return true;
          }
          return filterValue.includes(getValue(columnId));
        },
      }),
      columnHelper.accessor('visits', {
        ...createActivityColumnDefinition<INetworkDto>({
          id: 'visits',
          activityItems: activityHeaderItems,
          myUserId: myUserId || '',
        }),
      }),
      columnHelper.actions(({ row }) => (
        <ActionRowCell
          resolver={(isOpen) => useNetworkActions(isOpen ? row.original.id : undefined)}
        />
      )),
    ] as ColumnDef<INetworkDto>[];
  }, []);
}
