import { LinkIcon, UserPlusIcon } from '@heroicons/react/24/outline';
import {
  useConnectWithUserMutation,
  useConnectionIsInitatedQuery,
} from '../../Request/queries/connectionRequestQueries';
import { useHasConnectionQuery } from '../queries/connectionQueries';
import { useFollowMutation, useIsFollowingQuery } from '../queries/contactQueries';
import { useUserProfileQuery } from '../../Profile/queries/userProfileQueries';
import { IActionItem } from '../../../shared/model/IActionItem';

export interface ISocialContactActions {
  menuItems: IActionItem[];
}

export default function useUserMenuActions(userId?: string): ISocialContactActions {
  const profileQuery = useUserProfileQuery(userId);
  const isConnectionInitiated = useConnectionIsInitatedQuery(userId);
  const isConnected = useHasConnectionQuery(userId);
  const isFollowing = useIsFollowingQuery(userId);
  const connectMutation = useConnectWithUserMutation();
  const followMutation = useFollowMutation();

  const handleConnect = () => {
    if (!profileQuery.data) return;

    connectMutation.mutate({
      userId: profileQuery.data.id,
      displayName: profileQuery.data.displayName,
    });
  };

  const connectItem: IActionItem = {
    id: `${userId}-connection`,
    title: 'Connect',
    disabled:
      !profileQuery.isSuccess ||
      !isConnectionInitiated.isSuccess ||
      !isConnected.isSuccess ||
      isConnected.data ||
      isConnectionInitiated.data,
    icon: UserPlusIcon,
    onClick: handleConnect,
  };

  const handleFollow = () => {
    if (!profileQuery.data) return;

    followMutation.mutate({
      userId: profileQuery.data.id,
      firstName: profileQuery.data.firstName,
      lastName: profileQuery.data.lastName,
    });
  };
  const followItem: IActionItem = {
    id: `${userId}-add`,
    title: 'Add to contacts',
    disabled: !profileQuery.isSuccess || !isFollowing.isSuccess || isFollowing.data,
    icon: LinkIcon,
    onClick: handleFollow,
  };

  const menuItems: IActionItem[] = [];

  if (isConnected.isSuccess) {
    menuItems.push(connectItem);
  }

  if (isFollowing.isSuccess) {
    menuItems.push(followItem);
  }

  return {
    menuItems,
  };
}
