/* eslint-disable @typescript-eslint/no-shadow */
import { ChangeEventHandler, useMemo, useState } from 'react';
import {
  ColumnDef,
  ColumnFilter,
  ColumnFiltersState,
  FilterFn,
  RowSelectionState,
} from '@tanstack/react-table';
import { useParams } from 'react-router';
import MemberRowCell from '../../shared/components/table/cells/MembersRowCell';
import DataTable, { createDataTableColumnHelper } from '../../shared/components/table/DataTable';
import SearchBar from '../../shared/components/search/SearchBar';
import useEntityManifest from '../../shared/hooks/useEntityManifest';
import { ResourceType } from '../../shared/model/ResourceType';
import ProfileRowCell from '../../shared/components/table/cells/ProfileRowCell';
import ActionRowCell from '../../shared/components/table/cells/ActionRowCell';
import { InfiniteApiQueryScroll } from '../../shared/components/scrolling/InfiniteApiQueryScroll';
import { IProjectDto } from './models/IProjectDto';
import { useProjectsInfiniteQuery } from './queries/projectQueries';
import useProjectActions from './hooks/useProjectActions';
import { useMyUserId } from '../../shared/auth/accountHooks';
import { createActivityColumnDefinition } from '../Tracking/trackingHelpers';
import { IActivityItem } from '../../shared/components/activity/IActivityItem';

const DefaultColumnFilters = [] as ColumnFilter[];

const customFilterFunction: FilterFn<IProjectDto> = (row, columnId, filterValue) => {
  const value = row.getValue(columnId) as string;
  return value.toLowerCase().includes(filterValue.toLowerCase());
};

function ProjectDataTable() {
  const [selectedRows, setSelectedRows] = useState<RowSelectionState>({});
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>(DefaultColumnFilters);
  const projectManifest = useEntityManifest(ResourceType.Project);
  const contactManifest = useEntityManifest(ResourceType.Contact);
  const [searchTerm, setSearchTerm] = useState('');
  const myUserId = useMyUserId();

  const { tenantId } = useParams();

  // const actualSelectedRows = Object.keys(selectedRows)
  //   .filter((item) => selectedRows[item])
  //   .map((item) => item);
  const handleSearchChanged: ChangeEventHandler<HTMLInputElement> = (e) => {
    setSearchTerm(e.target.value);
  };

  const columns = useMemo(() => {
    const columnHelper = createDataTableColumnHelper<IProjectDto>();
    const activityHeaderItems = [
      { type: 'Contact', label: 'New contacts' },
      { type: 'Conversation', label: 'New conversations' },
      { type: 'Task', label: 'New tasks' },
      { type: 'Mention', label: 'New mentions' },
    ] as IActivityItem[];
    return [
      // columnHelper.multiSelect(),
      columnHelper.accessor('name', {
        id: 'name',
        header: 'Name',
        filterFn: customFilterFunction,
        minSize: 250,
        cell: ({ row, getValue }) => (
          <ProfileRowCell
            photoUrl={row.original.photoUrl || projectManifest.icon}
            name={getValue()}
          />
        ),
      }),
      columnHelper.accessor('description', {
        id: 'description',
        header: 'Description',
        filterFn: customFilterFunction,
      }),
      columnHelper.accessor('boards', {
        meta: {
          size: '175px',
        },
        header: () => 'Boards',
        cell: ({ getValue }) => <MemberRowCell members={getValue()} />,
      }),
      columnHelper.accessor('memberCount', {
        meta: {
          size: '175px',
        },
        header: () => 'Members',
        cell: ({ getValue }) => (
          <MemberRowCell
            members={Array(getValue())
              .fill(1)
              .map((_, i) => ({ id: i.toString(), photoUrl: contactManifest.icon }))}
          />
        ),
      }),
      columnHelper.accessor('visits', {
        ...createActivityColumnDefinition<IProjectDto>({
          id: 'visits',
          activityItems: activityHeaderItems,
          myUserId: myUserId || '',
        }),
      }),
      columnHelper.actions(({ row }) => (
        <ActionRowCell resolver={() => useProjectActions(row.original)} />
      )),
    ] as ColumnDef<IProjectDto>[];
  }, []);

  const projectQuery = useProjectsInfiniteQuery({ tenantId });

  if (projectQuery.error) return <div>Error: {projectQuery.error.message}</div>;

  return (
    <div className="flex flex-col overflow-hidden">
      <div className="flex items-center gap-3">
        <div className="grow xl:max-w-96">
          <SearchBar
            placeholder="Search to find projects"
            onSearchChange={handleSearchChanged}
            searchTerm={searchTerm}
          />
        </div>
      </div>
      <div className="flex flex-col justify-items-center overflow-hidden mt-4">
        <InfiniteApiQueryScroll query={projectQuery}>
          {({ data }) => (
            <DataTable
              isLoading={projectQuery.isPending}
              getRowId={(row) => row.id}
              columns={columns}
              data={data}
              columnFilters={columnFilters}
              onColumnFiltersChange={setColumnFilters}
              globalFilter={searchTerm}
              rowSelection={selectedRows}
              onRowSelectionChange={setSelectedRows}
            />
          )}
        </InfiniteApiQueryScroll>
      </div>
    </div>
  );
}

export default ProjectDataTable;
