import { useNavigate } from 'react-router';
import { usePipelineQuery } from './queries/pipelineQueries';
import { usePipelineStoreContext } from './context/pipeline-context';
import TabHeaderWrapper from '../../shared/components/tabs/TabHeaderWrapper';
import TabContent from '../../shared/components/tabs/TabContent';
import TabNavItem from '../../shared/components/tabs/TabNavItem';
import { useStagesQuery } from './queries/stageQueries';
import SidePanelHeadline from '../../layout/building-blocks/SidePanelHeadline';
import PipelineEditStages from './PipelineEditStages';
import PipelineEditBudget from './PipelineEditBudget';
import TabWrapper from '../../shared/components/tabs/TabWrapper';
import TabContentWrapper from '../../shared/components/tabs/TabContentWrapper';
import PipelineMembers from './PipelineMembers';
import { usePipelinePrivilegesQuery } from './queries/pipelinePriviligesQueries';
import Howto from '../../shared/components/message-popups/HowTo';
import pipelineDummy from '../../assets/images/pipeline-icon.svg';
import useEntityManifest from '../../shared/hooks/useEntityManifest';
import { ResourceType } from '../../shared/model/ResourceType';
import { useBoardQuery } from '../Projects/queries/boardQueries';
import HeaderSubHeaders from '../../shared/components/headers/HeaderSubHeaders';
import SpacingContainer from '../../shared/components/spacing/SpacingContainer';
import SpacingContainerItem from '../../shared/components/spacing/SpacingContainerItem';
import { Filler } from '../../layout/Filler';
import Messaging from '../Messaging/Messaging';
import { useMessagesQuery, UseMessagesQueryResult } from '../Messaging/queries/messageQueries';
import Attachments from '../../shared/components/attachments/Attachments';
import { useResourceConversationQuery } from '../Messaging/queries/conversationQueries';
import Activities from '../Activities/Activities';
import useBoardActions from '../Projects/hooks/useBoardActions';
import TabDropdownItem from '../../shared/components/tabs/TabDropdownItem';

interface IPipelineEditPipelineProps {
  projectId: string;
  boardId: string;
  pipelineId: string;
}

export default function PipelineEditPipeline({
  projectId,
  boardId,
  pipelineId,
}: IPipelineEditPipelineProps) {
  const { activeTab, setActiveTab } = usePipelineStoreContext();
  const boardQuery = useBoardQuery(projectId, boardId);
  const pipelineQuery = usePipelineQuery(pipelineId);
  const privilegesQuery = usePipelinePrivilegesQuery(pipelineId);
  const stagesQuery = useStagesQuery({ pipelineId });
  const pipelineManifest = useEntityManifest(ResourceType.Pipeline);
  const conversationQuery = useResourceConversationQuery(boardId, ResourceType.Board);
  const conversation = conversationQuery.data;
  const messagesQuery = useMessagesQuery(conversationQuery.data?.id);
  const navigate = useNavigate();

  const handleNavigateBack = () => {
    navigate('..', { replace: true });
  };

  const menuItems = useBoardActions(projectId, boardId, handleNavigateBack, handleNavigateBack);

  if (
    !pipelineQuery.isSuccess ||
    !stagesQuery.isSuccess ||
    !privilegesQuery.isSuccess ||
    !boardQuery.isSuccess
  ) {
    return <></>;
  }

  const board = boardQuery.data;
  const pipeline = pipelineQuery.data;

  return (
    <>
      <SidePanelHeadline
        headline={board.name}
        subHeadline=""
        colorSetting={pipelineManifest.color}
        imageSrc={board?.photoUrl || pipelineDummy}
      />

      <TabWrapper>
        <TabHeaderWrapper>
          <TabNavItem
            id="info"
            activeId={activeTab}
            title="Info"
            setActiveTab={() => setActiveTab('info')}
          />
          {privilegesQuery.data.canReadMembers && (
            <TabNavItem
              id="chat"
              activeId={activeTab}
              title="Chat"
              setActiveTab={() => setActiveTab('chat')}
            />
          )}
          {privilegesQuery.data.canReadMembers && (
            <TabNavItem
              id="members"
              activeId={activeTab}
              title="Members"
              setActiveTab={() => setActiveTab('members')}
            />
          )}
          <TabNavItem
            id="stages"
            activeId={activeTab}
            title="Stages"
            setActiveTab={() => setActiveTab('stages')}
          />
          {privilegesQuery.data.canReadActivities && (
            <TabNavItem
              id="activities"
              activeId={activeTab}
              title="Tasks"
              setActiveTab={() => setActiveTab('activities')}
            />
          )}
          {privilegesQuery.data.canReadBudgets && (
            <TabNavItem
              id="budget"
              activeId={activeTab}
              title="Budget"
              setActiveTab={() => setActiveTab('budget')}
            />
          )}
          <TabDropdownItem isActive={false} menuItems={menuItems.items} />
        </TabHeaderWrapper>
        <TabContentWrapper>
          <TabContent id="info" activeTabId={activeTab}>
            <div className="flex-1 min-h-0 flex flex-col sm:px-0">
              <SpacingContainer>
                <SpacingContainerItem>
                  <HeaderSubHeaders
                    size="small"
                    text="Description"
                    subTexts={[board.description || '']}
                  />
                </SpacingContainerItem>
                <SpacingContainerItem>
                  <HeaderSubHeaders
                    size="small"
                    text="Currency"
                    subTexts={[pipeline.currencyIsoCode || '']}
                  />
                </SpacingContainerItem>
              </SpacingContainer>
              <Filler />
              <div className="flex pt-2">
                <Howto
                  title="Info"
                  description="Change the name, description and currency of your pipeline."
                />
              </div>
            </div>
          </TabContent>
          <TabContent id="chat" activeTabId={activeTab}>
            {conversation && (
              <Messaging
                messages={messagesQuery as UseMessagesQueryResult}
                conversation={conversation}
              />
            )}
          </TabContent>
          <TabContent id="attachments" activeTabId={activeTab}>
            <Attachments conversationId={conversation?.id as string} />
          </TabContent>
          <TabContent id="activities" activeTabId={activeTab}>
            <Activities resourceIds={[projectId, boardId]} type={ResourceType.Board} />
          </TabContent>
          <TabContent id="members" activeTabId={activeTab}>
            <PipelineMembers projectId={projectId} boardId={boardId} pipelineId={pipelineId} />
          </TabContent>
          <TabContent id="stages" activeTabId={activeTab}>
            <PipelineEditStages />
          </TabContent>
          <TabContent id="budget" activeTabId={activeTab}>
            <PipelineEditBudget />
          </TabContent>
        </TabContentWrapper>
      </TabWrapper>
    </>
  );
}
