import { Card } from '../../shared/components/cards/Card';
import { IContactDto } from './models/IContactDto';
import ContactActionButtons from './ContactActionButtons';
import { useContactProfilesQuery } from './queries/contactQueries';
import ContactUserTabBody from './ContactUserCard';
import { Separator } from '../../layout/separator/Separator';

interface IProps {
  contact: IContactDto;
}
export default function ContactUserInformation({ contact }: IProps) {
  const contactProfilesQuery = useContactProfilesQuery(contact.id);

  return (
    <>
      {contactProfilesQuery.data?.map((profile) => (
        <Card
          as="div"
          isInteractive={false}
          key={profile.photoUrl}
          className="pt-3  flex flex-col flex-1 w-full"
        >
          <ContactUserTabBody user={profile} />
          <Separator />
          {profile?.sourceType === 'InternalContact' && (
            <div className="mt-auto">
              <ContactActionButtons contact={contact} profileId={profile.id} />
            </div>
          )}
        </Card>
      ))}
    </>
  );
}
