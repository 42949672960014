import { IContactDto } from './models/IContactDto';
import logo from '../../assets/images/logoY.svg';
import ScrollBarWrapper from '../../shared/components/scrolling/ScrollBarWrapper';
import ContactUserTabBody from './ContactUserCard';
import Howto from '../../shared/components/message-popups/HowTo';
import { Card } from '../../shared/components/cards/Card';
import { useContactProfilesQuery } from './queries/contactQueries';
import FlexContainer from '../../shared/components/structure/FlexContainer';
import { ButtonColors } from '../../shared/constants/ButtonColors';
import useModal from '../../shared/components/modal/hooks/useModal';
import IconButton from '../../shared/components/buttons/IconButton';
import AddIcon from '../../shared/components/icons/AddIcon';
import HeaderSubHeaders from '../../shared/components/headers/HeaderSubHeaders';
import ContactActionButtons from './ContactActionButtons';
import ContactStats from './ContactStats';
import { Separator } from '../../layout/separator/Separator';
import { useResponsiveQueries } from '../../shared/hooks/useResponsiveQueries';
import ContactUserInformation from './ContactUserInformation';
import { ContactFormWrapper } from './ContactFormWrapper';

interface IProps {
  contact: IContactDto;
}

export function ContactInfoTab({ contact }: IProps) {
  const { openModal } = useModal();
  const { isSmallScreen } = useResponsiveQueries();

  const editContact = () => {
    openModal({
      title: 'Add contact information',
      content: <ContactFormWrapper contactId={contact.id} />,
    });
  };

  const contactProfilesQuery = useContactProfilesQuery(contact.id);

  const isAddButtonVisible = !!contactProfilesQuery.data?.every(
    (c) => c.sourceType !== 'InternalContact',
  );

  return (
    <FlexContainer direction="column" className="h-full">
      <ScrollBarWrapper>
        {contact.userId && (
          <FlexContainer direction="column">
            {contactProfilesQuery.data?.map((profile) => (
              <Card
                key={profile.id}
                as="div"
                isInteractive={false}
                className="pt-3 last:mb-6"
                avatarUrl={profile.sourceType === 'InternalContact' ? profile.photoUrl : logo}
              >
                <ContactUserTabBody user={profile} />
                <Separator />
                {profile?.sourceType === 'InternalContact' && (
                  <ContactActionButtons contact={contact} profileId={profile.id} />
                )}
              </Card>
            ))}

            {isAddButtonVisible && (
              <FlexContainer justify="end">
                <IconButton
                  icon={<AddIcon className="w-5 h-5" />}
                  color={ButtonColors.Gray}
                  onClick={editContact}
                  label="Add info"
                  orientation="horizontal"
                />
              </FlexContainer>
            )}
          </FlexContainer>
        )}

        {isSmallScreen && <ContactUserInformation contact={contact} />}

        <HeaderSubHeaders size="small" text="Sharing" />

        <Card as="div" isInteractive={false}>
          <div className="flex-1 overflow-x-hidden">
            <ContactStats contact={contact} />
          </div>
        </Card>
      </ScrollBarWrapper>
      <Howto
        title="Info"
        description="View your contact's information and the number of shared contacts."
      />
    </FlexContainer>
  );
}
